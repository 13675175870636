import formatPrice from '../../CustomFunctions/formatPrice';
import Currency from '../Currency';
import pinIcon from '../../images/icons/browse/pin.svg';
import noImage from '../../images/no-image-primary.png';
import { useState } from 'react';

const WatchListAssetItem = (props) =>{
    const { image , name , currency,collection,price ,total_invest ,url} = props.data;
    const [imageError,setImageError] = useState(false);

    const handleImageError = () =>{
        setImageError(true);
    }
    
    return(
        <div className="col-md-6 col-lg-4">
            <div className="property-info">
            <div class="ribbon">
                <span class="ribbon-content">Watching</span>
            </div>
                <a href={url} target='_blank'>
                <div className={`image-wrapper ${imageError ? 'no-image' : ''}`}>
                         { !imageError && <img src={image} alt="asset" onError={handleImageError} className='img-fluid' loading='lazy'/>}
                         { imageError && <img src={noImage} alt="asset" className='img-fluid' loading='lazy'/>}
                    </div>
                </a>
                <div className='p-4'>
                    <div className="d-flex align-items-start justify-content-between flex-fill">
                        <div>
                            <h3 className='mb-2 land-head custom-tooltip' tooltip-data={name}> <span className="single-liner ">{name}</span></h3>
                            <p className='m-0 land-currency'>{collection}</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-end ms-2'>
                            <Currency name={currency} style={{fontSize:'1.5rem'}}/>
                            <h3 className='land-value mb-0 ms-1 single-liner'>
                                {formatPrice(price)}
                            </h3>
                        </div>
                    </div>
                    <p className='m-0 mt-4 d-flex align-items-center land-desc'>
                        <img src={pinIcon} className='me-2' alt="land-desc-image" />
                        {total_invest}
                    </p>
                </div>
            </div>
        </div>
        
    )
}

export default WatchListAssetItem;