import axios from "axios";
import Cookies from 'js-cookie';
import showToast from "../CustomFunctions/showToast";

export function Loginthunk(body,user) {
  return async (dispatch) => {
    dispatch({
      type: "LOGIN_FETCH",
    });
    try {
      var result = await axios({
        method: 'post',
        url: process.env.REACT_APP_BASE_API+"/login",// 'https://reqres.in/api/login',
        data:body,
        // withCredentials:true,
        headers:{
            'X-API-KEY':process.env.REACT_APP_API_KEY,
            'Content-Type' : 'text/plain;charset=UTF-8'
        }
      });
      sessionStorage.setItem('userData', JSON.stringify(result.data));
      localStorage.clear();
      // Cookies.set('user',result.headers["set-cookie"])
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: result.data,
      });
      //showToast('Welcome To Araa Land','success');
      // window.location = "/"
    }
    catch(err){
      dispatch({
        type: "LOGIN_FAILURE",
      });
      showToast('Invalid Credentials','error');
    }
  };
}

export function CreateUserthunk(body,user) {
  return async (dispatch) => {
    dispatch({
      type: "CREATE_USER_POST",
    });
    try{
      var result = await axios({
        method: 'post',
        url: process.env.REACT_APP_BASE_API+"/create_user",// 'https://reqres.in/api/login',
        data:body,
        headers:{
            'X-API-KEY':process.env.REACT_APP_API_KEY,
        }
      });

      dispatch({
        type: "CREATE_USER_SUCCESS",
        payload: user,
      });
      showToast('Verification Email Sent!','success');
    }
    catch(err){
      dispatch({
        type: "CREATE_USER_FAILURE",
      });

      if (err.response && err.response && err.response.data && err.response.data.msg) {
          showToast(err.response.data.msg, "error");
      } else {
          showToast('Error','error');
      }
    }
  };
}


export function ForgotPaswordthunk(email,user) {
  return async (dispatch) => {
    dispatch({
      type: "FORGOT_PASSWORD_GET",
    });
    try {
      var result = await axios({
        method: 'get',
        url: process.env.REACT_APP_BASE_API+"/forgot_password?email="+email,
        headers:{
            'X-API-KEY':process.env.REACT_APP_API_KEY,
        }
      });

      dispatch({
        type: "FORGOT_PASSWORD_SUCCESS",
        payload: user,
      });
      showToast('Reset Email Sent!','success');
    }
    catch(err){
      dispatch({
        type: "FORGOT_PASSWORD_FAILURE",
      });

      if (err.response && err.response && err.response.data && err.response.data.msg) {
          showToast(err.response.data.msg, "error");
      } else {
          showToast('Error','error');
      }
    }
  };
}


export function ResetPasswordthunk(doc,user) {
  return async (dispatch) => {
    dispatch({
      type: "RESET_PASSWORD_POST",
    });
    try {
      // console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", doc);
      var result = await axios({
        method: 'post',
        url: process.env.REACT_APP_BASE_API+"/forgot_password",
	data:doc,
        headers:{
            'X-API-KEY':process.env.REACT_APP_API_KEY,
        }
      });
       
      dispatch({
        type: "RESET_PASSWORD_SUCCESS",
        payload: user,
      });
      showToast('Reset password successful!','success');
    }
    catch(err){
      dispatch({
        type: "RESET_PASSWORD_FAILURE",
      });

      if (err.response && err.response && err.response.data && err.response.data.msg) {
          showToast(err.response.data.msg, "error");
      } else {
          showToast('Error','error');
      }
    }
  };
}



export function Marketplacesthunk() {
  return async (dispatch) => {
    dispatch({
      type: "MARKET_PLACE_FETCH",
    });
    var result = await axios({
      method: 'get',
      url: process.env.REACT_APP_BASE_API+'/marketplaces',
      headers:{
          'X-API-KEY':process.env.REACT_APP_API_KEY,
      }
  });
    if (result) {
      dispatch({
        type: "MARKET_PLACE_SUCCESS",
        payload: result.data,
      });
    } else {
      dispatch({
        type: "MARKET_PLACE_FAILURE",
      });
    }
  };
}

export function PhysicalTransactionsThunk(url) {
  // console.log("Request Received in Physical Assets Thunk With This>>>>",url)
  return async (dispatch) => {
    dispatch({
      type: "PHYSICAL_ASSET_FETCH",
    });
    try{
      var result = await axios({
        method: 'get',
        url: process.env.REACT_APP_BASE_API+'/recent_assets?type=physical&'+url,
        headers:{
            'X-API-KEY':process.env.REACT_APP_API_KEY,
        }
      });
      dispatch({
        type: "PHYSICAL_ASSET_SUCCESS",
        payload: result.data.results.results,
      });
    } 
    catch(err){
      dispatch({
        type: "PHYSICAL_ASSET_FAILURE",
      });
    }
  };
}

export function VirtualTransactionsThunk(url) {
  // console.log("Request Received in Virtual Transactions Thunk With This>>>>",url)
  return async (dispatch) => {
    dispatch({
      type: "VIRTUAL_ASSET_FETCH",
    });
    try{
      var result = await axios({
        method: 'get',
        url: process.env.REACT_APP_BASE_API+'/recent_transactions?type=virtual&'+url,
        headers:{
            'X-API-KEY':process.env.REACT_APP_API_KEY,
        }
      });
      // console.log("Result got from Virtual Filter >>>>>",result);
      dispatch({
        type: "VIRTUAL_ASSET_SUCCESS",
        payload: result.data.results,
      });
    } 
    catch(err){
      dispatch({
        type: "VIRTUAL_ASSET_FAILURE",
      });
    }
  };
}

export function SearchThunk(body) {
  return async (dispatch) => {
    // console.log("Request Received in Search Thunk With This>>>>",body)
    dispatch({
      type: "SEARCH_FETCH",
    });
    try{
      var result = await axios({
        method: 'get',
        url: process.env.REACT_APP_BASE_API+'/search_items?'+body,
        headers:{
          'X-API-KEY':process.env.REACT_APP_API_KEY,
        }
      });
      // console.log("Result got from Search >>>>>",result);
      dispatch({
        type: "SEARCH_SUCCESS",
        payload: result.data,
      });
    } 
    catch(err){
      dispatch({
        type: "SEARCH_FAILURE",
      });
    }
  };
}

export function AdminTableThunk() {
  return async (dispatch) => {
    // console.log("Request Received in Search Thunk With This>>>>",body)
    dispatch({
      type: "TABLE_FETCH",
    });
    try{
      const cookie=Cookies.get('user');
      var result = await axios({
        method: 'get',
        url: process.env.REACT_APP_BASE_API+"/platform_users",
        withCredentials:true,
        headers:{
          'X-API-KEY':process.env.REACT_APP_API_KEY
        }
      });
      // console.log("Result got from Search >>>>>",result);
      dispatch({
        type: "TABLE_SUCCESS",
        payload: result.data,
      });
    } 
    catch(err){
      dispatch({
        type: "TABLE_FAILURE",
      });
    }
  };
}

export function AdminTableUpdate(body) {
  return async (dispatch) => {
    // console.log("Request Received in Search Thunk With This>>>>",body)
    dispatch({
      type: "ADMIN_TABLE_UPDATE",
    });
    try{
      var result = await axios({
        method: 'post',
        url: process.env.REACT_APP_BASE_API+'/platform_users',
        data:body,
        headers:{
          'X-API-KEY':process.env.REACT_APP_API_KEY,
        }
      });
      // console.log("Result got from Search >>>>>",result);
      dispatch({
        type: "ADMIN_TABLE_UPDATE_SUCCESS",
        payload: result.data,
      });
    } 
    catch(err){
      dispatch({
        type: "ADMIN_TABLE_UPDATE_FAILURE",
      });
    }
  };
}

export function DashboardThunk() {
  return async (dispatch) => {
    dispatch({
      type: "DASHBOARD_FETCH",
    });
    try{
      var result = await axios({
        method: 'get',
        url: process.env.REACT_APP_BASE_API+'/dashboard',
        headers:{
          'X-API-KEY':process.env.REACT_APP_API_KEY,
        }
      });
      // console.log("Result got from Dashboard >>>>>",result);
      dispatch({
        type: "DASHBOARD_SUCCESS",
        payload: result.data,
      });
    } 
    catch(err){
      dispatch({
        type: "DASHBOARD_FAILURE",
      });
    }
  };
}


export function AddAssetThunk(body) {
  // console.log("Request Received in Add Asset with this====>",body)
  return async (dispatch) => {
    dispatch({
      type: "ADD_ASSET_FETCH",
    });
    try{
      var result = await axios({
        method: 'post',
        url: process.env.REACT_APP_BASE_API+'/add_asset',
        data:body,
        headers:{
          'X-API-KEY':process.env.REACT_APP_API_KEY,
        },
      });
      // console.log("Result got from Add Asset =========>>",result);
      dispatch({
        type: "ADD_ASSET_SUCCESS",
        payload:result.data.result,
      });
      showToast('Asset Addedd Successfully !','success')
      setTimeout(()=>{window.location.reload()}, 3000);	    
    } 
    catch(err){
      // console.log("ADD ASSET Error======>>",err)
      dispatch({
        type: "ADD_ASSET_FAILURE",
      });
      showToast('Error while Adding Asset','error')
    }
  };
}


export function GetTimestampsThunk(email, network) {
  // console.log("Request Received in GetTimestamps with this====>",email, network)
  return async (dispatch) => {
    dispatch({
      type: "GET_TIMESTAMPS_FETCH",
    });
    try{
      var result = await axios({
        method: 'get',
        url: process.env.REACT_APP_BASE_TIMESTAMP_API+'/api?network='+network+"&email="+email,
        headers:{
          'X-API-KEY':process.env.REACT_APP_API_KEY,
        },
      });
      // console.log("Result got from GetTimestamp =========>>",result);
      dispatch({
        type: "GET_TIMESTAMPS_SUCCESS",
        payload:result.data,
      });
      //showToast('Successfully !','success')
    } 
    catch(err){
      // console.log("Get Timestamps Error======>>",err)
      dispatch({
        type: "GET_TIMESTAMPS_FAILURE",
      });
      showToast('Error while fetching Timestamps','error')
    }
  };
}


export function AddTimestampThunk(body) {
  // console.log("Request Received in Add Timestamp with this====>",body)
  return async (dispatch) => {
    dispatch({
      type: "ADD_TIMESTAMP_FETCH",//ADD_ASSET_FETCH
    });
    try {
      var result = await axios({
        method: 'post',
        url: process.env.REACT_APP_BASE_API+'/timestamp',
        data:body,
        headers:{
          'X-API-KEY':process.env.REACT_APP_API_KEY,
        },
      });
      // console.log("Result got from Add Timestamp =========>>",result);
      dispatch({
        type: "ADD_TIMESTAMP_SUCCESS",
        payload:result.data,
      });
      showToast('Timestamp Added Successfully !','success')
      setTimeout(()=>{window.location.reload()}, 3000);	    
 
    } 
    catch(err){
      // console.log("ADD TIMESTAMP Error======>>",err)
      dispatch({
        type: "ADD_TIMESTAMP_FAILURE",
      });
      let errorr = "Error while adding Property ID: ";
      try {
	      errorr = errorr+err.response.data.error;
      } catch (ee){}
      showToast(errorr,'error')
    }
  };
}

export function AppendTimestampThunk(body) {
  // console.log("Request Received in append Timestamp with this====>",body)
  return async (dispatch) => {
    dispatch({
      type: "APPEND_TIMESTAMP_FETCH",//ADD_ASSET_FETCH
    });
    try {
      var result = await axios({
        method: 'post',
        url: process.env.REACT_APP_BASE_TIMESTAMP_API+'/api',
        data: body,
        headers:{
          'X-API-KEY':process.env.REACT_APP_API_KEY,
        },
      });
      // console.log("Result got from append Timestamp =========>>",result);
      dispatch({
        type: "APPEND_TIMESTAMP_SUCCESS",
        payload:result.data,
      });
      showToast('Timestamp Appended Successfully !','success')
    } 
    catch(err){
      // console.log("APPEND TIMESTAMP Error======>>",err)
      dispatch({
        type: "APPEND_TIMESTAMP_FAILURE",
      });
      showToast('Error while Appending Timestamp','error')
    }
  };
}

export function WithdrawAssetThunk(body) {
  // console.log("Request Received in withdraw Asset with this====>",body)
  return async (dispatch) => {
    dispatch({
      type: "WITHDRAW_ASSET_FETCH",//ADD_ASSET_FETCH
    });
    try {
      var result = await axios({
        method: 'post',
        url: process.env.REACT_APP_BASE_API+'/timestamp/asset_withdraw',
        data: body,
        headers:{
          'X-API-KEY':process.env.REACT_APP_API_KEY,
        },
      });
      // console.log("Result got from withdraw Asset =========>>",result);
      dispatch({
        type: "WITHDRAW_ASSET_SUCCESS",
        payload:result.data,
      });
      showToast('Asset Withdrawn Successfully !','success')
    } 
    catch(err){
      // console.log("WITHDRAW ASSET Error======>>",err)
      dispatch({
        type: "WITHDRAW_ASSET_FAILURE",
      });
      showToast('Error while withdrawing asset','error')
    }
  };
}

export function AddPlatformThunk(body) {
  return async (dispatch) => {
    dispatch({
      type: "ADDPLATFORM_THUNK",
    });
    try{
      var result = await axios({
        method: 'post',
        url: process.env.REACT_APP_BASE_API+'/create_platform',
        headers:{
          'X-API-KEY':process.env.REACT_APP_API_KEY,
        },
        data:{
          platform_name:body
        }
      });
      dispatch({
        type: "ADDPLATFORM_SUCCESS",
      });
      showToast('Platform Added Successfully !','success')
    } 
    catch(err){
      dispatch({
        type: "ADDPLATFORM_FAILURE",
      });
      showToast('Platform Failed to be Added!','error')
    }
  };
}
