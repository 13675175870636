import { useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import formatDecimal from '../CustomFunctions/formatDecimal';
import { AssetFetchThunk, VirtualTransactionsThunk } from '../Reduxstore/thunks';
import Currency from './Currency';
import NoDataFound from './NoDataFound';


const VirtualTransactionItem = (props) => { 
    const { asset_img_url , asset_name , collection_name,event_total_price,event_payment_symbol,external_url } = props.data;
    return (
        <tr>
            <td colSpan={2}>
                <a href={external_url} target='_blank' className='text-decoration-none text-dark'>
                    <div className="icon-wrapper">
                        <div className="image-wrapper">
                            <img src={asset_img_url} alt="icon" className='img-fluid' loading='lazy'/>
                        </div>
                        <div className="d-block ms-3 w-100">
                            <p className="asset-name mb-0">{asset_name}</p>
                            <div className="d-flex justify-content-between flex-wrap">
                                <p className="mb-0 asset-subname">{collection_name}</p>
                                <div className="d-flex align-items-center">
                                    <Currency name={event_payment_symbol}  style={{width:'14px'}}/>
                                    <p className="mb-0 text-right ms-1"> {formatDecimal(event_total_price)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </td>
        </tr>
    )
}

const VirtualTransactionTable = () =>{
    const dispatch = useDispatch();
    const {assets,isloading,error} = useSelector(state => state.virtualAsset);
    useEffect(()=>{
        dispatch(VirtualTransactionsThunk('limit=20'));
    },[])
    return(
        <>
        {error!==undefined && assets=='' && error && !isloading ? (
               <NoDataFound style={{maxWidth:'250px'}}/>
            ) 
            : (
                <div className="table-wrapper">
                    <table className=" table table-responsive table-borderless table-hover">
                        <thead>
                            <tr>
                                <th >Name</th>
                                <th className='text-end'>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isloading &&
                                <tr>
                                    <td colSpan={2}>
                                        <div className="loader" style={{position:'relative',inset:'0',transform:'none'}}><ThreeDots color="#8FF129"></ThreeDots></div> 
                                    </td>
                                </tr> 
                            }
        
                            {assets && assets!==undefined && !isloading && assets.map((assetItem,index)=>{
                                return <VirtualTransactionItem key={index} data={assetItem}></VirtualTransactionItem>
                            })}
        
                            
                        </tbody>
                    </table>
                </div>
            )
        }
        </>
       
    );
}

export default VirtualTransactionTable;