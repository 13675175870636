import Scrollspy from 'react-scrollspy';
import { NavLink } from 'react-router-dom';

import communityImage1 from '../images/community/community-image-1.png';
import communityImage3 from '../images/community/community-image-3.jpg';

import storyImage1 from '../images/community/story-image-1.png';
import storyImage2 from '../images/community/story-image-2.png';
import { useState } from 'react';
import showToast from '../CustomFunctions/showToast';
import { Helmet } from 'react-helmet-async';

const exploreItemData =[{
    'exploreItemHead':'Welcome to the araa community hub',
    'exploreItemDesc':' This is where community interaction takes place.The araa community is home to hundreds of thousands of developers, technologists, designers, users, HODLers and enthusiasts.',
    'exploreItemImage':communityImage1,
}
];

const users = [
    {
        name:'Daniel Saliba',
        image:storyImage1,
    },
    {
        name:'Bary Rowe',
        image:storyImage2,
    },
    {
        name:'Daniel Saliba',
        image:storyImage1,
    },
    {
        name:'Bary Rowe',
        image:storyImage2,
    },
    {
        name:'Daniel Saliba',
        image:storyImage1,
    },
    {
        name:'Bary Rowe',
        image:storyImage2,
    },
    {
        name:'Daniel Saliba',
        image:storyImage1,
    },
    {
        name:'Bary Rowe',
        image:storyImage2,
    },
    {
        name:'Daniel Saliba',
        image:storyImage1,
    },
    {
        name:'Bary Rowe',
        image:storyImage2,
    },
    {
        name:'Daniel Saliba',
        image:storyImage1,
    },
    {
        name:'Bary Rowe',
        image:storyImage2,
    },
    {
        name:'Daniel Saliba',
        image:storyImage1,
    },
    {
        name:'Bary Rowe',
        image:storyImage2,
    },
    {
        name:'Daniel Saliba',
        image:storyImage1,
    },
    {
        name:'Bary Rowe',
        image:storyImage2,
    },
    {
        name:'Daniel Saliba',
        image:storyImage1,
    },
    {
        name:'Bary Rowe',
        image:storyImage2,
    },
    {
        name:'Daniel Saliba',
        image:storyImage1,
    },
    {
        name:'Bary Rowe',
        image:storyImage2,
    },
    {
        name:'Daniel Saliba',
        image:storyImage1,
    },
    {
        name:'Bary Rowe',
        image:storyImage2,
    },
    {
        name:'Daniel Saliba',
        image:storyImage1,
    },
    {
        name:'Bary Rowe',
        image:storyImage2,
    },
]

const ActionButton = (props) =>{
    const [fillIcon,setFillIcon] = useState(false);
    const {like,comment,share,save} = props;

    const handleActive = (e) =>{
        setFillIcon(!fillIcon);
        if(!fillIcon){
            const name = e.target.getAttribute('name');
            showToast(`${name} Clicked !`)
        }
    }
    return(
        <button className='btn' onClick={handleActive}>
            { like && <i className={`fa-heart fa-xl ${fillIcon ? 'fa-solid ' : 'fa-regular'}`} name={'Like'}></i>}

            { comment && <i className={`fa-comment fa-xl ${fillIcon ? 'fa-solid' : 'fa-regular'}`} name={'Comment'}></i>}

            { share && <i className={`fa-paper-plane fa-xl ${fillIcon ? 'fa-solid' : 'fa-regular'}`} name={'Share'}></i>}
            
            { save && <i className={`fa-bookmark fa-xl ${fillIcon ? 'fa-solid' : 'fa-regular'}`} name={'Save'}></i>}
        </button>
    )
}

const CommunityExploreItem = (props) =>{
    const {exploreItemHead,exploreItemDesc,exploreItemImage} = props.data;

    return(
        <div className="community-content__feed__item my-4">
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <span className='feed-user-image-wrapper d-block me-3'>
                        <img src={storyImage1} className="img-fluid" alt="user-image" loading='lazy'/>
                    </span>
                    <span>
                        <p className='feed-user-name mb-1'>Daniel Saliba</p>
                        <p className='feed-user-location m-0'>Dubai , UAE</p>
                    </span>
                </div>
                <div className="dropdown action-menu-wrapper">
                    <button className="btn  dropdown-toggle action-menu-btn" type="button" id="portfolioActionBtn" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fa-solid fa-angle-down" style={{fontSize:'2rem',color:'#A2A2A2'}}></i>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="portfolioActionBtn">
                        <li><a className="dropdown-item" href="/action">Action</a></li>
                        <li><a className="dropdown-item" href="/action">Another action</a></li>
                        <li><a className="dropdown-item" href="/action">Something else here</a></li>
                    </ul>
                </div>
            </div>
            <div className='feed-image-wrapper my-4'>
                <span className='d-block'>
                    <img src={communityImage3} alt="" className='img-fluid' loading='lazy'/>  
                </span>
            </div>
            <div className="d-flex align-items-center feed-actions mb-3">
                <ActionButton like/>
                <ActionButton comment/>
                <ActionButton share/>
                <ActionButton save/>
            </div>
            <p className='feed-desc'>
           {exploreItemDesc}
            </p>
            
        </div>
    )
}

const Comminuty = () =>{
    const [sidebar,setSidebar] = useState(false);
    const handleSidebar = (e) =>{
        setSidebar(!sidebar);
        e.preventDefault();
    }
    return(
        <>
        <Helmet>
        <title>Araa Land Community is a community where you can find your perfect property with ease.</title>
        <meta name="description" content="Arra community helps you find your favorite type of property with ease." />
        <link rel="canonical" href="https://app.arra.land/community" />
        </Helmet>
        <div className="main-content community">
            <div className="main-content__left ">
            <Scrollspy items={ ['explore','events','contribute','support'] } className={`list-unstyled sidebar ${sidebar && 'active'}`} currentClassName={'active'}>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#explore"> <span className="d-flex align-items-center me-2"><i class="fa-solid fa-magnifying-glass"></i></span> <span className="d-block">Explore</span></a></li>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#events" > <span className="d-flex align-items-center me-2"><i class="fa-solid fa-bars"></i></span> <span className="d-block">Events</span></a></li>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#contribute"> <span className="d-flex align-items-center me-2"><i class="fa-solid fa-plus"></i></span> <span className="d-block">Contribute</span></a></li>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#support"> <span className="d-flex align-items-center me-2"><i class="fa-regular fa-message"></i></span> <span className="d-block">Support </span></a></li>
                </Scrollspy>
                <button className="btn btn-primary sidebar-toggler" onClick={((e)=>handleSidebar(e))}>
                    <span></span><span></span><span></span>
                </button>
                <button className="btn btn-primary sidebar-close" onClick={((e)=>handleSidebar(e))}>
                    X
                </button>
            </div>
            <div className="main-content__right m-0 p-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 border-bottom p-0 m-0">
                            <div className="feed-stories-wrapper">
                                <ul className='list-unstyled'>
                                    {users.map((user)=>{
                                        return (
                                            <li>
                                                <NavLink to={'/profile'}>
                                                    <div className='image-wrapper'><img src={user.image} alt="" className='img-fluid' loading='lazy'/></div> 
                                                    <p>{user.name}</p>
                                                </NavLink>
                                            </li>
                                        ) })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row community-content__item">
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-12  col-sm-9 input-wrapper">
                                        <input type="text" className="form-control" id="community-search" placeholder="What’s going on, Daniel?"/>
                                </div>
                                <div className="col-12 mt-3 mt-sm-0 col-sm-3 btn-wrapper">
                                    <button className='btn btn-primary'>Share</button>
                                </div>
                            </div>
                            <div className="row mt-4" id="explore">
                                { exploreItemData && exploreItemData!==undefined ? exploreItemData.map((exploreItem,index)=>{
                                    return <CommunityExploreItem key={`community-${index}`} data={exploreItem}></CommunityExploreItem>
                                }) : null}
                            </div>
                        </div>
                        <div className="d-none d-lg-block col-lg-3">
                            <div className="user-profile">
                                <div className="d-flex flex-wrap align-item-center justify-content-between pb-4 border-bottom px-3">
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <div className="image-wrapper mb-2 me-2">
                                            <img src={storyImage1} alt="user" className='img-fluid' loading='lazy'/>
                                        </div>
                                        <div>
                                            <p className='mb-0 user-profile-name'>Daniel Saliba</p>
                                            <p className='mb-0 user-profile-id'>@danielsaliba</p>
                                            <p className='mb-0 mt-4 user-profile-location'>Dubai, UAE</p>
                                        </div>
                                    </div>
                                    <div><button className='btn user-profile-action-btn'>Edit</button></div>
                                </div>
                                <div className="d-flex flex-wrap pt-3 pb-2 px-4 user-profile-stats justify-content-between">
                                    <div>
                                        <p className='mb-1 user-profile-stats-head'>Posts</p>
                                        <p className='mb-0 user-profile-stats-value'>2.6K</p>
                                    </div>
                                    <div>
                                        <p className='mb-1 user-profile-stats-head'>Posts</p>
                                        <p className='mb-0 user-profile-stats-value'>2.6K</p>
                                    </div>
                                    <div>
                                        <p className='mb-1 user-profile-stats-head'>Posts</p>
                                        <p className='mb-0 user-profile-stats-value'>2.6K</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 trending-box px-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="trending-box-head m-0">
                                    Trending News
                                    </p>
                                    <button className='btn trending-box-action-btn'>View All</button>
                                </div>
                                <div className='trending-box__content mt-3'>
                                    <div className="trending-box__content__item mt-3 d-flex">
                                        <div className='me-3'><i class="fa-solid fa-arrow-trend-up"></i></div>
                                        <NavLink to={'/trending-post'}>
                                            <p className='m-0 link-head'>Crypto Big Losses And Some Beneefits</p>
                                            <p className='m-0 link-desc'>Read More</p>
                                        </NavLink>
                                    </div>
                                    <div className="trending-box__content__item mt-3 d-flex">
                                        <div className='me-3'><i class="fa-solid fa-arrow-trend-up"></i></div>
                                        <NavLink to={'/trending-post'}>
                                            <p className='m-0 link-head'>Bitcoin Makes S&P No. 1</p>
                                            <p className='m-0 link-desc'>Read More</p>
                                        </NavLink>
                                    </div>
                                    <div className="trending-box__content__item mt-3 d-flex">
                                        <div className='me-3'><i class="fa-solid fa-arrow-trend-up"></i></div>
                                        <NavLink to={'/trending-post'}>
                                            <p className='m-0 link-head'>Crypto</p>
                                            <p className='m-0 link-desc'>Ethereum</p>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 trending-box px-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="trending-box-head m-0">
                                    Trending Groups
                                    </p>
                                    <button className='btn trending-box-action-btn'>View All</button>
                                </div>
                                <div className='trending-box__content mt-3'>
                                    <div className="trending-box__content__item mt-3 d-flex">
                                        <div className='me-3'><i class="fa-solid fa-arrow-trend-up"></i></div>
                                        <NavLink to={'/trending-post'}>
                                            <p className='m-0 link-head'>#AraaRealEstate</p>
                                            <p className='m-0 link-desc'>1040 Posts</p>
                                        </NavLink>
                                    </div>
                                    <div className="trending-box__content__item mt-3 d-flex">
                                        <div className='me-3'><i class="fa-solid fa-arrow-trend-up"></i></div>
                                        <NavLink to={'/trending-post'}>
                                            <p className='m-0 link-head'>#Games</p>
                                            <p className='m-0 link-desc'>5467 Posts</p>
                                        </NavLink>
                                    </div>
                                    <div className="trending-box__content__item mt-3 d-flex">
                                        <div className='me-3'><i class="fa-solid fa-arrow-trend-up"></i></div>
                                        <NavLink to={'/trending-post'}>
                                            <p className='m-0 link-head'>#Crypto</p>
                                            <p className='m-0 link-desc'>5467 Posts</p>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Comminuty;