import { toast } from "react-toastify";

const showToast = (message,type) =>{
    if(type==='info'){
        return (
            toast.info(message,{
            toastId: 'info1',
        }));
    }
    else if(type==='success'){
        return (
            toast.success(message,{
            toastId: 'success1',
        }));
    }
    else if(type==='warning'){
        return (
            toast.warning(message,{
            toastId: 'warning1',
        }));
    }
    else if(type==='error'){
        return (
            toast.error(message,{
            toastId: 'error1',
        }));
    }
    else{
        return (
            toast(message,{
            toastId: 'default1',
        }));
    }
};

export default showToast;