import { Helmet } from "react-helmet-async";

const ProfileAccount = () =>{
    return(
        <>
        <Helmet>
        <title>Your Profile in Araa.land - Your Global Real Estate Marketplace</title>
        <meta name="description" content="Your Profile in Araa.land , Find Any global Real Estate crowdfunding & tokenization assets from one convenient place" />
        <link rel="canonical" href="https://app.arra.land/summary/account" />
        </Helmet>
        <div className="m-5 p-5">
            <h1 className="text-center">Profile Account Works !</h1>
        </div>
        </>
    )
}

export default ProfileAccount;