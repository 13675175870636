import workInProgressImage from "../images/work-in-progress-primary.png";
import iconLogo from "../images/logos/icon-logo-primary.png";

const WorkInProgress = (props) =>{
    const {loadingText} = props
    return(
        <div className='mx-auto' style={props.style}>
            <div className="position-relative" style={{height:410}}>
                <img src={workInProgressImage} alt="work-in-progress" className="img-fluid" style={{objectFit:'cover'}} loading='lazy' />
                <img src="" alt=""  loading='lazy' />
                <img src={iconLogo} alt="icon" className="img-fluid" style={{position:'absolute',left:'32%',bottom:'30%',maxWidth:'50px'}} loading='lazy' />
                <img src="" alt=""  loading='lazy' />
            </div>
             {/* <div className="loader-text price-change text-center"> Work in Progress ! <span className="loader__dot">.</span><span className="loader__dot">.</span><span className="loader__dot">.</span></div> */}
             { loadingText &&
             <div className="loader-text price-change text-center text-nowrap fs-1"> Work in Progress !</div>}
        </div>
    )
}

export default WorkInProgress;