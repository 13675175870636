import { Link } from "react-router-dom";
import tableLogo from '../images/welcome/table-logo-1.png';
import graphImage from '../images/tools/graph.svg';

const ToolsCompareItem = (props) => { 
    const {platformname,
    annualreturn,
    return3y,
    classification,
    growth,
    mininvest} = props.data;

    return (
        <tr>
            <td>
            <Link to={`/physical-assets/${platformname}`}>
                <div className="icon-wrapper">
                    <div className="image-wrapper">
                        <img src={tableLogo} alt="icon" className='img-fluid' loading='lazy'/>
                    </div>
                    <div className="d-inline-block ms-3"><p className="asset-name mb-0">{platformname}</p><p className="mb-0 asset-subname">BAND</p></div>
                </div>
            </Link>
            </td>
            <td>
                {annualreturn}
            </td>
            <td>
                {return3y}
            </td>
            <td>
                {classification}
            </td>
            <td>
                <div className="growth-graph-image">
                    <img src={graphImage} alt="graph" className="img-fluid" loading='lazy' />
                </div>
            </td>
            <td className="text-start">
                {mininvest}
            </td>
            
        </tr> 
    )
}

const ToolsCompareTable = (props) =>{
    return(
        <div className="table-wrapper table-head-colored">
            <table className=" table table-responsive table-borderless table-hover">
                <thead>
                    <tr>
                        <th >Platform</th>
                        <th >Annual Return</th>
                        <th >Retun 3Y</th>
                        <th >Classification</th>
                        <th >Growth of 1000</th>
                        <th >Minimum Investment</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((item,index)=>{
                        return  <ToolsCompareItem key={`ToolsCompareItem${index}`} data={item}></ToolsCompareItem>;
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default ToolsCompareTable;