import { useMemo } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import tableLogo from '../images/welcome/table-logo-1.png';
import Pagination from "../Hooks/Pagination";


const MetaverseItem = (props) => { 
    const {assetName,assetMarket,receivedFromName,receivedFromToken,sentToName,sentToToken,popularityIndex,popularityMarket,tokenIcon,tokenQuantity,tokenValue} = props.data;

    return (
        <tr>
            <td>
                <div className="icon-wrapper">
                    <div className="image-wrapper">
                        <img src={tableLogo} alt="icon" className='img-fluid' loading='lazy' />
                    </div>
                    <div className="d-inline-block ms-3"><p className="asset-name mb-0">{assetName}</p><p className="mb-0 asset-subname">{assetMarket}</p></div>
                </div>
            </td>
            <td>
                <div className="icon-wrapper">
                    <div className="image-wrapper">
                        <img src={tableLogo} alt="icon" className='img-fluid' loading='lazy' />
                    </div>
                    <div className="d-inline-block ms-3"><p className="asset-name mb-0">{receivedFromName}</p><p className="mb-0">{receivedFromToken}</p></div>
                </div>
            </td>
            <td >
                <div className="icon-wrapper">
                    <div className="image-wrapper">
                        <img src={tableLogo} alt="icon" className='img-fluid' loading='lazy' />
                    </div>
                    <div className="d-inline-block ms-3"><p className="asset-name mb-0">{sentToName}</p><p className="mb-0">{sentToToken}</p></div>
                </div>
                
            </td>
            <td >
                <p className="mb-0">#{popularityIndex}</p>
                <p className="mb-0 asset-subname">{popularityMarket}</p>
            </td>
            <td>
                <p className="mb-0"><span className="me-2">{tokenIcon}</span>{tokenQuantity}</p>
                <p className="mb-0">{tokenValue}</p>
            </td>
            
        </tr> 
    )
}

const MetaverseTable = (props) =>{

    
  let PageSize = 10;
  const {data} = props; 
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

    return(
        <div>
            <div className="table-wrapper table-head-colored">
                <table className=" table table-responsive table-borderless table-hover">
                    <thead>
                        <tr>
                            <th >Assets</th>
                            <th >From</th>
                            <th >To</th>
                            <th >Popularity</th>
                            <th >Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentTableData.map((item,index)=>{
                            return  <MetaverseItem key={`MetaverseItem${index}`} data={item}></MetaverseItem>;
                        })}
                    </tbody>
                </table>
            </div>
            <div className="mt-4">
                <Pagination
                    className="pagination-bar p-0"
                    currentPage={currentPage}
                    totalCount={data.length}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                    />
            </div>
        </div>

    );
}

export default MetaverseTable;