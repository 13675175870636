import { useDispatch , useSelector} from "react-redux";
import { Loginthunk } from "../Reduxstore/thunks";
import { useNavigate } from 'react-router';
import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import showToast from "../CustomFunctions/showToast";
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

const Login = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isUserLoading,setUserLoading]=useState(false);
    const [isPartnerLoading,setPartnerLoading]=useState(false);
    const {isuserloggedin,user,error} = useSelector(state=> state.auth);

    const queryParameters = new URLSearchParams(window.location.search)
    const isPartner = queryParameters.get("partner")

    const defaultUserDetails = {
        "email": "eve.holt@reqres.in",
        "password": "cityslicka"
    }

    const userList =[
        {
        email:'daniel@araa.land',
        password:'daniel@araa',
        firstName:"Daniel",
        lastName:'Saliba',
        location:"Dubai UAE"
        },
        {
        email:'barry@todalarity.com',
        password:'barry@araa',
        firstName:"Barry ",
        lastName:'Rowe',
        location:"California , USA"
        },
        {
        email:'admin@araa.land',
        password:'admin@araa',
        firstName:"Admin",
        lastName:'Araa'
        }
    ];

    function validateuser(user){
        const {email,password} = user;
        for(let i in userList){
            if(email == userList[i].email && password==userList[i].password ){
              return userList[i];
            }
        }
	//backup method to let anyone loging with just email
	if (email == password) {
	    var firstName = email.split("@")[0];
	    var lastName = email.split("@")[1];
	    return {"email": email, 
		    "password": password, 
		    "firstName": firstName,
		    "lastName": lastName};
	}
    } 

    const [userInput, setUserInput] = useState({
        email : '',
        password : ''
    });
    const [partnerInput, setPartnerInput] = useState({
        email : '',
        password : ''
    });

    const {email,password} = userInput;

    const handleChange = key => event => {
        setUserInput({...userInput, [key] : event.target.value})
    }

    const handlePartnerChange = key => event => {
        setPartnerInput({...partnerInput, [key] : event.target.value})
    }

    const handleLogin = async(e) =>{
        e.preventDefault();
        if(email == ''){
            showToast('Enter Email','error');
        }else if(password == ''){
            showToast('Enter Password','error');
        }else{
            await dispatch(Loginthunk({"email": email, "password": password},userInput));
        }
        setUserLoading(false);
        
    }
    const handlePartnerLogin = async(e) =>{
        e.preventDefault();
        if(partnerInput.email == ''){
            showToast('Enter Email','error');
        }else if(partnerInput.password == ''){
            showToast('Enter Password','error');
        }else{
            await dispatch(Loginthunk({"email": partnerInput.email, "password": partnerInput.password},partnerInput));
        }
        setPartnerLoading(false);       
    }

    useEffect(()=>{
        if(isuserloggedin){ 
            let session=sessionStorage.getItem('userData');
            if(session.length > 0){
                session=JSON.parse(session);
                if(session?.is_platform_user){
                    // if(session?.is_form_submitted){
                    //     navigate('/');
                    // }else{
                    // }
                    navigate('/greet');
                }else if( session?.platform_name==="admin"){
                    navigate('/admin-formupdate')
                }else{
                    navigate('/')
                }
            }
        }
    },[isuserloggedin])

    return(
        <>
        <Helmet>
        <title>Login to Araa Land</title>
        <meta name="description" content="Login to Araa Land Account" />
        <link rel="canonical" href="https://app.araa.land/login" />
        </Helmet>
        <div className="login d-flex justify-content-around">            
            { !isuserloggedin &&
            <div className=" py-5 px-5">
                <form action="" className="mt-4 mx-auto text-start shadow p-5 position-relative" style={{backgroundColor:'#f9f9f9',borderRadius:'20px'}}>
		    { isPartner ? <h3 className="text-dark text-center mb-4">Sign In as Platform Partner</h3>  : <h3 className="text-dark text-center mb-4">Please Login To Access This Feature</h3> }
                    <div className="mb-3 row">
                        <label for="user-email" className="col-sm-3 col-md-2 col-form-label">Email</label>
                        <div className="col-sm-9 col-md-10">
                        <input type="text" className="form-control" id="user-email" onChange={handleChange('email')} value={userInput.email} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label for="user-password" className="col-sm-3  col-md-2 col-form-label" >Password</label>
                        <div className="col-sm-9 col-md-10">
                        <input type="password" className="form-control" id="user-password" onChange={handleChange('password')} value={userInput.password}/>
                        </div>
                    </div>
                    <button type="button" className="d-block btn py-2 px-3 btn-primary mt-4 mx-auto" onClick={(e)=>{setUserLoading(true);handleLogin(e)}} style={{minWidth:'200px',height:'35px'}}> { !isUserLoading ? <span>Click Here To Login</span> : <span className="d-flex justify-content-center align-items-center"><ThreeDots color="#fff" height={17}></ThreeDots></span> }</button>
                    <div className='d-flex justify-content-center mt-2' >
                        <Link className="navbar-brand" to={ isPartner ? '/signup?partner=1' :'/signup'}>Sign Up</Link>
                        <Link className="navbar-brand" to={ isPartner ? '/forgot_password?partner=1': '/forgot_password'}>Forgot Password</Link>
                    </div>
                </form>
            </div>
            }
            {/* !isuserloggedin &&
            <div className="py-5 px-5" >
                <form action="" className="mt-4 mx-auto text-start shadow p-5 position-relative" style={{backgroundColor:'#f9f9f9',borderRadius:'20px'}}>
                    <h3 className="text-dark text-center mb-4">Please Login To Access Platform Partner</h3>
                    <div className="mb-3 row">
                        <label for="user-email" className="col-sm-3 col-md-2 col-form-label">Email</label>
                        <div className="col-sm-9 col-md-10">
                        <input type="text" className="form-control" id="user-email" onChange={handlePartnerChange('email')} value={partnerInput.email} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label for="user-password" className="col-sm-3 col-md-2 col-form-label" >Password</label>
                        <div className="col-sm-9 col-md-10">
                        <input type="password" className="form-control" id="user-password" onChange={handlePartnerChange('password')} value={partnerInput.password}/>
                        </div>
                    </div>
                    <button className="d-block btn py-2 px-3 btn-primary mt-4 mx-auto" onClick={(e)=>{setPartnerLoading(true);handlePartnerLogin(e)}} style={{minWidth:'200px',height:'35px'}}> { !isPartnerLoading ? <span>Click Here To Login</span> : <span className="d-flex justify-content-center align-items-center"><ThreeDots color="#fff" height={17}></ThreeDots></span> }</button>
                    <div style={{display:'flex',justifyContent:'center',marginTop:'10px'}}>
                        <Link className="navbar-brand" to={'/signup'}>Sign Up</Link>
                        <Link className="navbar-brand" to={'/forgot_password'}>Forgot Password</Link>
                    </div>
                </form>
            </div>
            */}
        </div>
        </>
    )
}

export default Login;
