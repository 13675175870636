import { Helmet } from "react-helmet-async"

const ProfileSummary = () =>{
    return(
        <>
        <Helmet>
        <title>Araa.land - Your Global Real Estate Marketplace</title>
        <meta name="description" content="Find Any global Real Estate crowdfunding & tokenization assets from one convenient place" />
        <link rel="canonical" href="https://app.arra.land/summary" />
        </Helmet>
        <div className="m-5 p-5">
            <h1 className="text-center">Profile Summary Works !</h1>
        </div>
        </>
    )
}

export default ProfileSummary;