import { useDispatch , useSelector} from "react-redux";
import { ForgotPaswordthunk, ResetPasswordthunk } from "../Reduxstore/thunks";
import { useNavigate } from 'react-router';
import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import showToast from "../CustomFunctions/showToast";
import { Link, NavLink, useSearchParams } from 'react-router-dom';

const ForgotPassword = () => {
	//NOTE: IF KEY IS PRESENT, GIVE THE NEW PASSWORD FIELDS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const queryParameters = new URLSearchParams(window.location.search)
    const isPartner = queryParameters.get("partner")

    const [userInput, setUserInput] = useState({
        email : '',
	password1: '',
	password2: '',
	key: '',
    });


    const {email, password1, password2} = userInput;
    const isloading = useSelector(state=> state.isloading);
    const [searchParams, setSearchParams] = useSearchParams();
    let key = searchParams.get("key","");
    // console.log("key", key);
    // console.log(password1, password2)
    const handleChange = key => event => {
        setUserInput({...userInput, [key] : event.target.value})
    }

    const handleForgotPassword = (e) =>{
        if(email == ''){
            showToast('Enter Email','error');
        }else{
            dispatch(ForgotPaswordthunk(email,userInput));    
        }
        e.preventDefault();
    }

    const handleResetPassword = (e) =>{
        if (password1 == ''){
            showToast('Please enter a password','error');
	} else if (password1 != password2){
            showToast('Passwords don\'t match','error');
	} else {
            dispatch(ResetPasswordthunk({'email': email, 'pass1':password1,
	                                 'pass2':password2, 'key':key}, userInput));    
        }
        e.preventDefault();
    }

    if (!key) {
      return(
        <div className="forgot_password">
            <div className="d-block w-100 py-5 px-5" style={{height:'calc(100vh - 220px'}}>
                <form action="" className="mt-4 mx-auto text-start shadow p-5 position-relative" style={{maxWidth:'700px',backgroundColor:'#f9f9f9',borderRadius:'20px'}}>
                <h3 className="text-dark text-center mb-4">Forgot Password</h3>
                    <div class="mb-3 row">
                        <label for="user-email" class="col-sm-2 col-form-label">Email</label>
                        <div class="col-sm-10">
                        <input type="text" class="form-control" id="user-email" onChange={handleChange('email')} />
                        </div>
                    </div>
                    <button className="d-block btn py-2 px-3 btn-primary mt-4 mx-auto" onClick={handleForgotPassword} style={{minWidth:'200px',height:'35px'}}> { !isloading ? <span>Reset Password</span> : <span className="d-flex justify-content-center align-items-center"><ThreeDots color="#fff" height={17}></ThreeDots></span> }</button>
                
                    <Link className="navbar-brand" to={ isPartner ? '/login?partner=1' : '/login'}>
		        Login
		    </Link>
                    <Link className="navbar-brand" to={ isPartner ? '/signup?partner=1' : '/signup'}>
		        Sign Up
		    </Link>
                </form>
            </div>
            
        </div>
      )
    } else {
	    //reset password
      return(
        <div className="forgot_password">
            <div className="d-block w-100 py-5 px-5" style={{height:'calc(100vh - 220px'}}>
                <form action="" className="mt-4 mx-auto text-start shadow p-5 position-relative" style={{maxWidth:'700px',backgroundColor:'#f9f9f9',borderRadius:'20px'}}>
                    <h3 className="text-dark text-center mb-4">Reset Password</h3>
                    <input type="hidden" id="user-key" name="user-key" value={key}/>
                    <div class="mb-3 row">
                        <label for="user-pass1" class="col-sm-2 col-form-label">New Password</label>
                        <div class="col-sm-10">
                            <input type="password" class="form-control" id="user-pass1" onChange={handleChange('password1')} />
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="user-pass2" class="col-sm-2 col-form-label">Confirm Password</label>
                        <div class="col-sm-10">
                             <input type="password" class="form-control" id="user-pass2" onChange={handleChange('password2')} />
                        </div>
                    </div>
                    <button className="d-block btn py-2 px-3 btn-primary mt-4 mx-auto" onClick={handleResetPassword} style={{minWidth:'200px',height:'35px'}}> { !isloading ? <span>Reset Password</span> : <span className="d-flex justify-content-center align-items-center"><ThreeDots color="#fff" height={17}></ThreeDots></span> }</button>
                </form>
            </div>        
        </div>
      )

    }
}

export default ForgotPassword;
