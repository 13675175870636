import Currency from "../Currency";
import pinIcon from '../../images/icons/browse/pin.svg'
import { useState } from "react";

const VirtualLand = (props) =>{
    const { asset_img_url,asset_url ,event_payment_symbol, asset_name , collection_name,event_total_price,event_quantity } = props.data;
    
    return(
        <div className='property-info'>
            {/* <p className='d-flex align-items-center land-id'>
                    <img src={pinIcon} className='me-2' alt="land-desc-image" loading='lazy'/>
                    47,141
                </p> */}
            <div className='image-wrapper'>
                <a href={asset_url} target="_blank">
                    <img src={asset_img_url} alt={'land-image'} className='img-fluid' loading='lazy'/>
                </a>
            </div>
            <div className='p-4'>
            <div className="d-flex align-items-start justify-content-between flex-fill">
                    <div>
                        <h5 className='mb-2 land-head single-liner'>{asset_name}</h5>
                        {/* <p className='m-0 land-size'>200m²</p> */}
                    </div>
                    <div className='d-flex align-items-center justify-content-end ms-2'>
                        {/* <img src={landIcon} className="me-2" alt="land-icon" loading='lazy'/> */}
                        <Currency name={event_payment_symbol} style={{width:'20px'}}/>
                        <h5 className='land-value ms-2 mb-0' style={{maxWidth:'80px',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>
                        {event_total_price}
                        </h5>
                    </div>
                </div>
	        {/*
                <p className='m-0 mt-4 d-flex align-items-center land-desc'>
                    <img src={pinIcon} className="img-fluid me-2" alt="pin" loading='lazy'/>
                    {event_quantity}
                </p>*/}
            </div>
        </div>
    )
}

export default VirtualLand;
