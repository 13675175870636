import { useEffect, useMemo, useState } from 'react';
import Scrollspy from 'react-scrollspy';
import Select from 'react-select';
import Pagination from '../Hooks/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import CrowdFundingTable from './CrowdFundingTable';
import { ThreeDots } from 'react-loader-spinner';
import { PhysicalTransactionsThunk } from '../Reduxstore/thunks';
import NoDataFound from './NoDataFound';
import WorkInProgress from './WorkInProgress';
import { aprPercentOptions, assetClassOptions, cocPercentOptions, countriesOptions, irrPercentOptions, locationOptions, platformOptions, priceRangeOptions, priceSqmOptions, totalAreaOptions, trendingCitiesOptions } from '../CustomFunctions/filterOptions';
import PhysicalProperty from './Cards/PhysicalProperty';
import { useLocation, useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from 'axios';
let debounce;

const PhysicalAssets = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('crowdfunding');
    const [showFilterInSidebar, setShowFilterInSidebar] = useState(false);
    const [sData,setSData]=useState([]);
    const [data,setData]=useState([]);
    const [searchValue,setSearchValue]=useState()
    const [columnSort,setColumnSort]=useState({isSortedDesc:false})

    const PhysicalAssetFilterOptions = [
        { value: 'filter-item-1', label: 'Filter' },
        { value: 'filter-item-2', label: 'Filter Item 2' },
        { value: 'filter-item-3', label: 'Filter Item 3' },
        { value: 'filter-item-4', label: 'Filter Item 4' },
    ];

    const crowdFundingData = [
        {
            platformid: 'crowdstreet',
            platformname: 'Crowdstreet',
            platformicon: 'https://www.crowdstreet.com/hubfs/favicon.ico',
            pricechange: "13.65%",
            location: 'Austin, TX, USA',
            licensing: 'FINRA',
            equity: 'Yes',
            debt: 'Yes',
            reit: 'Yes',
            tokenized: 'No',
            fiat: 'Yes',
            crypto: 'No',
            funding: '$USD 3.30B',
            fundvalue: 5
        },
        {
            platformid: 'realtymogul',
            platformname: 'RealtyMogul',
            platformicon: 'https://cdn.realtymogul.com/static/favicon.ico',
            pricechange: "13.65%",
            location: ' LOS ANGELES, CA , USA',
            licensing: 'N/A',
            equity: 'Yes',
            debt: 'No',
            reit: 'Yes',
            tokenized: 'No',
            fiat: 'Yes',
            crypto: 'No',
            funding: '$USD 955M',
            fundvalue: 10
        }, {
            platformid: 'property-shares',
            platformname: 'PropertyShares',
            platformicon: 'https://media1.propertyshare.in/images/fav-ico/favicon.png',
            pricechange: "13.65%",
            location: 'Bangalore, India',
            licensing: 'DIFC',
            equity: 'No',
            debt: 'Yes',
            reit: 'Yes',
            tokenized: 'Yes',
            fiat: 'Yes',
            crypto: 'Yes',
            funding: '$USD 9M',
            fundvalue: 5
        },
        {
            platformid: 'lofty',
            platformname: 'Lofty',
            platformicon: 'https://www.lofty.ai/favicon-32x32.png',
            pricechange: "13.65%",
            location: 'USA',
            licensing: 'No',
            equity: 'Yes',
            debt: 'No',
            reit: 'Yes',
            tokenized: 'Yes',
            fiat: 'N/A',
            crypto: 'Yes',
            funding: '$USD 19M',
            fundvalue: 10
        },
    ];
    // const data=[...crowdFundingData,...crowdFundingData,...crowdFundingData]
    let PageSize = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    },[currentPage,data])

    const PhysicalAssetsData = [
        {
            title: "Embassy Business Hub",
            subtitle: "Yelahanka , Bangalore",
            image: "https://propmedia2.propertyshare.in/website/property/M2psRzRmUUZsc2F0SFhUYkxoOHRLUT09/media-v2/images/listingbanner/540x420/1654068898-embassy-business-hub-bangalore-1.jpg",
            area: "1,74,497 SF",
            price: "₹7,450",
            yieldpercent: "10%",
            irr: "18.8%",
            sold: true
        },
        {
            title: "Two Inverkip Street",
            subtitle: "Greenock , United Kingdom",
            image: "https://propmedia2.propertyshare.in/website/property/Ly9hYVNOR3NiUS9YM0ZBb09VelZIZz09/media-v2/images/listingbanner/540x420/1646040960-greenock-uk-united-kingdom-1.jpg",
            area: "41,583 SF",
            price: "£106",
            sold: false
        },
        {
            title: "Embassy Tech Square",
            subtitle: "Outer Ring Road , Bangalore",
            image: "https://propmedia1.propertyshare.in/website/property/d3Joby9VTnlncndkZit1ZlAxQ2ZMdz09/media-v2/images/listingbanner/540x420/1638176089-embassy-tech-square-bangalore-1.jpg",
            area: "84,512 SF",
            yieldpercent: "8.62%",
            irr: "15.9%"
        },
        {
            title: "Embassy Tech Square",
            subtitle: "Outer Ring Road , Bangalore",
            image: "https://propmedia1.propertyshare.in/website/property/d3Joby9VTnlncndkZit1ZlAxQ2ZMdz09/media-v2/images/listingbanner/540x420/1638176089-embassy-tech-square-bangalore-1.jpg",
            area: "84,512 SF",
            price: "₹13,253",
            yieldpercent: "8.62%",
            irr: "15.9%",
            sold: false
        },
        {
            title: "Two Inverkip Street",
            subtitle: "Greenock , United Kingdom",
            image: "https://propmedia2.propertyshare.in/website/property/Ly9hYVNOR3NiUS9YM0ZBb09VelZIZz09/media-v2/images/listingbanner/540x420/1646040960-greenock-uk-united-kingdom-1.jpg",
            area: "41,583 SF",
            price: "£106",
            yieldpercent: "8.02%",
            irr: "15.7%",
            sold: false
        },
        {
            title: "Embassy Business Hub",
            subtitle: "Yelahanka , Bangalore",
            image: "https://propmedia2.propertyshare.in/website/property/M2psRzRmUUZsc2F0SFhUYkxoOHRLUT09/media-v2/images/listingbanner/540x420/1654068898-embassy-business-hub-bangalore-1.jpg",
            area: "1,74,497 SF",
            price: "₹7,450",
            yieldpercent: "10%",
            irr: "18.8%",
            sold: false
        },

    ]
    const dispatch = useDispatch();

    const [realEstateValues, setRealEstateValues] = useState({});
    // console.log("R E Values ========>>", realEstateValues);

    const handleRealEstateChange = key => event => {
        setRealEstateValues({ ...realEstateValues, [key]: event.target.value })
    }
    const [crowdFundValues, setCrowdFundValues] = useState({});
    // console.log("Crowdfund Values ========>>", crowdFundValues);


    const handleCrowdFundChange = key => event => {
        setCrowdFundValues({ ...crowdFundValues, [key]: event.target.value })
    }
    const handleToggler = (elem) => {
        if (elem.target.checked) {
            setCrowdFundValues({ ...crowdFundValues, ['criteria']: [...crowdFundValues['criteria'], elem.target.value] })
        } else {
            setCrowdFundValues({
                ...crowdFundValues, ['criteria']: [...crowdFundValues['criteria']].filter(function (btn) {
                    return btn !== elem.target.value
                })
            });
        }
    }
    const { isloading, assets, error } = useSelector((state => state.physicalAsset));
    const physical_assets = [];//results['assets']
    const tokenized_assets = [];//results['tokenized_assets']

    if (assets && assets['assets']['results']) {
        for (let i = 0; i < assets['assets']['results'].length; i++) {
            physical_assets.push(assets['assets']['results'][i]);
        }
    }
    if (assets && assets['assets']['results']) {
        for (let i = 0; i < assets['assets']['results'].length; i++) {
            tokenized_assets.push(assets['assets']['results'][i]);
        }
    }

    // console.log("Physical State ==>>>", useSelector(state => state.physicalAsset));

    const searchData = () => {
        if (activeTab == "tokenized-re") {
	    realEstateValues['tokenized'] = '1'
	}
        var str = Object.keys(realEstateValues).map(key => `${key}=${realEstateValues[key]}`).join("&");

        // dispatch(SearchThunk(`query=${query}&${str}`));
	console.log(realEstateValues);
        console.log("FINAL Q STRING ====>>>", str)
        dispatch(PhysicalTransactionsThunk(str));
    };


    const fetchAssets = () => {
        setRealEstateValues({})
        searchData();
    }

    const getCrowdfunding=()=>{
        axios(
            {url: process.env.REACT_APP_BASE_API+'/platform_data', 
                 method: "get",
                 headers:{
                   'X-API-KEY':process.env.REACT_APP_API_KEY,
                 }
               }).then((res) => {
                const finalData=res.data.platforms.filter((e)=>e.form_data);
                setData([...finalData]);
                setSData([...finalData]);
               })
               .catch((err) => {
                 console.error(err);
               });
    }
    useEffect(() => {
        let temp=sData.filter((e)=>e?.form_data.name?.includes(searchValue?.toLowerCase()));
        if(temp.length>0){
          clearTimeout(debounce);
          debounce=setTimeout(()=>{setCurrentPage(1);setData([...temp])},500);
        }else if(searchValue !==''){
            setCurrentPage(1);
            setData([])
        }else{
            setCurrentPage(1);
            setData([...sData])
        }
      }, [searchValue])
      
      useEffect(() => {
          let temp;
          if(columnSort.isSortedDesc){
              temp=data.sort((a, b) => {if (!a.form_data.name || !b.form_data.name) {
                  return 0;
                }
                return b.form_data.name.localeCompare(a.form_data.name, undefined, { sensitivity: 'base' })
              });
          }else{
              temp=data.sort((a, b) => {if (!a.form_data.name || !b.form_data.name) {
                  return 0;
                }
                return a.form_data.name.localeCompare(b.form_data.name, undefined, { sensitivity: 'base' })
              });
                  }
          setData([...temp])
      }, [columnSort])
    useEffect(() => {
        searchData();
    }, [realEstateValues])
    useEffect(() => {
        if (location.state) {
            setActiveTab(location.state);
        }
        getCrowdfunding();
    }, [])
    
    function handleListingsClick(e) {
        document.getElementById("physical-estate-tab").click();
    }

    return (
        <>
        <Helmet>
        <title>Find your perfect physical realesatete here.</title>
        <meta name="description" content="Find Any global Real Estate crowdfunding from  one convenient place" />
        <link rel="canonical" href="https://app.arra.land/physical-assets" />
        </Helmet>
        <div className=" main-content physical-assets">
            <div className="main-content__left ">
                <Scrollspy items={['listing']} className="list-unstyled sidebar" currentClassName={'active'}>
                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" style={{"cursor": "pointer"}} onClick={handleListingsClick}>
                        <span className="d-flex align-items-center me-2">
                            <i className="fa-solid fa-bars"></i>
                        </span>
                        <span className="d-block">Listings</span>
                    </a></li>
	    {/*
                    <li className='bg-primary'>
                        <div >
                            <button className="btn w-100 text-start text-white p-0" onClick={() => setShowFilterInSidebar(!showFilterInSidebar)}>Filter</button>
                            {showFilterInSidebar && (<>
                                {activeTab === "physical-estate" && (<FilterPhysicalEstate PhysicalAssetFilterOptions={PhysicalAssetFilterOptions} realEstateValues={realEstateValues} setRealEstateValues={setRealEstateValues} handleRealEstateChange={handleRealEstateChange}></FilterPhysicalEstate>)}
                                {activeTab === "crowdfunding" && (<FilterCrowdFundValues crowdFundValues={crowdFundValues} setCrowdFundValues={setCrowdFundValues} handleCrowdFundChange={handleCrowdFundChange} handleToggler={handleToggler}></FilterCrowdFundValues>)}
                                {activeTab === "tokenized-re" && (<FilterTokenizedRealEstate PhysicalAssetFilterOptions={PhysicalAssetFilterOptions} realEstateValues={realEstateValues} setRealEstateValues={setRealEstateValues} handleRealEstateChange={handleRealEstateChange}></FilterTokenizedRealEstate>)}
                            </>)}
                        </div>
                    </li>
             */}
                </Scrollspy>
            </div>
            <div className="main-content__right m-0 p-0">
                {/* <div className="container-fluid" style={{ marginTop: 30 }}>
                    <button className='btn btn-primary p-2' style={{ display: 'flex', alignItems: 'center' }} onClick={() => navigate(-1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M20 15h-8v3.586a1 1 0 0 1 -1.707 .707l-6.586 -6.586a1 1 0 0 1 0 -1.414l6.586 -6.586a1 1 0 0 1 1.707 .707v3.586h8a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1z"></path>
                        </svg>
                        &nbsp; Back
                    </button>
                </div> */}
                <div className="container-fluid">
                    <div className="row mt-0 mt-lg-5" id='listing'>
                        <div className="col-12">
                            <ul className="nav nav-tabs" id="browseTab" role="tablist">
                            <li><button className='btn' onClick={()=>navigate(-1)}><ArrowBackIosIcon/></button></li>
                                <li className="nav-item" role="presentation">
                                <h1 className='seo-optimization'><button className={`nav-link ${activeTab === "crowdfunding" ? "active" : ""}`} id="crowdfunding-tab" data-bs-toggle="tab" data-bs-target="#crowdfunding" type="button" role="tab" aria-controls="crowdfunding" aria-selected="true" onClick={(e) => {setActiveTab(e.target.attributes[6].nodeValue);getCrowdfunding()}}>
                                        Crowdfunding Platforms
                                        </button>
                                        </h1>
                                </li>
                                <li className="nav-item" role="presentation">
                                <h1 className='seo-optimization'><button className={`nav-link ${activeTab === "physical-estate" ? "active" : ""}`} id="physical-estate-tab" data-bs-toggle="tab" data-bs-target="#physical-estate" type="button" role="tab" aria-controls="physical-estate" aria-selected="false" onClick={(e) => { setActiveTab(e.target.attributes[6].nodeValue); fetchAssets() }}>
                                    Physical Real Estate</button></h1>
                                </li>
                                <li className="nav-item" role="presentation">
                                <h1 className='seo-optimization'><button className={`nav-link ${activeTab === "tokenized-re" ? "active" : ""}`} id="tokenized-re-tab" data-bs-toggle="tab" data-bs-target="#tokenized-re" type="button" role="tab" aria-controls="tokenized-re" aria-selected="false" onClick={(e) => { setActiveTab(e.target.attributes[6].nodeValue); fetchAssets() }}>
                                   Tokenized Real Estate</button></h1>
                                </li>
                                <li className="nav-item" role="presentation">
                                <h1 className='seo-optimization'><button className={`nav-link ${activeTab === "fractionalized" ? "active" : ""}`} id="fractionalized-tab" data-bs-toggle="tab" data-bs-target="#fractionalized" type="button" role="tab" aria-controls="fractionalized" aria-selected="false" onClick={(e) => { setActiveTab(e.target.attributes[6].nodeValue);}}>
                                Fractionalized</button></h1>
                                </li>
                            </ul>
                            <div style={{ "paddingLeft": "25px", "paddingRight": "25px", "paddingTop": "25px", "marginBottom": "-15px", "fontSize": "16px" }}>
                                Browse any syndicated, tokenized, fractionalized and crowdfunded real estate from around the world, as well as search and find platforms and marketplaces tokenizing and crowdfunding properties.
                            </div>
                            <div className="tab-content" id="browseTabContent">
                            <div className={`tab-pane fade ${activeTab === "crowdfunding" ? "show active": ""}`} id="crowdfunding" role="tabpanel" aria-labelledby="crowdfunding-tab">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Crowdfunding Platforms
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo">
                                        <div className="accordion-body">
                                            <div className="container-fluid g-0">
                                                <div className="row mt-5">
                                                <div className="row mt-2 mb-4">
                            <div className='col-4'><h1 className="py-3 mb-1 seo-optimization">Crowdfunding, Tokenization and Fractional Ownership</h1></div>
                            <div className="col-8 m-auto">
                                <div className="input-wrapper my-auto">
                                    <input name="query" type="text" className="form-control" id="browse-crowdfund-search" placeholder="Search for real estate platforms around the world." value={searchValue} onChange={(e)=>setSearchValue(e.target.value)} />
                                </div>
                            </div>
                        </div>
                                                   {data && <div className="crowdfunding-content">
                                                        {/* <p className='m-0 text-uppercase'>LIST OF ALL CROWDFUNDING PLATFORMS </p> */}
                                                        <CrowdFundingTable data={currentTableData} columnSort={columnSort} setColumnSort={setColumnSort}></CrowdFundingTable>

                                                        <div className="mt-4">

                                                            <Pagination
                                                                className="pagination-bar p-0"
                                                                currentPage={currentPage}
                                                                totalCount={data.length}
                                                                pageSize={PageSize}
                                                                onPageChange={page => setCurrentPage(page)}
                                                            />
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${activeTab === "physical-estate" ? "show active": ""}`} id="physical-estate" role="tabpanel" aria-labelledby="physical-estate-tab">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Physical Real Estate
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="collapse show " aria-labelledby="headingOne">
                                        <div className="accordion-body ">
                                            <div className="container-fluid g-0">
                                                <div className="row mt-5">

                                                    {physical_assets !== undefined && physical_assets !== '' && !isloading && physical_assets.map((item, index) => {
                                                        return <PhysicalProperty key={index} data={item} />
                                                    })}

                                                    {isloading && <div className="loader" style={{ position: 'relative', inset: '0', transform: 'none' }}>
                                                        <ThreeDots color="#8FF129">
                                                        </ThreeDots>
                                                    </div>}
                                                    {error !== undefined && !isloading && error && <NoDataFound style={{ maxWidth: '400px' }}></NoDataFound>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* TOKENIZED REAL ESTATE */}
                                <div className={`tab-pane fade ${activeTab === "tokenized-re" ? "show active": ""}`} id="tokenized-re" role="tabpanel" aria-labelledby="tokenized-re-tab">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Tokenized Real Estate
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree">
                                        <div className="accordion-body">
                                            <div className="container-fluid g-0">
                                                <div className="row mt-5">

                                                    {tokenized_assets !== undefined && tokenized_assets !== '' && !isloading && tokenized_assets.map((item, index) => {
                                                        return <PhysicalProperty key={index} data={item} />
                                                    })}

                                                    {isloading && <div className="loader" style={{ position: 'relative', inset: '0', transform: 'none' }}>
                                                        <ThreeDots color="#8FF129">
                                                        </ThreeDots>
                                                    </div>}
                                                    {error !== undefined && !isloading && error && <NoDataFound style={{ maxWidth: '400px' }}></NoDataFound>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${activeTab === "fractionalized" ? "show active": ""}`} id="fractionalized" role="tabpanel" aria-labelledby="fractionalized-tab">
                                <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Fractionalized
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour">
                                        <div className="accordion-body">
                                            <div className="container-fluid g-0">
                    <div className="row mt-3 mt-lg-5 leaderboard-table-item" id="phyrtual-hybrid">
                        {/* <h3 className="py-3 mb-1">Phyrtual-Hybrid Market</h3> */}
                        <WorkInProgress style={{ maxWidth: '400px' }} loadingText />
                    </div>

	    </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

function FilterCrowdFundValues(props) {
    return (<>
        <form action="">
            <div className="row mt-2 mb-4">
                <div className="col-12">
                    <div className="input-wrapper">
                        <input type="text" className="form-control" id="leaderboard-search" placeholder="Search your platforms or Investment based on your criteria" />
                    </div>
                </div>
            </div>
            <div className="mt-3 p-4 filter-menu ">
                <div className='filter-menu__item'>
                    <p className='m-0 text-uppercase mb-2'>PRICE PER SQM</p>
                    <Select placeholder={'Select Price'} options={priceSqmOptions} classNamePrefix={'react-select'} components={{
                        IndicatorSeparator: () => null
                    }} onChange={e => props.setCrowdFundValues({
                        ...props.crowdFundValues,
                        ['priceSqm']: e.value
                    })} />
                </div>
                <div className='filter-menu__item'>
                    <p className='m-0 text-uppercase mb-2'>IRR %</p>
                    <Select placeholder={'Select IRR'} options={irrPercentOptions} classNamePrefix={'react-select'} components={{
                        IndicatorSeparator: () => null
                    }} onChange={e => props.setCrowdFundValues({
                        ...props.crowdFundValues,
                        ['irrComparison']: e.value
                    })} />
                </div>
                <div className='filter-menu__item'>
                    <p className='m-0 text-uppercase mb-2'>COC %</p>
                    <Select placeholder={'Select IRR'} options={cocPercentOptions} classNamePrefix={'react-select'} components={{
                        IndicatorSeparator: () => null
                    }} onChange={e => props.setCrowdFundValues({
                        ...props.crowdFundValues,
                        ['cocComparison']: e.value
                    })} />
                </div>
                <div className='filter-menu__item'>
                    <p className='m-0 text-uppercase mb-2'>APR %</p>
                    <Select placeholder={'Select IRR'} options={aprPercentOptions} classNamePrefix={'react-select'} components={{
                        IndicatorSeparator: () => null
                    }} onChange={e => props.setCrowdFundValues({
                        ...props.crowdFundValues,
                        ['aprComparison']: e.value
                    })} />
                </div>
                <div className='filter-menu__item'>
                    <p className='m-0 text-uppercase mb-2'>RETURN CALCULATOR</p>
                    <div className="py-2 px-3 d-flex align-items-center border">
                        <p className='m-0 bg-light-custom px-2'>{props.crowdFundValues.returnPercentage || 20}%</p>
                        <div className="ms-3 d-flex">
                            <span>0%</span>
                            <div className="custom-slider mx-2">
                                <input type="range" min={0} step={1} max={60} className="slider" onChange={props.handleCrowdFundChange('returnPercentage')} id="crowdfundReturn" />
                            </div>
                            <span>60%</span>
                        </div>
                    </div>
                </div>
                <div className='filter-menu__item'>
                    <p className='m-0 text-uppercase mb-2'>CITIES</p>
                    <Select placeholder={'Select City'} options={trendingCitiesOptions} classNamePrefix={'react-select'} components={{
                        IndicatorSeparator: () => null
                    }} onChange={e => props.setCrowdFundValues({
                        ...props.crowdFundValues,
                        ['city']: e.value
                    })} />
                </div>
                <div className='filter-menu__item'>
                    <p className='m-0 text-uppercase mb-2'>COUNTRY</p>
                    <Select placeholder={'Select City'} options={countriesOptions} classNamePrefix={'react-select'} components={{
                        IndicatorSeparator: () => null
                    }} onChange={e => props.setCrowdFundValues({
                        ...props.crowdFundValues,
                        ['country']: e.value
                    })} />
                </div>
                <div className='filter-menu__item'>
                    <p className='m-0 text-uppercase mb-2'>ASSETS CLASS COMPARISON</p>
                    <Select placeholder={'Select Asset Class'} options={assetClassOptions} classNamePrefix={'react-select'} components={{
                        IndicatorSeparator: () => null
                    }} onChange={e => props.setCrowdFundValues({
                        ...props.crowdFundValues,
                        ['assetClass']: e.value
                    })} />
                </div>
                <div className='filter-menu__item'>
                    <p className='m-0 text-uppercase mb-2'>CRITERIA</p>
                    <div className="d-flex flex-wrap">
                        {['blockchain', 'nft', 'tokenized'].map((btn, index) => {
                            return <div key={`criteria-btn-${index}`} className={`toggler-wrapper mb-3`}>
                                <div className="custom-toggler">
                                    <label className="switch">
                                        <input type="checkbox" value={btn} onClick={e => props.handleToggler(e)} />
                                        <span className="slider round"></span>
                                    </label>
                                    <p className='m-0 text-capitalize'> {btn}</p>
                                </div>
                            </div>;
                        })}

                    </div>
                </div>
                <div className='filter-menu__item'>
                    <p className='m-0 text-uppercase mb-2'>LOCKUP PERIOD <span className='text-capitalize'>( Months )</span></p>
                    <div className="py-2 px-3 d-flex align-items-center border">
                        <p className='m-0 bg-light-custom px-2'>{props.crowdFundValues.lockupPeriod || 36}</p>
                        <div className="ms-3 d-flex">
                            <span>0</span>
                            <div className="custom-slider mx-2">
                                <input type="range" min={0} step={1} max={36} className="slider" onChange={props.handleCrowdFundChange('lockupPeriod')} id="myRange" />
                            </div>
                            <span>36</span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>);
}

function FilterTokenizedRealEstate(props) {
    return (<form action="">
        <div className="row mt-2 mb-4">
            <div className="col-12">
                <div className="input-wrapper">
                    <input type="text" className="form-control" id="leaderboard-search" placeholder="Search tokenized real estate assets.." />
                </div>
            </div>
            <div className="col-12 mt-3 d-flex align-items-center flex-wrap  justify-content-xl-between">
                <Select placeholder={'RECENTLY LISTED'} options={props.PhysicalAssetFilterOptions} className="react-select-style-2" classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => console.log(e.value)} />

                <div className="custom-toggler mt-3">
                    <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                    </label>
                    <p className='m-0'> ON SALE</p>
                </div>
            </div>
        </div>
        <div className="mt-3 p-4 filter-menu ">
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>PROPERTY TYPE</p>
                <Select placeholder={'Select Property'} options={assetClassOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['propertyType']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>PRICE PER SQM</p>
                <Select placeholder={'Select Price / SQM'} options={priceSqmOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['priceSqm']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>PRICE RANGE</p>
                <Select placeholder={'Select Price Range'} options={priceRangeOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['price']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>LOCATION</p>
                <Select placeholder={'Select Location'} options={locationOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['location']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>Trending Cities</p>
                <Select placeholder={'Select City'} options={trendingCitiesOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['location']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>AREA  ( M<sup>2</sup> )</p>
                <Select placeholder={'Select Area'} options={totalAreaOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['area']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>PLATFORMS</p>
                <Select placeholder={'Select Platform'} options={platformOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['platform']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>FILTER</p>
                <div className="d-flex flex-wrap">
                    {['All', 'Upcoming', 'Active', 'Sold'].map((btn, index) => {
                        return <button key={`collection-btn-${index}`} className={`btn mb-3 mb-lg-0 text-capitaliz ${props.realEstateValues.filter == btn ? 'active' : ''}`} value={btn} type='button' onClick={e => props.setRealEstateValues({
                            ...props.realEstateValues,
                            ['filter']: e.target.value
                        })}>{btn}</button>;
                    })}

                </div>
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>CURRENT CASH ON CASH RETURN </p>
                <div className="py-2 px-3 d-flex align-items-center border">
                    <p className='m-0 bg-light-custom px-2'>{props.realEstateValues.coc || 30}%</p>
                    <div className="ms-3 d-flex">
                        <span>0%</span>
                        <div className="custom-slider mx-2">
                            <input type="range" min={0} step={1} max={30} className="slider" onChange={props.handleRealEstateChange('coc')} id="myRange" />
                        </div>
                        <span>30%</span>
                    </div>
                </div>
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>CURRENT PROJECTED IRR</p>
                <div className="py-2 px-3 d-flex align-items-center border">
                    <p className='m-0 bg-light-custom px-2'>{props.realEstateValues.irr || 30}%</p>
                    <div className="ms-3 d-flex">
                        <span>0%</span>
                        <div className="custom-slider mx-2">
                            <input type="range" min={0} step={1} max={30} className="slider" onChange={props.handleRealEstateChange('irr')} id="myRangeTwo" />
                        </div>
                        <span>30%</span>
                    </div>
                </div>
            </div>
            {
                /* <div className='d-flex align-items-end'>
                //     <button className='btn btn-primary' type='button' onClick={handleFormReset}>Reset Form</button>
                // </div> */
            }
        </div>
    </form>);
}

function FilterPhysicalEstate(props) {
    return (<form action="">
        <div className="row mt-2 mb-4">
            <div className="col-12">
                <div className="input-wrapper">
                    <input type="text" className="form-control" id="leaderboard-search" placeholder="Search any physical real estate assets.." />
                </div>
            </div>
            <div className="col-12 mt-3 d-flex align-items-center flex-wrap  justify-content-xl-between">
                <Select placeholder={'RECENTLY LISTED'} options={props.PhysicalAssetFilterOptions} className="react-select-style-2" classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => console.log(e.value)} />

                <div className="custom-toggler mt-3">
                    <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                    </label>
                    <p className='m-0'> ON SALE</p>
                </div>
            </div>
        </div>
        <div className="mt-3 p-4 filter-menu ">
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>PROPERTY TYPE</p>
                <Select placeholder={'Select Property'} options={assetClassOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['propertyType']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>PRICE PER SQM</p>
                <Select placeholder={'Select Price / SQM'} options={priceSqmOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['priceSqm']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>PRICE RANGE</p>
                <Select placeholder={'Select Price Range'} options={priceRangeOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['price']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>LOCATION</p>
                <Select placeholder={'Select Location'} options={locationOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['location']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>Trending Cities</p>
                <Select placeholder={'Select City'} options={trendingCitiesOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['location']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>AREA  ( M<sup>2</sup> )</p>
                <Select placeholder={'Select Area'} options={totalAreaOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['area']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>PLATFORMS</p>
                <Select placeholder={'Select Platform'} options={platformOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setRealEstateValues({
                    ...props.realEstateValues,
                    ['platform']: e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>FILTER</p>
                <div className="d-flex flex-wrap">
                    {['All', 'Upcoming', 'Active', 'Sold'].map((btn, index) => {
                        return <button key={`collection-btn-${index}`} className={`btn mb-3 mb-lg-0 text-capitaliz ${props.realEstateValues.filter == btn ? 'active' : ''}`} value={btn} type='button' onClick={e => props.setRealEstateValues({
                            ...props.realEstateValues,
                            ['filter']: e.target.value
                        })}>{btn}</button>;
                    })}

                </div>
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>CURRENT CASH ON CASH RETURN </p>
                <div className="py-2 px-3 d-flex align-items-center border">
                    <p className='m-0 bg-light-custom px-2'>{props.realEstateValues.coc || 30}%</p>
                    <div className="ms-3 d-flex">
                        <span>0%</span>
                        <div className="custom-slider mx-2">
                            <input type="range" min={0} step={1} max={30} className="slider" onChange={props.handleRealEstateChange('coc')} id="myRange" />
                        </div>
                        <span>30%</span>
                    </div>
                </div>
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>CURRENT PROJECTED IRR</p>
                <div className="py-2 px-3 d-flex align-items-center border">
                    <p className='m-0 bg-light-custom px-2'>{props.realEstateValues.irr || 30}%</p>
                    <div className="ms-3 d-flex">
                        <span>0%</span>
                        <div className="custom-slider mx-2">
                            <input type="range" min={0} step={1} max={30} className="slider" onChange={props.handleRealEstateChange('irr')} id="myRangeTwo" />
                        </div>
                        <span>30%</span>
                    </div>
                </div>
            </div>
            {
                /* <div className='d-flex align-items-end'>
                   <button className='btn btn-primary' type='button' onClick={handleFormReset}>Reset Form</button>
                </div> */
            }
        </div>
    </form>);
}

export default PhysicalAssets;
