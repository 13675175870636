import { useEffect, useState } from "react";
import { useDispatch , useSelector} from "react-redux";
import Select from 'react-select';
import Scrollspy from 'react-scrollspy';
import PerformanceTable from "./PerformanceTable";
import MyChart from "./MyChart";
import {TailSpin, ThreeDots} from 'react-loader-spinner';
import pinIcon from '../images/icons/browse/pin.svg';
import axios from 'axios';
import myAssetsLogo from '../images/icons/dashboard/my-assets.svg';
import performanceLogo from '../images/icons/dashboard/performance.svg';
import importAssetLogo from '../images/icons/dashboard/import-assets.svg';
import forYouLogo from '../images/icons/dashboard/for-you.svg';
import learnAndEanLogo from '../images/icons/dashboard/learn-and-earn.svg';
import notificationsLogo from '../images/icons/dashboard/notifications.svg';
import inviteLogo from '../images/icons/dashboard/invite.svg';
import freeSectionImage from '../images/dashboard-free-section.png';
import { AddAssetThunk, AssetFetchThunk, DashboardThunk, Marketplacesthunk } from "../Reduxstore/thunks";
import Currency from "./Currency";
import NoDataFound from "./NoDataFound";
import showToast from './../CustomFunctions/showToast'
import formatPrice from "../CustomFunctions/formatPrice";
import addAssetFormInputs from "../CustomFunctions/addAssetFormInputs";
import WatchListAssetItem from "./Cards/WatchListAssetItem";
import formatDecimal from "../CustomFunctions/formatDecimal";
import { useMemo } from "react";
import { Helmet } from "react-helmet-async";

const DashboardAssetItem = (props) =>{
    const {name,assetDollarValue,token_name,assetDescvalue,image} = props.data;

    return(
        <div className="dashboard-asset-item" style={{backgroundImage:`url(${image})`}}>
            <div>
                <h5 className="asset-value double-liner">{name} </h5>
                {/* <h6 className="asset-dollar-value">{assetDollarValue}</h6> */}
            </div>
            <div className="d-flex justify-content-between"><p className="mb-0 asset-dollar-value">{token_name}</p><p className="mb-0 price-change">{assetDescvalue}</p></div>
        </div>
    )
}


const Dashboard = () =>{
    const [activePortfolioBtn,setactivePortfolioBtn] = useState();

    const [addAssetData, setaddAssetData] = useState({
        name:'',
        platform:'',
        currency:'',
        price_per_share:'',
        share_number:'',
        date_purchased:'',
        url:'',
        image_url:''
    });
    

    const[totalVal,setTotalVal] = useState(0);

    const [sidebar,setSidebar] = useState(false);

    const { name,platform,currency,price_per_share,share_number,date_purchased,url,image_url} = addAssetData;
    
    const handleAddAssetForm = key => event => {
        setaddAssetData({...addAssetData, [key] : event.target.value})
    };

    const handleActive = (id) =>{
        setactivePortfolioBtn(id)
    }

    const WatchListData = [
        {
            "asset_type": "Land Link",
            "currency": "INR",
            "last_update": "",
            "collection": "propertyshare",
            "chain": "Crowd",
            "name": "IBC Knowledge Park",
            "price": 11350,
            "marketplace_subtype": "Bannerghatta Road , Bangalore",
            "image": "https://propmedia1.propertyshare.in/website/property/NlNSVjFwQUdNR3pzQkF1ZGJ3Q0krdz09/media-v2/images/listingbanner/540x420/1578665086-ibc-knowledge-park-bangalore-1.jpg",
            "url": "https://www.propertyshare.in/commercial-properties/bangalore/ibc-knowledge-park",
            "total_invest": 32277,
            "yield": "9%",
            "return_target": "18.2%",
            "target_coc": "9%",
            "target_irr": "18.2%",
            "percent_sold": 1,
            "item_id": "propertyshare_ibc-knowledge-park"
        },
        {
            "asset_type": "Land Link",
            "currency": "USD",
            "last_update": "",
            "collection": "lofty",
            "chain": "Algorand",
            "name": "917 Pawnee Ave, Memphis, TN 38109",
            "price": 67.60601851851852,
            "marketplace_subtype": "memphis",
            "image": "https://images.lofty.ai/images/01F3NMA3R3E9QMGJKJ2TF4A6HK/001.webp",
            "url": "https://www.lofty.ai/property_deal/917-Pawnee-Ave_Memphis-TN-38109",
            "total_invest": 146029,
            "yield": null,
            "return_target": null,
            "target_coc": 5.28,
            "target_irr": 17.28,
            "percent_sold": 1,
            "item_id": "lofty_237913743"
        },
        {
            "_id": "sandbox_7099043149",
            "collection_slug": "sandbox",
            "collection": "The Sandbox",
            "collection_url": "https://opensea.io/collection/sandbox",
            "asset_id": 367965386,
            "asset_name": "LAND (-48, 62)",
            "asset_description": "A LAND is a digital piece of real estate in The Sandbox metaverse that players can buy to build experiences on top of. Once you own a LAND, you will be able to populate it with Games and Assets. Each LAND is a unique (non-fungible) token lying on the public Ethereum blockchain (ERC-721).",
            "asset_contract_date": "2022-01-27T23:55:22.193843",
            "asset_url": "https://opensea.io/assets/ethereum/0x5cc5b05a8a13e3fbdb0bb9fccd98d38e50f90c38/108684",
            "asset_img_url": "https://lh3.googleusercontent.com/oiDKpsHkz2rblEWvwDD8sPj1DlXnoKxYr70baOnbshl4htGrBKBl61mri5qDqSE4-UVW-0NprEljWelQ1RcRRIVkzJUBW7478KNd2VU",
            "total_invest": 348279,
            "event_id": 7099043149,
            "price": 1.8768768,
            "currency": "ETH",
            "event_time": "2022-07-08T05:57:31",
            "event_auction_type": null,
            "event_contract_address": "0x00000000006c3852cbef3e08e8df289169ede581",
            "event_quantity": "1",
            "event_payment_symbol": "ETH",
            "event_total_price": 2.1,
            "created": "2022-07-08 05:57:31",
            "last_updated": "2022-07-08 06:20:29",
            "name": "LAND (-48, 62)",
            "description": "A LAND is a digital piece of real estate in The Sandbox metaverse that players can buy to build experiences on top of. Once you own a LAND, you will be able to populate it with Games and Assets. Each LAND is a unique (non-fungible) token lying on the public Ethereum blockchain (ERC-721).",
            "image": "https://api.sandbox.game/lands/72b2e1fc-c1d9-4c2e-886a-2df5e41fd4b3/v3/preview-560px-x-560px.webp",
            "sandbox": {
                "name": "-48, 62",
                "description": "3",
                "image": "https://api.sandbox.game/estates/72b2e1fc-c1d9-4c2e-886a-2df5e41fd4b3/logo.jpeg"
            },
            "properties": [
                {
                    "trait_type": "Land X",
                    "value": 156,
                    "max_value": 408,
                    "display_type": "number"
                },
                {
                    "trait_type": "Land Y",
                    "value": 266,
                    "max_value": 408,
                    "display_type": "number"
                },
                {
                    "trait_type": "Land Type",
                    "value": "Regular"
                }
            ],
            "external_url": "https://www.sandbox.game/en/lands/72b2e1fc-c1d9-4c2e-886a-2df5e41fd4b3/"
        }
    ];
    
    const dispatch = useDispatch();
    
    const {isloading,dashboard='',error} = useSelector(state => state.dashboard);

    let myAssets ={};

    if(dashboard!==''){
/*	    console.log(dashboard)
	if (dashboard.assets && dashboard.assets.digital_land && dashboard.assets.digital_land.ethereum){
	    let address = dashboard.assets.digital_land.ethereum.keys()[0]
            myAssets.virtual = dashboard.assets.digital_land.ethereum[address];
        } else {
	    myAssets.virtual = [];
	}
	if (dashboard.assets && dashboard.assets.land_link) {
            myAssets.physical = dashboard.assets.land_link;
	} else {
	    myAssets.physical = [];
	
	}
*/
	    myAssets.virtual = [];
	    for (var chain in dashboard.assets.digital_land) {
	        for (var key in dashboard.assets.digital_land[chain]){
                    for (var obj in dashboard.assets.digital_land[chain][key]){
                        myAssets.virtual.push(obj);
	      	    }
	        }
            }
	    myAssets.physical = [];
	    for (var key in dashboard.assets.land_link) {
		for (var id in dashboard.assets.land_link[key]){
		    let obj = dashboard.assets.land_link[key][id];
                    myAssets.physical.push(obj)
		}
	    }
    } else {
	    myAssets.virtual = []
	    myAssets.physical = []
    }
    // console.log("myAssets",myAssets);

    const getData =  () =>{
        return Array.from({length: window.innerWidth > 1024 ? 20 : 10}, () => Math.floor(Math.random() * totalVal)).sort(function(a, b) {
        return a - b;
        });;
    }; 

    const chartData = useMemo(()=>{
        return {
            // labels: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
            labels: [100,...getData(),totalVal],
            datasets: [
                {
                // data: [0, 20, 45, 60 ,85, 120, 250, 450,800,1250,totalVal ],
                data: [100,...getData(),totalVal],
            borderColor: '#8FF129',
            backgroundColor: '#8FF129',
            pointRadius: window.innerWidth > 1024 ? 5: 4,
            pointHoverRadius: window.innerWidth > 1024 ? 10 : 5,
            },
        ],
        };
    },[totalVal]) 

    const myChartOptions = {
        responsive: true,
        scales: {
          x: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: false,
            }
          },
          y: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: false,
            }
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            padding: window.innerWidth > 1024 ? 20 : 10,
            bodyFont: {
              size: window.innerWidth > 1024 ? 36 : 14,
            },
            backgroundColor: '#8FF129',
            cornerRadius: 20,
            caretSize: window.innerWidth > 1024 ? 10 : 5,
            caretPadding: window.innerWidth > 1024 ? 20 : 10,
            callbacks: {
              title: function() {},
              beforeLabel: function() {},
            },
            displayColors:false,
          },
      
        },
    };

    // console.log("DASHBOARD STORE STATE====>",dashboard)

    const handleTotalValue = (value) =>{
        setTotalVal(value);
    }
    const {isloading:addAssetLoading,addasset,error:addAssetError}  = useSelector(state=>state.addAsset);

    const addAsset = (e) =>{
        e.preventDefault();
        if(name == ''){
            showToast('Enter Asset Name','error');
        }else if(platform == ''){
            showToast('Enter Asset Platform','error');
        }
        else if(currency == ''){
            showToast('Enter Asset Currency','error');
        }
        else if(price_per_share == ''){
            showToast('Enter Asset Price Per Share','error');
        }
        else if(share_number == ''){
            showToast('Enter Asset Share Number','error');
        }
        else if(date_purchased == ''){
            showToast('Enter Asset Date Purchased ','error');
        }
        else if(url == ''){
            showToast('Enter Asset URL','error');
        } 
        else if(image_url == ''){
            showToast('Enter Asset Image URL','error');
        }else{
            dispatch(AddAssetThunk(addAssetData));

            if(addasset){
                setaddAssetData({
                    name:'',
                    platform:'',
                    currency:'',
                    price_per_share:'',
                    share_number:'',
                    date_purchased:'',
                    url:'',
                    image_url:''
                })
            }
           
        }
    }

    const handleSidebar = (e) =>{
        setSidebar(!sidebar);
        e.preventDefault();
    }

    const getDashboardData = () =>{
        dispatch(DashboardThunk());
    }



    const submitToMailingList = () => {
      let body = {"email": document.getElementById("mailing_list_id").value};
      axios(
         {url: process.env.REACT_APP_BASE_API+'/join_mailing_list',
          method: "post",
          data: body,
          headers:{
            'X-API-KEY':process.env.REACT_APP_API_KEY,
          }
        }).then((res) => {
          showToast("Thanks! We'll email you soon!", 'success');
        })
        .catch((err) => {
          console.error(err);
	  showToast("Error adding to list.", "error");
        });


    }

    useEffect(()=>{
        getDashboardData();
    },[])
    
    return(
        <>
        <Helmet>
        <title>Real Estate Dashboard | Araa </title>
        <meta name="description" content="Monitor all your digitized, tokenized and virtual real estate from one dashboard.  Investors diversify their crowdfunding real estate investments and buy from many sites: We make it easy for you to efficiently track your performance with real-time data and news related to your investments.  You can symbols.  You can create custom views using many data points."/>
        <meta property="og:title" content="Real Estate Dashboard | Araa " />
        <meta property="og:description" content="Monitor all your digitized, tokenized and virtual real estate from one dashboard.  Investors diversify their crowdfunding real estate investments and buy from many sites: We make it easy for you to efficiently track your performance with real-time data and news related to your investments.  You can symbols.  You can create custom views using many data points." />
        <meta property="og:image" content="https://static.wixstatic.com/media/339d55_6a24db0a3d844c06950a55881e78f481%7Emv2.jpg/v1/fit/w_2500,h_1330,al_c/339d55_6a24db0a3d844c06950a55881e78f481%7Emv2.jpg" />
        <meta property="og:image:width" content="2500" />
        <meta property="og:image:height" content="1330" />
        <meta property="og:url" content="https://app.araa.land/dashboard" />
        <meta property="og:site_name" content="Araa" />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Real Estate Dashboard | Araa " />
        <meta property="twitter:description" content="Monitor all your digitized, tokenized and virtual real estate from one dashboard.  Investors diversify their crowdfunding real estate investments and buy from many sites: We make it easy for you to efficiently track your performance with real-time data and news related to your investments.  You can symbols.  You can create custom views using many data points." />
        <meta property="twitter:image" content="https://static.wixstatic.com/media/339d55_6a24db0a3d844c06950a55881e78f481%7Emv2.jpg/v1/fit/w_2500,h_1330,al_c/339d55_6a24db0a3d844c06950a55881e78f481%7Emv2.jpg" />
        <link rel="canonical" href="https://app.arra.land/dashboard" />
        </Helmet>
        <div className="main-content dashboard">
            <div className="main-content__left ">
                <Scrollspy items={ ['my-assets', 'performance','add-assets'/*,'watchlist','learn-and-earn','notifications','invite' */] } className={`list-unstyled sidebar ${sidebar && 'active'}`} offset={-125} currentClassName={'active'}>
                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#my-assets"> <span className="d-flex align-items-center me-2"><img src={myAssetsLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Assets</span></a></li>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#performance" > <span className="d-flex align-items-center me-2"><img src={performanceLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Performance</span></a></li>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#add-assets"> <span className="d-flex align-items-center me-2"><img src={importAssetLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Add Assets</span></a></li>
                    {/*
                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#watchlist"> <span className="d-flex align-items-center me-2"><img src={forYouLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Watchlist </span></a></li>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#lear-and-earn"> <span className="d-flex align-items-center me-2"><img src={learnAndEanLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Learn & Earn </span></a></li>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#notifications"> <span className="d-flex align-items-center me-2"><img src={notificationsLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Notifications </span></a></li>
                    
                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#invite" data-bs-toggle="modal" data-bs-target="#invite-friends"> <span className="d-flex align-items-center me-2"><img src={inviteLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Invite Friends </span></a></li>
		    */}
                </Scrollspy>
                <button className="btn btn-primary sidebar-toggler" onClick={((e)=>handleSidebar(e))}>
                    <span></span><span></span><span></span>
                </button>
                <button className="btn btn-primary sidebar-close" onClick={((e)=>handleSidebar(e))}>
                    X
                </button>
            </div>
            <div className="main-content__right">
                <div className="container-fluid px-3 px-lg-0">
                    <h1 className="mt-4 text-center">Your dashboard for your real estate portfolio.</h1>
                    <div className="row mt-4" id="my-assets">
                        <div className="col-12 col-md-6 col-lg-7">
                            <h1 className="seo-optimization">Portfolio</h1>
                            <div className="chart-wrapper">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        { /*totalVal!=0 &&*/ !isloading && <h2>$ {formatPrice(formatDecimal(totalVal))}</h2> }
                                        {error && dashboard=='' && !isloading && <h3 className="text-danger d-flex align-items-center">$ Error in Getting Balance ! </h3> }
                                        {isloading && <ThreeDots color='#8FF129' height={40} width={40}/>}
                                        <h5>Portfolio Balance</h5>
                                    </div>
                                    {/* <div className="dropdown action-menu-wrapper">
                                        <button className="btn  dropdown-toggle action-menu-btn" type="button" id="portfolioActionBtn" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="fa-solid fa-ellipsis-vertical" style={{fontSize:'2rem'}}></i>
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="portfolioActionBtn">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </div> */}
                                    { error && !isloading && totalVal==0 &&
                                        <button className="btn btn-primary ms-2" onClick={getDashboardData}><i className="fa-solid fa-rotate-right" style={{color: '#fff'}}></i></button> 
                                    }
                                </div>
                                {/* totalVal!==0 && !isloading && <MyChart options={myChartOptions} key={`total${totalVal}`} data={chartData}></MyChart>

                                {isloading && <ThreeDots color='#8FF129' height={40} width={40}/>}

                                {error!==undefined && totalVal==0  && error && !isloading ? (
                                            <NoDataFound style={{maxWidth:'200px'}}/>
                                    ) : null
                                }
                                <div className="d-flex flex-wrap mt-5 button-wrapper">
                                    <button className={`btn ${activePortfolioBtn=='dash-1h' ? 'active' :''}`} id="dash-1h" onClick={((e)=>{handleActive(e.target.id)})}>1H</button>
                                    <button  className={`btn ${activePortfolioBtn=='dash-2h' ? 'active' :''}`} id="dash-2h" onClick={((e)=>{handleActive(e.target.id)})}>24H</button>
                                    <button  className={`btn ${activePortfolioBtn=='dash-1w' ? 'active' :''}`} id="dash-1w" onClick={((e)=>{handleActive(e.target.id)})}>1W</button>
                                    <button  className={`btn ${activePortfolioBtn=='dash-1m' ? 'active' :''}`} id="dash-1m" onClick={((e)=>{handleActive(e.target.id)})}>1M</button>
                                    <button  className={`btn ${activePortfolioBtn=='dash-1y' ? 'active' :''}`} id="dash-1y" onClick={((e)=>{handleActive(e.target.id)})}>1Y</button>
                                    <button  className={`btn ${activePortfolioBtn=='dash-all' ? 'active' :''}`} id="dash-all" onClick={((e)=>{handleActive(e.target.id)})}>ALL</button>
                                </div>
	                        */}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5">
                            <div className="dashboard-asset-item-wrapper">
                            <h1 className="seo-optimization">Your Assets</h1>
                            <div className="h-100">
                                { isloading ? <div className="loader"><ThreeDots color="#8FF129"></ThreeDots></div> : null}

                                { isloading!==true && dashboard!=='' && myAssets!='' && 
                                    <div  className="dashboard-asset-item-box">
                                    { (myAssets.virtual ).map((item,index)=>{
                                        return index<2 ? <DashboardAssetItem data={item}  key={`dash-asset-item-${index}`}></DashboardAssetItem> : ''
                                    })}
                                    
                                    

                                    { myAssets.physical.map((item,index)=>{
                                        return index<1 ? <DashboardAssetItem data={item}  key={`dash-asset-item-${index}`}></DashboardAssetItem> : ''
                                    })}

                                   
                                    </div>
                                }

                                {error!==undefined && dashboard=='' && error && !isloading ? (
                                            <NoDataFound style={{maxWidth:'400px'}}/>
                                    ) : null
                                }
                            </div>
                            </div> 
                        </div>
                    </div>
                    <div className="row mt-5 mt-lg-5" id="performance">
                        <div className="col-12 col-md-6 col-lg-7">
                            <div className="d-flex flex-wrap align-items-center justify-content-between pb-3">
                                <h3>Performance</h3>
                                {/* <div className="d-flex align-items-center">
                                    <div className="select-wrapper me-3">
                                        <Select classNamePrefix={'react-select'}  options={timeFilterOptions} defaultValue={timeFilterOptions[0]} 
                                        components={{
                                            IndicatorSeparator: () => null
                                        }} onChange={((e)=>setValues({...values,['performenceTime']:e.value}))}/>
                                    </div>
                                    <div className="select-wrapper">
                                    <Select  classNamePrefix={'react-select'}  options={topGainersOptions} defaultValue={topGainersOptions[0]} 
                                        components={{
                                            IndicatorSeparator: () => null
                                        }} onChange={((e)=>setValues({...values,['performanceType']:e.value}))}/>
                                    </div>
                                </div> */}
                            </div>
                            <div className="mt-2">
                                <PerformanceTable handleTotal = {handleTotalValue}></PerformanceTable>
                            </div>

                        </div>
                    </div>
                    <div className="mt-3 row" id="add-assets">
                        <div className="col-12 col-md-6 col-lg-6">
                        <h3>Add My Assets</h3>
                            <form action="" className="">
                                <div className="row">
                                    <div className="col-12">
                                        {addAssetFormInputs.map((input)=>{
                                            return(
                                            <div class="mb-3">
                                                <label for={input.id} class="form-label">{input.label}</label>
                                                <input type={input.type} class="form-control" id={input.id} placeholder={input.placeholder} onChange={handleAddAssetForm(`${input.value}`)} />
                                            </div>
                                            )
                                        })}
                                        <button className="btn mt-3 btn-primary py-2 " style={{minWidth:'150px'}}  onClick={(e)=>addAsset(e)} > { !addAssetLoading ? <span>Add Asset</span> : <span className="d-flex justify-content-center align-items-center"><ThreeDots color="#fff" height={17}></ThreeDots></span> }</button>
              
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="free-section">
                                <div>
                                    <h3 className="free-section-head">Join Our Mailing List </h3>
                                    <p>Learn more about the latest crowdfunding real estate news</p>
                                </div>
                                <p className="free-section-desc">Get the latest news on developments in the crowdfunding and virtual real estate markets.</p>
                                <form action="/api/join_mailing">
                                    <div className="d-flex flex-wrap flex-lg-nowrap mb-3">
                                        <input id="mailing_list_id" type="email" className="form-control" placeholder="Enter email addresses" aria-label="Enter email addresses"/>
                                        <span className="mt-2 mx-auto mt-lg-0 ms-lg-2 btn btn-primary px-5" id="free-submit-btn" onClick={submitToMailingList}>Enter</span>
                                    </div>
                                </form>{/*
                                <div className="img-wrapper">
                                    <img src={freeSectionImage} className="img-fluid" alt="" loading='lazy'/>
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                        <p className="m-0">Only certain users can accept <br /> your invitation.
                                        </p> 
                                        <a href="terms" className="terms">View Terms and Conditions</a>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mt-lg-5" id="watchlist">
	             {/*
                        <h3 className="mb-3">Watchlist</h3>
                        {WatchListData.map((watchListItem)=>{
                            return <WatchListAssetItem data={watchListItem}/>
                        })}
		      */}
                    </div>
                </div>
            </div>
            
        </div>
        </>
    )
}

export default Dashboard;
