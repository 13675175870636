export const assetTypesOptions = [
    { value: 'crowd', label: 'Crowdfunding' },
    { value: 'virtual', label: 'Virtual' },
    { value: 'hybrid', label: 'Hybrid' },
    { value: 'multi', label: 'Multi' },
    { value: 'other', label: 'Other' },

];

export const basedInOptions = [
    { value: 'china', label: 'China' },
    { value: 'usa', label: 'USA' },
    { value: 'europe', label: 'Europe' },
    { value: 'other', label: 'Other' },
];

export const priceSqmOptions = [
    { value:"", label:"Select Price"},
    { value: 'range:0,200', label: '$0 - 200' },
    { value: 'range:200,500', label: '$200 - 500' },
    { value: 'range:500,1000', label: '$500 - 1000' },
    { value: 'range:1000,2000', label: '$1000 - 2000' },
    { value: 'range:2000,3000', label: '$2000 - 3000' },
    { value: 'range:3000,4000', label: '$3000 - 4000' },
    { value: 'range:4000,5000', label: '$4000 - 5000' },
    { value: 'gt:50000', label: '$5000+' },
];

export const irrPercentOptions = [
    { value: "", label:"Select IRR"},
    { value: 'range:0,7.5', label: '0 - 7.5%' },
    { value: 'range:7.5,12.5', label: '7.5 - 12.5%' },
    { value: 'range:12.5,20', label: '12.5 - 20%' },
    { value: 'gt:20', label: '20+%' },
];
export const cocPercentOptions = [
    { value:"", label: "Select COC"},
    { value: 'range:0,7.5', label: '0 - 7.5%' },
    { value: 'range:7.512.5', label: '7.5 - 12.5%' },
    { value: 'range:12.5,20', label: '12.5 - 20%' },
    { value: 'gt:20', label: '20+%' },
];
export const aprPercentOptions = [
    { value:"", label:"Select APR"},
    { value: 'range:0,7.5', label: '0 - 7.5%' },
    { value: 'range:7.5,12.5', label: '7.5 - 12.5%' },
    { value: 'range:12.5,20', label: '12.5 - 20%' },
    { value: 'gt:20', label: '20+%' },
];
export const propertyTypeOptions = [
    { value:"", label:"Select Property Type"},
    { value: 'in:Residential', label: 'Residential' },
    { value: 'in:Commercial', label: 'Commercial' },
    { value: 'in:MultiFamily', label: 'MultiFamily' },
    { value: 'in:Land', label: 'Land' },
    { value: 'in:Retail', label: 'Retail' },
    { value: 'in:Industrial', label: 'Industrial' },
    { value: 'in:Farm', label: 'Farm' }
];

export const platformSearchOptions = [
    { value:"", label:"Select Platform Search"},
    { value: 'in:Arrived', label: 'Arrived' },
    { value: 'in:Brickx', label: 'Brickx' },
    { value: 'in:Crowd-real-estate', label: 'Crowd-real-estate' },
    { value: 'in:Crowdstreet', label: 'Crowdstreet' },
    { value: 'in:Driftwoodcapital', label: 'Driftwoodcapital' },
    { value: 'in:Danasyariah', label: 'Danasyariah' },
    { value: 'in:Estateguru', label: 'Estateguru' },
    { value: 'in:Ethis', label: 'Ethis' },
    { value: 'in:Farmtogether', label: 'Farmtogether' },
    { value: 'in:Finloo', label: 'Finloo' },
    { value: 'in:Fundimmo', label: 'Fundimmo' },
    { value: 'in:Fund-that-flip', label: 'Fund-that-flip' },
    { value: 'in:Housers', label: 'Housers' },
    { value: 'in:Lande', label: 'Lande' },
    { value: 'in:Landex', label: 'Landex' },
    { value: 'in:Realt', label: 'Realt' },
    { value: 'in:Realty-mogul', label: 'Realty-mogul' },
    { value: 'in:Redswan', label: 'Redswan' },
    { value: 'in:Rendity', label: 'Rendity' },
    { value: 'in:Ruebushgroup', label: 'Ruebushgroup' },
    { value: 'in:Uown', label: 'Uown' },
    { value: 'in:Upstone', label: 'Upstone' },
    { value: 'in:Upvest', label: 'Upvest' },
    { value: 'in:Urbanitae', label: 'Urbanitae' },
    { value: 'in:Venturecrowd', label: 'Venturecrowd' }
]

export const priceOptions = [
    { value:"", label:"Select Price"},
    { value: 'range:0,200', label: '$0 - 200' },
    { value: 'range:200,500', label: '$200 - 500' },
    { value: 'range:500,1000', label: '$500 - 1000' },
    { value: 'range:1000,2000', label: '$1000 - 2000' },
    { value: 'range:2000,3000', label: '$2000 - 3000' },
    { value: 'range:3000,4000', label: '$3000 - 4000' },
    { value: 'range:4000,5000', label: '$4000 - 5000' },
    { value: 'gt:5000', label: '$5000+' }
]

export const trendingCitiesOptions = [
    { value: 'la', label: 'Los Angeles' },
    { value: 'delhi', label: 'Delhi' },
    { value: 'dubai', label: 'Dubai' },
    { value: 'london', label: 'London' },
    { value: 'capetown', label: 'Cape Town' }
];

export const countriesOptions = [
    { value:"", label:"Select Country"},
    { value: 'in:United States', label: 'United States' },
    { value: 'in:Germany', label: 'Germany' },
    { value: 'in:NetherLands', label: 'NetherLands' },
    { value: 'in:Australia', label: 'Australia' },
    { value: 'in:Ireland', label: 'Ireland' },
];

export const assetClassOptions = [
    { value: 'residential', label: 'Residential' },
    { value: 'office', label: 'Office' },
    { value: 'hotel', label: 'Hotel' },
    { value: 'farm', label: 'Farm' },
    { value: 'multifamily', label: 'Multifamily' },
    { value: 'industrial', label: 'Industrial' },
    { value: 'infrastructure', label: 'Infrastructure' },
];

export const virtualWorldOptions = [
    { value: 'decentraland', label: 'Decentraland' },
    { value: 'sandbox', label: 'The Sandbox' },
    { value: 'nft-worlds', label: 'NFT Worlds' },
    { value: 'somnium-space', label: 'Somnium Space' },
    { value: 'upland', label: 'Upland' },
    { value: 'superworld', label: 'Superworld' },
    { value: 'over', label: 'Over' },
];

export const blockchainOptions = [
    { value: 'ethereum', label: 'Ethereum' },
    { value: 'cardano', label: 'Cardano' },
    { value: 'algorand', label: 'Algorand' },
    { value: 'avalanche', label: 'Avalanche' },
    { value: 'solana', label: 'Solana' },
    { value: 'polkadot', label: 'Polkadot' },
    { value: 'polygon', label: 'Polygon' },
];

export const currencyOptions = [
    { value: 'ether', label: 'Ether' },
    { value: 'mana', label: 'Mana' },
    { value: 'sand', label: 'Sand' },
    { value: 'algo', label: 'Algorand' },
];

export const priceRangeOptions = [
    { value:"", label:"Select Price"},
    { value: 'range:0,5', label: '$0 - 5 M' },
    { value: 'range:5,10', label: '$5 - 10 M' },
    { value: 'range:10,100', label: '$10 - 100 M' },
    { value: 'gt:100', label: '$ 100 M' },
];

export const locationOptions = [
    { value: 'northusa', label: 'North America' },
    { value: 'southusa', label: 'South America' },
    { value: 'asia', label: 'Asia' },
    { value: 'middleeaset', label: 'Middle East' },
];

export const totalAreaOptions = [
    { value: 'range:0,200', label: '0-200' },
    { value: 'range:200,300', label: '200-300' },
    { value: 'range:300,500', label: '300-500' },
    { value: 'range:500', label: '500+' },
];

export const platformOptions = [
    { value: 'lofty', label: 'Lofty' },
    { value: 'realtymogul', label: 'RealtyMogul' },
    { value: 'propertyshare', label: 'Propertyshare' },
    { value: 'crowdstreet', label: 'Crowdstreet' },
    { value: 'upland', label: 'Upland' },
    { value: 'over', label: 'Over' },
]
