import axios from 'axios';

export const updateUser = (callback) => {
  axios({
    url: process.env.REACT_APP_BASE_API+'/get_user',
    method: "get",
    withCredentials:true,
    headers:{
      'Content-Type' : 'application/json'
    }
  }).then((res) => {
    sessionStorage.setItem('userData', JSON.stringify(res.data));
    callback(res.data);
  });
}

export const getUser = () => {
    let rval = {}
    let data = sessionStorage.getItem('userData');
    if (data) {
	data = JSON.parse(data);
        rval['email'] = data.email;
        rval['firstName'] = data.first_name;
        rval['lastName'] = data.last_name;
	rval['dids_available'] = data.dids_available;
	rval['is_platform_user'] = data.is_platform_user;
	rval['is_form_submitted'] = data.is_form_submitted;
	rval['platform_name'] = data.platform_name;
        rval['location'] = data.location;
    } else {
        rval = {};
    }
    return rval;
}

export const logOut = () => {
    sessionStorage.removeItem('userData');
    localStorage.clear();
    window.location = "/login";
}

export const isLoggedIn = () => {
    const user = getUser();
    if (user.email) {
        return true;
    }
    return false;
}

export default getUser;
