
export var AuthReducer = function (
    state = {
      isuserloggedin: sessionStorage.userToken ? true : false,
      user:[]
    },
    action
  ) {
    switch (action.type) {
      case "LOGIN_FETCH": {
        state = { ...state }
        state["isloading"] = true
        return state
      }
      case "LOGIN_SUCCESS": {
        state = { ...state }
        state["isuserloggedin"] = true
        state["user"] = action.payload
        state["isloading"] = false
        return state
      }
      case "LOGIN_FAILURE": {
        state = { ...state }
        state["isloading"] = false
        state["error"] = "Invalid Credentials"
        return state
      }
      default: return state
    }
}

export var CreateUserReducer = function (
    state = {
    },
    action
  ) {
    switch (action.type) {
      case "CREATE_USER_POST": {
        state = { ...state }
        state["isloading"] = true
        return state
      }
      case "CREATE_USER_SUCCESS": {
        state = { ...state }
        state["isloading"] = false
	state['success'] = true
        return state
      }
      case "CREATE_USER_FAILURE": {
        state = { ...state }
        state["isloading"] = false
        state["error"] = "Invalid Credentials"
        return state
      }
      default: return state
    }
}


export var ForgotPasswordReducer = function (
    state = {
    },
    action
  ) {
    switch (action.type) {
      case "FORGOT_PASSWORD_GET": {
        state = { ...state }
        state["isloading"] = true
        return state
      }
      case "FORGOT_PASSWORD_SUCCESS": {
        state = { ...state }
        state["isloading"] = false
	state['success'] = true
        return state
      }
      case "FORGOT_PASSWORD_FAILURE": {
        state = { ...state }
        state["isloading"] = false
        state["error"] = "Invalid Email"
        return state
      }
      default: return state
    }
}

export var ResetPasswordReducer = function (
    state = {
    },
    action
  ) {
    switch (action.type) {
      case "RESET_PASSWORD_POST": {
        state = { ...state }
        state["isloading"] = true
        return state
      }
      case "RESET_PASSWORD_SUCCESS": {
        state = { ...state }
        state["isloading"] = false
	state['success'] = true
        return state
      }
      case "RESET_PASSWORD_FAILURE": {
        state = { ...state }
        state["isloading"] = false
        state["error"] = "Invalid Email"
        return state
      }
      default: return state
    }
}




export var PhysicalAssetReducer = function (
  state={},
  action
) {
  switch (action.type) {
    case "PHYSICAL_ASSET_FETCH": {
      state = { ...state }
      state["isloading"] = true
      return state
    }
    case "PHYSICAL_ASSET_SUCCESS": {
      state = { ...state }
      state["assets"] = action.payload
      state["isloading"] = false
      
      return state
    }
    case "PHYSICAL_ASSET_FAILURE": {
      state = { ...state }
      state["isloading"] = false
      state["assets"] = ''
      state["error"] = "Error in Data Fetch"
      return state
    }
    default: return state
  }
}

export var VirtualAssetReducer = function (
  state={},
  action
) {
  switch (action.type) {
    case "VIRTUAL_ASSET_FETCH": {
      state = { ...state }
      state["isloading"] = true
      return state
    }
    case "VIRTUAL_ASSET_SUCCESS": {
      state = { ...state }
      state["assets"] = action.payload
      state["isloading"] = false
      
      return state
    }
    case "VIRTUAL_ASSET_FAILURE": {
      state = { ...state }
      state["isloading"] = false
      state["assets"] = ''
      state["error"] = "Error in Data Fetch"
      return state
    }
    default: return state
  }
}

export var MarketPlaceReducer = function (
  state={},
  action
) {
  switch (action.type) {
    case "MARKET_PLACE_FETCH": {
      state = { ...state }
      state["isloading"] = true
      return state
    }
    case "MARKET_PLACE_SUCCESS": {
      state = { ...state }
      state["marketplace"] = action.payload
      state["isloading"] = false
      
      return state
    }
    case "MARKET_PLACE_FAILURE": {
      state = { ...state }
      state["isloading"] = false
      state["error"] = "Error in Market Data Fetch"
      return state
    }
    default: return state
  }
}

export var DashboardReducer = function (
  state={dashboard:''},
  action
) {
  switch (action.type) {
    case "DASHBOARD_FETCH": {
      state = { ...state }
      state["isloading"] = true
      return state
    }
    case "DASHBOARD_SUCCESS": {
      state = { ...state }
      state["dashboard"] = action.payload
      state["isloading"] = false
      return state
    }
    case "DASHBOARD_FAILURE": {
      state = { ...state }
      state["isloading"] = false
      state["dashboard"] = '';
      state["error"] = "Error in Dashboard Data Fetch"
      return state
    }
    default: return state
  }
}

export var SearchReducer = function (
  state={search:''},
  action
) {
  switch (action.type) {
    case "SEARCH_FETCH": {
      state = { ...state }
      state["isloading"] = true
      return state
    }
    case "SEARCH_SUCCESS": {
      state = { ...state }
      state["search"] = action.payload
      state["isloading"] = false
      return state
    }
    case "SEARCH_FAILURE": {
      state = { ...state }
      state["isloading"] = false
      state["error"] = "Error in Search Data Fetch"
      return state
    }
    default: return state
  }
}

export var AdminTableThunkReducer = function (
  state={data:''},  
  action
) {
  switch (action.type) {
    case "TABLE_FETCH": {
      state = { ...state }
      state["isloading"] = true
      return state
    }
    case "TABLE_SUCCESS": {
      state = { ...state }
      state["data"] = action.payload
      state["isloading"] = false
      
      return state
    }
    case "TABLE_FAILURE": {
      state = { ...state }
      state["isloading"] = false
      state["error"] = "Error in Search Data Fetch"
      return state
    }
    default: return state
  }
}

export var AdminTableUpdateThunkReducer = function (
  state={data:''},
  action
) {
  switch (action.type) {
    case "ADMIN_TABLE_UPDATE_FETCH": {
      state = { ...state }
      state["isloading"] = true
      return state
    }
    case "ADMIN_TABLE_UPDATE_SUCCESS": {
      state = { ...state }
      state["search"] = action.payload
      state["isloading"] = false
      
      return state
    }
    case "ADMIN_TABLE_UPDATE_FAILURE": {
      state = { ...state }
      state["isloading"] = false
      state["error"] = "Error in Search Data Fetch"
      return state
    }
    default: return state
  }
}

export var AddAssetReducer = function (
  state={},
  action
) {
  switch (action.type) {
    case "ADD_ASSET_FETCH": {
      state = { ...state }
      state["isloading"] = true
      return state
    }
    case "ADD_ASSET_SUCCESS": {
      state = { ...state }
      state["addasset"] = action.payload
      state["isloading"] = false
      
      return state
    }
    case "ADD_ASSET_FAILURE": {
      state = { ...state }
      state["isloading"] = false
      state["error"] = "Error in Add Asset"
      return state
    }
    default: return state
  }
}


export var AddTimestampReducer = function (
  state={},
  action
) {
  switch (action.type) {
    case "ADD_TIMESTAMP_FETCH": {
      state = { ...state }
      state["isloading"] = true
      return state
    }
    case "ADD_TIMESTAMP_SUCCESS": {
      state = { ...state }
      state["addtimestamp"] = action.payload
      state["isloading"] = false
      
      return state
    }
    case "ADD_TIMESTAMP_FAILURE": {
      state = { ...state }
      state["isloading"] = false
      state["error"] = "Error in Add Timestamp"
      return state
    }
    default: return state
  }
}


export var GetTimestampReducer = function (
  state = {},
  action
) {
    switch (action.type) {
      case "GET_TIMESTAMPS_FETCH": {
        state = { ...state};
	state['isloading'] = true;
	return state;
      }
      case "GET_TIMESTAMPS_SUCCESS": {
        state = { ...state};
	state['isloading'] = false;
	state['gettimestamp'] = action.payload;
	return state;
      }
      case "GET_TIMESTAMPS_FAILURE": {
        state = { ...state};
	state['isloading'] = false;
	state['error'] = "Error in Get Timestamps";
	return state
      }
      default: return state;
    }
}

export var AppendTimestampReducer = function (
  state={},
  action
) {
  switch (action.type) {
    case "APPEND_TIMESTAMP_FETCH": {
      state = { ...state }
      state["isloading"] = true
      return state
    }
    case "APPEND_TIMESTAMP_SUCCESS": {
      state = { ...state }
      state["appendtimestamp"] = action.payload
      state["isloading"] = false
      return state
    }
    case "APPEND_TIMESTAMP_FAILURE": {
      state = { ...state }
      state["isloading"] = false
      state["error"] = "Error in Append Timestamp"
      return state
    }
    default: return state
  }
}



