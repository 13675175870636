import axios from 'axios';
import React,{useEffect,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Scrollspy from 'react-scrollspy'
import { AdminTableThunk, AdminTableUpdate } from '../Reduxstore/thunks';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const initialstate= [
      {
          "_id": {
              "$oid": "63bdddf161beb3188679a6fb"
          },
          "email": "barry+3@land32.com",
          "dids_available": 3,
          "first_name": "bari",
          "is_platform_user": true,
          "last_name": "row",
          "nonce": "0.805479723627615",
          "password_hash": "4b036e2378cded795ae8f649cc76b62efbcf83130f05d95368c0fa034519734a",
          "verified": true,
          "assets": {
              "land_link": {
                  "Lofty": {
                      "property_deal/3834-W-Polk-St_Chicago-IL-60624": {
                          "currency": "USD",
                          "amount": 2.0,
                          "price": 46.49,
                          "date": "2023-01-09",
                          "url": "https://www.lofty.ai/property_deal/3834-W-Polk-St_Chicago-IL-60624",
                          "image": "https://images.lofty.ai/images/01FPKD7BNFR2D3H1CFTDNZMATT/001.webp",
                          "name": "3834 W Polk St",
                          "est_price": 46.49
                      }
                  }
              }
          },
          "is_form_submitted": true
      },
      {
          "_id": {
              "$oid": "63bf99a161beb31886ac22f4"
          },
          "email": "test_123@gmail.com",
          "dids_available": 3,
          "first_name": "Neeraj",
          "is_platform_user": true,
          "last_name": "dighade",
          "nonce": "0.14990219438849606",
          "password_hash": "93718d14481f26a235bbeb5405ed278c4765a52f48ba03cd71ae516a9a4350c3",
          "verified": false
      },
      {
          "_id": {
              "$oid": "63bff56f61beb3188694c013"
          },
          "email": "diminor462@webonoid.com",
          "dids_available": 3,
          "first_name": "diminor",
          "is_platform_user": true,
          "last_name": "diminor",
          "nonce": "0.056186512937413724",
          "password_hash": "1a1d28203731110775c395e4eace3e58f6d63b9d1e09b4a9f8daa6976d62fefc",
          "verified": true
      },
      {
          "_id": {
              "$oid": "63c5f30c61beb318868143c2"
          },
          "email": "barry+5@land32.com",
          "dids_available": 3,
          "first_name": "Barry",
          "is_platform_user": true,
          "last_name": "Rowe",
          "nonce": "0.5931389693394786",
          "password_hash": "9d21a9a9d0bba28b138f9914d52ab45d83888f9b4018ee0bc96715d5db5bc973",
          "verified": true,
          "is_form_submitted": true
      },
      {
          "_id": {
              "$oid": "63c95a8e61beb31886cb0ec6"
          },
          "email": "test123@yopmail.com",
          "dids_available": 3,
          "first_name": "test",
          "is_platform_user": true,
          "last_name": "test",
          "nonce": "0.15094972193046186",
          "password_hash": "ccddad4dad2941f5c45075cc4641be18338d2863b543f0fd2a2ee6cb55c6f9f8",
          "verified": true
      },
      {
          "_id": {
              "$oid": "63d17bc761beb31886b7133c"
          },
          "email": "barry+new1@land32.com",
          "dids_available": 3,
          "first_name": "Barr",
          "is_platform_user": true,
          "last_name": "https://ztranslate.net",
          "nonce": "0.12258510868126216",
          "password_hash": "15fb4e9e6f4b30d495771d552d9d44d6e88bb004e0533b5a35a0dd121cfcf8ed",
          "verified": true,
          "is_form_submitted": true
      },
      {
          "_id": {
              "$oid": "63e343d861beb31886b736c6"
          },
          "email": "riquautrijopu-6671@yopmail.com",
          "dids_available": 3,
          "first_name": "demo",
          "is_platform_user": true,
          "last_name": "demo",
          "nonce": "0.9581118982460741",
          "password_hash": "2b155995af2e28643d3879d3662b5c0e4285d95034bfc143bd0b9e6fb9dcba2b",
          "verified": true
      },
      {
          "_id": {
              "$oid": "640607f1a645b69053404b1b"
          },
          "email": "neerajdighade.neosoft@gmail.com",
          "dids_available": 3,
          "first_name": "test_company",
          "is_platform_user": true,
          "last_name": "tst@123.com",
          "nonce": "0.3170091684965525",
          "password_hash": "8acdbe09d52354c45bbf04a8e86f3b48464a0877189f510edade7f4c066bee5f",
          "verified": true,
          "is_form_submitted": true
      },
      {
          "_id": {
              "$oid": "64083ef6a645b690534bae6e"
          },
          "email": "jecrajezacrei-1969@yopmail.com",
          "dids_available": 3,
          "first_name": "temp",
          "is_platform_user": true,
          "last_name": "temp.com",
          "nonce": "0.8800594924975315",
          "password_hash": "6b0e494af7bd81fb7d464f32035eb34314f61d28d250e6da96aa47556c63e74e",
          "verified": true,
          "is_form_submitted": true,
          "platform_name": null
      },
      {
          "_id": {
              "$oid": "640843f6a645b690534bc84a"
          },
          "email": "farmtogether@yopmail.com",
          "dids_available": 3,
          "first_name": "farmtogether",
          "is_platform_user": true,
          "last_name": "farmtogether.com",
          "nonce": "0.12365066065851293",
          "password_hash": "ce984397420a06b370272372ec00cc023e74dd32c4b59f8fe1efac5709bda020",
          "verified": true,
          "is_form_submitted": true
      },
      {
          "_id": {
              "$oid": "641c72a1a645b69053b3d428"
          },
          "email": "test99@yopmail.com",
          "dids_available": 3,
          "first_name": "test99",
          "is_platform_user": true,
          "last_name": "abc.com",
          "nonce": "0.9353077254858986",
          "password_hash": "968a1e77e5d8b52dafa2104f6efa039b61a7a1e506a6d5d329fcc51ade972d01",
          "platform_name": "admin",
          "verified": true
      },
      {
          "_id": {
              "$oid": "6421585aa645b69053cd052d"
          },
          "email": "daniel+2@araa.land",
          "dids_available": 3,
          "first_name": "DanTokenizer2",
          "is_platform_user": true,
          "last_name": "Crowd",
          "nonce": "0.13231432259365372",
          "password_hash": "df7b3a28ecfb78f4d7d47c7ebc66066d27d827b4449ffa74ebb575987a9de107",
          "platform_name": "admin",
          "verified": true
      },
      {
          "_id": {
              "$oid": "642ee129a645b6905313c671"
          },
          "email": "barry+platform1@todalarity.com",
          "dids_available": 3,
          "first_name": "plat",
          "is_platform_user": true,
          "last_name": "plat.com",
          "nonce": "0.4805067582297181",
          "password_hash": "59487cc7be22c31d2870f5446889f4d18ff3e05c6d42a474eb39f1264fc30bb4",
          "platform_name": "admin",
          "verified": true
      },
      {
          "_id": {
              "$oid": "642fa501a645b6905317b3a4"
          },
          "email": "barry+platform2@todalarity.com",
          "dids_available": 3,
          "first_name": "barry+platform2@todalarity.com",
          "is_platform_user": true,
          "last_name": "barry+platform2@todalarity.com",
          "nonce": "0.9436374852107082",
          "password_hash": "e27b2291e4c9de2d17e224956a32f298583e6f60974ad8053a8f753898b519bc",
          "platform_name": "admin",
          "verified": true
      },
      {
          "_id": {
              "$oid": "64303e79a645b690531ae6dc"
          },
          "email": "test33@yopmail.com",
          "dids_available": 3,
          "first_name": "avvron",
          "is_platform_user": true,
          "last_name": "avvron@gamil.com",
          "nonce": "0.8411013266351061",
          "password_hash": "65fb016f3b1cbb0eb4142ddcce9ee47f0f2219c18a8fae08a96937a324a7cf88",
          "platform_name": "",
          "verified": true
      }
  ]

export default function AdminTable() {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const { isloading, data, error } = useSelector(state => state.adminTable);
    const [tableContent,setTableContent]=useState([]);

    useEffect(() => {
        dispatch(AdminTableThunk())
    }, [])

    useEffect(()=>{
      if(!isloading){
        if(data.results){
          setTableContent([...data.results])
        }
      }
    },[isloading])
    
    const handleUpdate=(item)=>{
        dispatch(AdminTableUpdate({
            user_id:item?._id?.$oid,
            collection:item.collection
        }))
    }

    const handleInputBoxChange=(index,value)=>{
        const copylist=JSON.parse(JSON.stringify(tableContent))
        copylist[index].collection=value;
        setTableContent([...copylist]);
    }
    
  return (
    <div className="main-content browse">
        <div className="main-content__left ">
        <Scrollspy items={['table']} className="list-unstyled sidebar" currentClassName={'active'}>
            <li className='bg-primary'><Link className="d-flex align-items-center lh-2 text-decoration-none text-white" to='/admin-formupdate'><span className="d-flex align-items-center me-2"><i className="fa-solid fa-bars"></i></span> <span className="d-block">Form</span></Link></li>
        </Scrollspy>
        </div>
        <div className="main-content__right m-0 p-0">
        <div className="container-fluid" style={{ marginTop: 30 /*,height:'379px'*/}}>
        <div className='my-2'><button className='btn' onClick={()=>navigate(-1)}><ArrowBackIosIcon/></button></div>
            <div className="table-wrapper table-head-colored" style={{
            padding: '0px',
            margin: '0',
            backgroundColor: 'rgba(222, 223, 226, 0.35)',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            border: '1px solid #DEDFE2',
            borderBottom: 'none'}}>
                <table className="table table-responsive table-borderless table-hover" style={{backgroundColor:'rgb(255,255,255,1)'}}>
                    <thead>
                        <tr className="text-center">
                            <th style={{position:"sticky",left:'0px'}}>Email</th>
                            <th>Platform Name</th>
                            <th>Form Submitted</th>
                            <th>Collection</th>
                            <th>New Collection</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        { tableContent.map((item,index)=>(
                            <tr key={"tableContent"+index} className='text-center' style={{verticalAlign:'middle'}}>
                                <td>{item.email}</td>
                                <td>{item.platform_name}</td>
                                <td>{String(item.is_form_submitted)}</td>
                                <td>{item.collection}</td>
                                <td><input value={item.collection} onChange={(e)=>handleInputBoxChange(index,e.target.value)}/></td>
                                <td><button className="btn btn-primary" onClick={()=>handleUpdate(item)}>Update</button></td>
                            </tr>
                        ))}
                        {tableContent.length === 0 &&(<tr><td>No Records Found</td></tr>)}
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    </div>
  )
}
