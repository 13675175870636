import { useTable,useSortBy } from 'react-table'
import { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router';


const CustomTable = (props)=>{
    const navigate=useNavigate()
    const {columns,myPlot,sortData,rowProps, ...restProps} = props.data;
    const isThereData = ()=>{
        if(myPlot!==undefined){
          return myPlot
        }
        else{
          return []
        }
    }
    const data = isThereData();
    const tableInstance = useTable({ columns, data,disableSortBy:!sortData,...restProps},useMemo(()=>useSortBy,[]))

    const {getTableProps, getTableBodyProps,headerGroups,rows,prepareRow} = tableInstance
    return(
        <table {...getTableProps()} className='table table-borderless'>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                    <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={props.style}
                    >
                    {column.render('Header')}
                        { sortData && <span className='sort-wrapper'>
                            {column.isSorted
                            ? column.isSortedDesc
                                ? <i class="fa-solid fa-caret-down"></i>
                                : <i class="fa-solid fa-caret-up"></i>
                            : <span className='no-sort'>
                                <i class="fa-solid fa-caret-up"></i>
                                <i class="fa-solid fa-caret-down"></i>
                            </span>}
                        </span>}
                    </th>
                ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row)
		 
                    return (
                        <tr {...row.getRowProps( rowProps  ? rowProps(row): undefined )} className="cursor-pointer">
                            {row.cells.map(cell => {
                            return (
                                <td
                                {...cell.getCellProps()}
                                >
                                {cell.render('Cell')}
                                </td>
                            )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default CustomTable;
