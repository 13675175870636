// import ETH from '../images/icons/currency/ether.svg';
import ADA from '../images/icons/currency/ada.svg';
import AED from '../images/icons/currency/aed.svg';
import ALGO from '../images/icons/currency/algo.svg';
import EUR from '../images/icons/currency/eur.svg';
// import INR from '../images/icons/currency/inr.svg';
import MANA from '../images/icons/currency/mana.svg';
import SAND from '../images/icons/currency/sand.svg';
// import USD from '../images/icons/currency/usd.svg';

const Currency = (props) => {
    const { name, style } = props;
    return (
        <>
            {name === "USD" && <i className="fa-solid fa-dollar-sign"></i>}
            {name === "ETH" || name === null ? <i className="fa-brands fa-ethereum" ></i> : null
            }
            {name === "WETH" ? <i className="fa-brands fa-ethereum" style={{ color: '#f00' }}></i> : null}
            {name === "ADA" && <span className='d-inline-flex align-items-center' style={{ width: '20px', height: '20px', ...style }}>  <img src={ADA} alt={name} loading='lazy' className='w-100 h-100' /> </span>}
            {name === "AED" && <span className='d-inline-flex align-items-center' style={{ width: '20px', height: '20px', ...style }}>  <img src={AED} alt={name} loading='lazy' className='w-100 h-100' /> </span>}
            {name === "ALGO" && <span className='d-inline-flex align-items-center' style={{ width: '20px', height: '20px', ...style }}>  <img src={ALGO} alt={name} loading='lazy' className='w-100 h-100' /> </span>}
            {name === "EUR" && <span className='d-inline-flex align-items-center' style={{ width: '20px', height: '20px', ...style }}>  <img src={EUR} alt={name} loading='lazy' className='w-100 h-100' /> </span>}
            {name === "INR" && <i className="fa-solid fa-indian-rupee-sign"></i>}
            {name === "MANA" && <span className='d-inline-flex align-items-center' style={{ width: '20px', height: '20px', ...style }}>  <img src={MANA} alt={name} loading='lazy' className='w-100 h-100' /></span>}
            {name === "SAND" && <span className='d-inline-flex align-items-center' style={{ width: '20px', height: '20px', ...style }}>  <img src={SAND} alt={name} loading='lazy' className='w-100 h-100' /> </span>}

        </>
    )
}

export default Currency;