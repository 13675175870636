import NoDataImage from '../images/no-data-primary.png';
import NoMatchFoundImage from '../images/no-match-primary.png';

const NoDataFound = (props) =>{
    return(
        <div className='mx-auto' style={props.style}>
            <img src={ props.nomatch ? NoMatchFoundImage : NoDataImage} alt={ props.nomatch ? 'No Match Found' :"No Data Found"} className='img-fluid' loading='lazy'/>
        </div>
    )
}

export default NoDataFound;