/*
Jurisdiction:
ID Number:
Owner
Files:
*/
const appendTimestampFormInputs = [{
    label:'Description',
    id:'description-name',
    type:'text',
    placeholder:'Enter Description',
    value:'data_description'
},
{
    label:'Data Link',
    id:'data-link',
    type:'text',
    placeholder:'Enter Link',
    value:'data_link'
},
]

export default appendTimestampFormInputs;
