import { useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AssetFetchThunk, PhysicalTransactionsThunk } from '../Reduxstore/thunks';
import NoDataFound from './NoDataFound';

const PhysicalAssetItem = (props) => {
    const { name, thumbnail, target_irr, collection, url, image } = props.data
    return (
        <tr>
            <td colSpan={2}>
                <a href={url} className='text-decoration-none text-dark' target={'_blank'}>
                    <div className="d-flex">
                        <div className="icon-wrapper">
                            <div className="image-wrapper">
                                {image ?
                                    <img src={image} alt="icon" className='img-fluid' loading='lazy' />
                                    :
                                    <img src={thumbnail} alt="icon" className='img-fluid' loading='lazy' />
                                }
                            </div>
                        </div>
                        <div className="d-block ms-3 w-100">
                            <p className="asset-name mb-0">{name}</p>
                            <div className="d-flex flex-wrap justify-content-between">
                                <p className="mb-0 asset-subname text-capitalize">{collection}</p>
                                <div className="d-flex align-items-center">
                                    {target_irr ?
                                        <p className="mb-0 text-right ms-1">  {target_irr}%</p>
                                        :
                                        <p className="mb-0 text-right ms-1">—</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </td>
        </tr>
    )
}

const PhysicalAssetsTransactions = () => {
    const dispatch = useDispatch();
    let { assets, isloading, error } = useSelector(state => state.physicalAsset);
    useEffect(() => {
        dispatch(PhysicalTransactionsThunk('limit=20'));
    }, [])
    if (assets)
        assets = assets['merged'];
    return (
        <>
            {error !== undefined && assets == '' && error && !isloading ? (
                <NoDataFound style={{ maxWidth: '250px' }}></NoDataFound>
            )
                : (
                    <div className="table-wrapper">
                        <table className=" table table-responsive table-borderless table-hover">
                            <thead>
                                <tr>
                                    <th >Name</th>
                                    <th className='text-end'>IRR/APR</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isloading &&
                                    <tr>
                                        <td colSpan={2}>
                                            <div className="loader" style={{ position: 'relative', inset: '0', transform: 'none' }}><ThreeDots color="#8FF129"></ThreeDots></div>
                                        </td>
                                    </tr>
                                }

                                {assets && assets !== undefined && !isloading && assets.map((assetItem, index) => {
                                    return <PhysicalAssetItem key={index} data={assetItem}></PhysicalAssetItem>
                                })}


                            </tbody>
                        </table>
                    </div>
                )
            }
        </>

    );
}

export default PhysicalAssetsTransactions;
