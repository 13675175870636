import Scrollspy from 'react-scrollspy';
import Select from 'react-select';
import {useDispatch, useSelector} from "react-redux";
// import listingIcon from '../images/icons/browse/listing.svg';
// import myStoreIcon from '../images/icons/browse/store.svg';

// import landIcon from '../images/icons/browse/land-logo.svg'
// import landImage from '../images/browse/virtual-land-image.png';

import MetaverseTable from './MetaverseTable';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useEffect } from 'react';
import { VirtualTransactionsThunk } from '../Reduxstore/thunks';
import { ThreeDots } from 'react-loader-spinner';
import NoDataFound from './NoDataFound';
import WorkInProgress from './WorkInProgress';
// import Currency from './Currency';
import { useState } from 'react';
import {blockchainOptions, currencyOptions, virtualWorldOptions } from '../CustomFunctions/filterOptions';
import VirtualLand from './Cards/VirtualLand';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const VirtualAssets = () =>{
    const navigate=useNavigate();
    const [values,setValues] = useState({});


    const virtualLandOptions  = [
        { value: 'filter-item-1', label: 'Filter' },
        { value: 'filter-item-2', label: 'Filter Item 2' },
        { value: 'filter-item-3', label: 'Filter Item 3' },
        { value: 'filter-item-4', label: 'Filter Item 4' },
    ];
    const CrowdFundingOptions  = [
        { value: 'filter-item-1', label: 'Filter' },
        { value: 'filter-item-2', label: 'Filter Item 2' },
        { value: 'filter-item-3', label: 'Filter Item 3' },
        { value: 'filter-item-4', label: 'Filter Item 4' },
    ];

    const metaverseData = [{
        index:1,
        assetName:'LAND (..107)',
        assetMarket:'The Sandbox',
        receivedFromName:'CalmPike',
        receivedFromToken:'0x03c6..5454',
        sentToName:'Tim361582',
        sentToToken:'0xb8bc..33a3',
        popularityIndex:1,
        popularityMarket:'The Sandbox',
        tokenIcon:'Icon',
        tokenQuantity:'1.30',
        tokenValue:'$3,060.95',
    },
    { index:2,
        assetName:'LAND (..107)',
        assetMarket:'Decentraland',
        receivedFromName:'CalmPike',
        receivedFromToken:'0x03c6..5454',
        sentToName:'Tim361582',
        sentToToken:'0xb8bc..33a3',
        popularityIndex:1,
        popularityMarket:'Decentraland',
        tokenIcon:'Icon',
        tokenQuantity:'1.30',
        tokenValue:'$3,060.95',
    },
    { index:3,
        assetName:'LAND (..107)',
        assetMarket:'Decentraland',
        receivedFromName:'CalmPike',
        receivedFromToken:'0x03c6..5454',
        sentToName:'Tim361582',
        sentToToken:'0xb8bc..33a3',
        popularityIndex:1,
        popularityMarket:'Decentraland',
        tokenIcon:'Icon',
        tokenQuantity:'1.30',
        tokenValue:'$3,060.95',
    },
    { index:4,
        assetName:'LAND (..107)',
        assetMarket:'Decentraland',
        receivedFromName:'CalmPike',
        receivedFromToken:'0x03c6..5454',
        sentToName:'Tim361582',
        sentToToken:'0xb8bc..33a3',
        popularityIndex:1,
        popularityMarket:'Decentraland',
        tokenIcon:'Icon',
        tokenQuantity:'1.30',
        tokenValue:'$3,060.95',
    },
    { index:5,
        assetName:'LAND (..107)',
        assetMarket:'Decentraland',
        receivedFromName:'CalmPike',
        receivedFromToken:'0x03c6..5454',
        sentToName:'Tim361582',
        sentToToken:'0xb8bc..33a3',
        popularityIndex:1,
        popularityMarket:'Decentraland',
        tokenIcon:'Icon',
        tokenQuantity:'1.30',
        tokenValue:'$3,060.95',
    },
    { index:6,
        assetName:'LAND (..107)',
        assetMarket:'Decentraland',
        receivedFromName:'CalmPike',
        receivedFromToken:'0x03c6..5454',
        sentToName:'Tim361582',
        sentToToken:'0xb8bc..33a3',
        popularityIndex:1,
        popularityMarket:'Decentraland',
        tokenIcon:'Icon',
        tokenQuantity:'1.30',
        tokenValue:'$3,060.95',
    },
    { index:7,
        assetName:'LAND (..107)',
        assetMarket:'Decentraland',
        receivedFromName:'CalmPike',
        receivedFromToken:'0x03c6..5454',
        sentToName:'Tim361582',
        sentToToken:'0xb8bc..33a3',
        popularityIndex:1,
        popularityMarket:'Decentraland',
        tokenIcon:'Icon',
        tokenQuantity:'1.30',
        tokenValue:'$3,060.95',
    },
    { index:8,
        assetName:'LAND (..107)',
        assetMarket:'Decentraland',
        receivedFromName:'CalmPike',
        receivedFromToken:'0x03c6..5454',
        sentToName:'Tim361582',
        sentToToken:'0xb8bc..33a3',
        popularityIndex:1,
        popularityMarket:'Decentraland',
        tokenIcon:'Icon',
        tokenQuantity:'1.30',
        tokenValue:'$3,060.95',
    },
    { index:9,
        assetName:'LAND (..107)',
        assetMarket:'Decentraland',
        receivedFromName:'CalmPike',
        receivedFromToken:'0x03c6..5454',
        sentToName:'Tim361582',
        sentToToken:'0xb8bc..33a3',
        popularityIndex:1,
        popularityMarket:'Decentraland',
        tokenIcon:'Icon',
        tokenQuantity:'1.30',
        tokenValue:'$3,060.95',
    },
    { index:10,
        assetName:'LAND (..107)',
        assetMarket:'Decentraland',
        receivedFromName:'CalmPike',
        receivedFromToken:'0x03c6..5454',
        sentToName:'Tim361582',
        sentToToken:'0xb8bc..33a3',
        popularityIndex:1,
        popularityMarket:'Decentraland',
        tokenIcon:'Icon',
        tokenQuantity:'1.30',
        tokenValue:'$3,060.95',
    },
    {   index:11,
        assetName:'LAND (..107)',
        assetMarket:'Decentraland',
        receivedFromName:'CalmPike',
        receivedFromToken:'0x03c6..5454',
        sentToName:'Tim361582',
        sentToToken:'0xb8bc..33a3',
        popularityIndex:1,
        popularityMarket:'Decentraland',
        tokenIcon:'Icon',
        tokenQuantity:'1.30',
        tokenValue:'$3,060.95',
    },
    
    ]

    const metaVerseOptions  = [
        { value: 'filter-item-1', label: 'Price-low to high' },
        { value: 'filter-item-2', label: 'Filter Item 2' },
        { value: 'filter-item-3', label: 'Filter Item 3' },
        { value: 'filter-item-4', label: 'Filter Item 4' },
    ];

    const handleChange = key => event => {
        setValues({...values, [key] : event.target.value})
    }
    const dispatch = useDispatch();
    const {assets,isloading,error} = useSelector(state => state.virtualAsset);
    // console.log("Virtual Asstes=====>>",assets);

    const getVirtualAssets = () =>{
        var str = Object.keys(values).filter(x => values[x].length>1).map(key => `${key}=${values[key]}`).join("&");
        // dispatch(SearchThunk(`query=${query}&${str}`));
        // console.log("FINAL Q STRING ====>>>",str)
        dispatch(VirtualTransactionsThunk(str));
    }

    useEffect(()=>{
        getVirtualAssets();
    },[values])
    return(
        <>
        <Helmet>
        <title>Buy virtual assests from Arra Land</title>
        <meta name="description" content="Find Any global tokenization assets from one convenient place" />
        <link rel="canonical" href="https://app.arra.land/virtual-assets" />
        </Helmet>
        <div className="main-content virtual-assets">
            <div className="main-content__left ">
            <Scrollspy items={ ['listing','store'] } className="list-unstyled sidebar" currentClassName={'active'}>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#listing"> <span className="d-flex align-items-center me-2"><i className="fa-solid fa-bars"></i></span> <span className="d-block">Listings</span></a></li>

                    {/* <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#store" > <span className="d-flex align-items-center me-2"><img src={myStoreIcon} alt="icon" loading='lazy'/></span> <span className="d-block">My Store</span></a></li> */}

                </Scrollspy>
            </div>
            <div className="main-content__right m-0 p-0">
            {/* <div className="container-fluid" style={{marginTop:30}}>
                    <button className='btn btn-primary p-2' style={{display:'flex',alignItems:'center'}}onClick={()=>navigate(-1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M20 15h-8v3.586a1 1 0 0 1 -1.707 .707l-6.586 -6.586a1 1 0 0 1 0 -1.414l6.586 -6.586a1 1 0 0 1 1.707 .707v3.586h8a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1z"></path>
</svg>
                  &nbsp; Back
                    </button>
                </div> */}
                <div className="container-fluid">
                    <div className="row mt-0 mt-lg-5" id='listing'>
                        <div className="col-12">
                                <ul className="nav nav-tabs" id="browseTab" role="tablist">
                                <li><button className='btn' onClick={()=>navigate(-1)}><ArrowBackIosIcon/></button></li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="all" aria-selected="true"><h1 className='seo-optimization'>All</h1></button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="virtual-estate-tab" data-bs-toggle="tab" data-bs-target="#virtual-estate" type="button" role="tab" aria-controls="virtual-estate" aria-selected="false"><h1 className='seo-optimization'>Virtual Real Estate</h1></button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="metaverse-tab" data-bs-toggle="tab" data-bs-target="#metaverse" type="button" role="tab" aria-controls="metaverse" aria-selected="false"><h1 className='seo-optimization'>Metaverse</h1></button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="web3-tab" data-bs-toggle="tab" data-bs-target="#web3" type="button" role="tab" aria-controls="web3" aria-selected="false"><h1 className='seo-optimization'>Web3</h1></button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="games-tab" data-bs-toggle="tab" data-bs-target="#games" type="button" role="tab" aria-controls="games" aria-selected="false"><h1 className='seo-optimization'>Games</h1></button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="worlds-tab" data-bs-toggle="tab" data-bs-target="#worlds" type="button" role="tab" aria-controls="worlds" aria-selected="false"><h1 className='seo-optimization'>Worlds</h1></button>
                                    </li>
                                </ul>
	                        <div style={{"paddingLeft": "25px", "paddingRight": "25px", "paddingTop": "25px", "marginBottom":"-15px", "fontSize": "16px"}}>
Browse all web3 and metaverse virtual real estate from all around the web.
                                </div>

                                <div className="tab-content" id="browseTabContent">
                                    <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                                        <h2 className="accordion-header" id="headingSix">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                        Worlds
                                            </button>
                                        </h2>
                                        <div id="collapseSix" className="collapse show" aria-labelledby="headingSix">
                                            <div className="accordion-body">
                                                <div className="container-fluid g-0">
                                                    <div className="row mt-5">
                                                    
                                                    {assets!==undefined && !isloading && assets.map((assetItem,index)=>{
                                                        return <div className="col-md-6 col-lg-4">
                                                                <VirtualLand key={index} data={assetItem}/>
                                                        </div> 
                                                    })}

                                                    { isloading || error ?  
                                                        <div className="full-page">
                                                            { isloading ? (
                                                            <div className="loader" style={{position:'relative',inset:'0',transform:'none'}}>
                                                                <ThreeDots color="#8FF129">
                                                                </ThreeDots>
                                                            </div>) : <NoDataFound style={{maxWidth:'400px'}}></NoDataFound> }
                                                        </div> 
                                                    : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>    
                                    </div>
                                    <div className="tab-pane fade " id="virtual-estate" role="tabpanel" aria-labelledby="virtual-estate-tab">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            Virtual Real Estate
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne">
                                            <div className="accordion-body">
                                                <div className="container-fluid g-0">
                                                    <form action="">
                                                        <div className="row mt-2 mb-4">
                                                            <div className="col-12 col-xl-8">
                                                                <div className="input-wrapper">
                                                                    <input type="text" className="form-control" id="leaderboard-search" placeholder="Search any real estate digital: crowdfunded/tokenized/virtual NFT, ranked by worlds, communities, lands, and owners." onInput={((e)=>setValues({...values,query:e.target.value}))}/>
                                                                </div>
                                                            </div>
	    {/*
                                                            <div className="col-12 mt-2 mt-xl-0 col-xl-4 d-flex align-items-center flex-wrap  justify-content-xl-between">
                                                                <Select placeholder={'RECENTLY LISTED'}  options={virtualLandOptions} className="react-select-style-2" classNamePrefix={'react-select'} components={{IndicatorSeparator: () => null }} onChange={((e)=>console.log(e.value))} />

                                                                <div className="custom-toggler">
                                                                    <label className="switch">
                                                                        <input type="checkbox"/>
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                    <p className='m-0'> ON SALE</p>
                                                                </div>
                                                            </div>
               */}                                             
                                                        </div>
                                                        <div className="mt-3 p-4 filter-menu ">
                                                            <div className='filter-menu__item'>
                                                                <p className='m-0 text-uppercase mb-2'>Worlds</p>
                                                                <Select placeholder={'Select World'}  options={virtualWorldOptions} classNamePrefix={'react-select'} components={{IndicatorSeparator: () => null }} onChange={((e)=>setValues({...values,collection:e.value}))}/>
                                                            </div>
                                                            <div className='filter-menu__item'>
                                                                <p className='m-0 text-uppercase mb-2'>Price Range <span className='text-capitalize'>( Million )</span></p>
                                                                <div className="py-2 px-3 d-flex align-items-center border">
                                                                    <p className='m-0 bg-light-custom px-2'>{values.price || 20}</p>
                                                                    <div className="ms-3 d-flex">
                                                                        <span>0</span>
                                                                        <div className="custom-slider mx-2">
                                                                            <input type="range" min={0} step={1} max={20}  className="slider" onChange={handleChange('price')} id="myRange"/>
                                                                        </div>
                                                                        <span>20</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='filter-menu__item'>
                                                                <p className='m-0 text-uppercase mb-2'>Blockchain</p>
                                                                <Select placeholder={'Select Blockchain'}  options={blockchainOptions} classNamePrefix={'react-select'} components={{IndicatorSeparator: () => null }} onChange={((e)=>setValues({...values,['blockchain']:e.value}))}/>
                                                            </div>
                                                            <div className='filter-menu__item'>
                                                                <p className='m-0 text-uppercase mb-2'>Currency</p>
                                                                <Select placeholder={'Select Currency'}  options={currencyOptions} classNamePrefix={'react-select'} components={{IndicatorSeparator: () => null }} onChange={((e)=>setValues({...values,['currency']:e.value}))}/>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="row mt-5">
                                                    
                                                    {assets!==undefined && !isloading && assets.map((assetItem,index)=>{
                                                       return <div className="col-md-6 col-lg-4">
                                                            <VirtualLand key={index} data={assetItem}/>
                                                        </div> 
                                                    })}

                                                    { isloading || error ?  
                                                        <div className="full-page">
                                                            { isloading ? (
                                                            <div className="loader" style={{position:'relative',inset:'0',transform:'none'}}>
                                                                <ThreeDots color="#8FF129">
                                                                </ThreeDots>
                                                            </div>) : <NoDataFound style={{maxWidth:'400px'}}></NoDataFound> }
                                                        </div> 
                                                    : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>    
                                    </div>
                                    <div className="tab-pane fade" id="metaverse" role="tabpanel" aria-labelledby="metaverse-tab">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Metaverse
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo">
                                            <div className="accordion-body">
                                                <div className="container-fluid g-0">
                                                <form action="">
                                                    <div className="row mt-2 mb-4">
                                                        <div className="col-12 col-xl-8">
                                                            <div className="input-wrapper">
                                                                <input type="text" className="form-control" id="leaderboard-search" placeholder="Search any Metaverse Lands & other digital assets..."/>
                                                            </div>
                                                        </div>

	    {/*
                                                        <div className="col-12 mt-2 mt-xl-0 col-xl-4 d-flex align-items-center flex-wrap justify-content-xl-between">
                                                            <Select placeholder={'RECENTLY LISTED'}  options={CrowdFundingOptions} className="react-select-style-2" classNamePrefix={'react-select'} components={{IndicatorSeparator: () => null }} onChange={((e)=>console.log(e.value))}/>
                                                            <div className="custom-toggler">
                                                                <label className="switch">
                                                                    <input type="checkbox"/>
                                                                    <span className="slider round"></span>
                                                                </label>
                                                            <p className='m-0'> ON SALE</p>
                                                            </div>
                                                        </div>

		*/}
                                                    </div>
                                                </form>
                                                <div className="row">
                                                    <div className="metaverse-content">
                                                        <p className='m-0 text-uppercase'>LIST OF ALL METAVERSE LANDS & ASSETS </p>
                                                        <MetaverseTable data={metaverseData}></MetaverseTable>

                                                        {/* <div className="mt-4 pagination-wrapper">
                                                            <ul className='list-unstyled d-flex flex-wrap'>
                                                                {[1,2,3,4,5,6,7,8,9].map((paginationItem,index)=>{
                                                                    return(
                                                                        <li key={`pagination-item-${index}`}><button className='btn btn-outline-primary btn-lg mb-2 me-3 lh-1' onClick={(()=>console.log("Pagintion Item ====",paginationItem))}>{paginationItem}</button></li>
                                                                    )
                                                                })}
                                                            </ul>
                                                            <div>Showing 1 out of 10,158</div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="row mt-3 mt-lg-5">
                                                    <div className='carousel'>
                                                        <div className='d-flex flex-wrap align-items-center mb-5'>
                                                            <Select placeholder={'Select Category'}  options={metaVerseOptions} className="react-select-style-2" classNamePrefix={'react-select'} components={{IndicatorSeparator: () => null }} onChange={((e)=>console.log(e.value))} defaultValue={metaVerseOptions[0]}/>
                                                            <p className='m-0 ms-2 carousel-label '>in Decentraland</p>
                                                        </div>
                                                        { !isloading && isloading!==undefined ? 
                                                        <Swiper modules={[A11y]}
                                                            spaceBetween={20}
                                                            slidesPerView={1.4}
                                                            breakpoints={{
                                                                576:{
                                                                    slidesPerView:2.4
                                                                },
                                                                991:{
                                                                    slidesPerView:3.4
                                                                },
                                                            }}
                                                            onSwiper={(swiper) => {
                                                                // console.log(swiper)
                                                            }}
                                                            onSlideChange={() => {
                                                            // console.log('slide change')
                                                            }}>
                                                            {  assets!==undefined && !isloading && assets.map((item,index)=>{
                                                            return <SwiperSlide><VirtualLand key={index} data={item}/></SwiperSlide>
                                                            })}
                                                        
                                                        </Swiper> : <div className="loader" style={{position:'relative',inset:'0',transform:'none'}}><ThreeDots color="#8FF129"></ThreeDots></div>}
                                                    </div>
                                                    <div className='carousel mt-3 mt-lg-5'>
                                                        <div className='d-flex flex-wrap align-items-center mb-5'>
                                                            <Select placeholder={'Select Category'}  options={metaVerseOptions} className="react-select-style-2" classNamePrefix={'react-select'} components={{IndicatorSeparator: () => null }} onChange={(e)=>{ 
                                                                //console.log(e.value))
                                                            }} defaultValue={metaVerseOptions[0]}/>
                                                            <p className='m-0 ms-2 carousel-label '>in Somnium Space</p>
                                                        </div>
                                                        { !isloading && isloading!==undefined ? 
                                                        <Swiper modules={[A11y]}
                                                            spaceBetween={20}
                                                            slidesPerView={1.4}
                                                            breakpoints={{
                                                                576:{
                                                                    slidesPerView:2.4
                                                                },
                                                                991:{
                                                                    slidesPerView:3.4
                                                                },
                                                            }}
                                                            onSwiper={(swiper) => {
                                                                //console.log(swiper)
                                                            }}
                                                            onSlideChange={() => {
                                                                // console.log('slide change')
                                                            }}>
                                                            {  assets!==undefined && !isloading && assets.map((item,index)=>{
                                                            return <SwiperSlide><VirtualLand key={index} data={item}/></SwiperSlide>
                                                            })}
                                                        
                                                        </Swiper> : <div className="loader" style={{position:'relative',inset:'0',transform:'none'}}><ThreeDots color="#8FF129"></ThreeDots></div>}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                    <div className="tab-pane fade" id="web3" role="tabpanel" aria-labelledby="web3-tab">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Web 3
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree">
                                            <div className="accordion-body">
                                                <WorkInProgress style={{maxWidth:'400px'}} loadingText/>
                                            </div>
                                        </div>    
                                    </div>
                                    <div className="tab-pane fade" id="games" role="tabpanel" aria-labelledby="games-tab">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Games
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour">
                                            <div className="accordion-body">
                                                <WorkInProgress style={{maxWidth:'400px'}} loadingText/>
                                            </div>
                                        </div>    
                                    </div>
                                    <div className="tab-pane fade" id="worlds" role="tabpanel" aria-labelledby="worlds-tab">
                                        <h2 className="accordion-header" id="headingFive">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Worlds
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive">
                                            <div className="accordion-body">
                                                <WorkInProgress style={{maxWidth:'400px'}} loadingText/>
                                            </div>
                                        </div>    
                                    </div>
                                </div>
                        </div>
                    </div>
                    {/* <div className="row mt-5 virtual-assets-content__item"  id='store'>
                        <div className="py-5">
                        <h2> My Store Content</h2>
                            <br />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        </>
    )
}

export default VirtualAssets;
