import VirtualTransactionTable from "./VirtualTransactionTable";
import Scrollspy from 'react-scrollspy';
import assetLogo from '../images/icons/welcome/assets.svg';
import helpLogo from '../images/icons/welcome/help.svg';
import topStoriesLogo from '../images/icons/welcome/top-stories.svg';
import { Link } from "react-router-dom";
import PhysicalAssetsTransactions from "./PhysicalAssetsTransactions";
import WorkInProgress from "./WorkInProgress";
import { useState, useEffect } from "react";
import axios from "axios";

import { useNavigate } from 'react-router';
import { Helmet } from "react-helmet-async";

const StoriesItem = (props) => {
    const { storyLink, storyImage, storyCategory, storyDate, storyTitle, storyDesc } = props.data;

    return (
        <div className="col-12 mt-3 border-top d-flex flex-wrap align-items-center justify-content-between story-item">
            <div className="col-12 col-lg-5 ps-0">
                <img src={storyImage} alt="story-image" loading="lazy" />
            </div>
            <div className="col-12 col-lg-7 py-2 ps-3">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <div className="d-flex"><p className=" me-3 mb-0">{storyCategory}</p><p className="mb-0 story-item-date">{storyDate}</p></div>
                        <a href={storyLink} className="text-dark text-decoration-none" target='_blank'>
                            <h6 className="my-2 me-2 story-item-title double-liner">{storyTitle}</h6>
                        </a>
                        <p className="story-item-desc double-liner">{storyDesc}</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

const NewsItems = (props) => {
    const { title, description, image_url, link, category, pubDate } = props.data;
    return (
        <div className="col-12 mt-3 border-top d-flex flex-wrap align-items-center justify-content-between story-item">
            <div className="col-12 col-lg-5 ps-0">
                <img src={image_url ? image_url : "https://img.einnews.com/medium/262638/0101010010.png"} alt="story-image" loading="lazy"/>
            </div>
            <div className="col-12 col-lg-7 py-2 ps-3">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <div className="d-flex"><p className=" me-3 mb-0">{category[0]}</p><p className="mb-0 story-item-date">{pubDate}</p></div>
                        <a href={link} className="text-dark text-decoration-none" target='_blank'>
                            <h6 className="my-2 me-2 story-item-title double-liner">{title}</h6>
                        </a>
                        <p className="story-item-desc double-liner">{description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Welcome = () => {
    const [sidebar, setSidebar] = useState(false);
    // const [fetchData,setFetchData]=useState([]);
    const [readMore,setReadMore]=useState(false);
    const handleSidebar = (e) => {
        setSidebar(!sidebar);
        e.preventDefault();
    }
    const navigate = useNavigate();

    // useEffect(() => {
    //   async function apiData(){
    //     try{
    //     let res= await axios.get('https://newsdata.io/api/1/news?apikey=pub_15567cbd90f2aa5af50063f73192da7e07ced&q=real%20estate');
    //     setFetchData([...res.data.results]);        
    //     }
    //     catch{}
    //   }
    //   apiData();
    // }, [])

    const storiesData = [
        {
            'storyLink': 'https://www.einnews.com/pr_news/560815117/global-real-estate-crowdfunding-market-outlook-2021-market-is-expected-to-grow-at-a-usd-1362-21-billion-to-2030',
            'storyImage': 'https://img.einnews.com/medium/262638/0101010010.png',
            'storyCategory': 'News',
            'storyDate': 'January 17, 2022',
            'storyTitle': 'Global Real Estate Crowdfunding Market Outlook 2021: Market is Expected to Grow at a USD 1362.21 billion to 2030',
            'storyDesc': 'Global Real Estate Crowdfunding to surpass USD 1362.21 billion by 2030 from USD 150.14 billion in 2020 at a CAGR 24.67% in the coming years, i.e., 2021-30.'
        }, {
            'storyLink': 'https://www.arabianbusiness.com/money/corporate/funding/dubai-based-real-estate-crowdfunding-platform-eyes-saudi-market-after-3-million-in-funding',
            'storyImage': 'https://www.arabianbusiness.com/cloud/2022/05/09/Saudi-Aabia-Riyadh.jpg',
            'storyCategory': 'News',
            'storyDate': ' Jun 13',
            'storyTitle': 'Dubai-based real estate crowdfunding platform eyes Saudi market after $3 million funding round',
            'storyDesc': 'SmartCrowd, which allows individual investors to take a “fractional” share in a rental property, has funded more than 70 properties and transacted over AED 50 million'
        }, {
            'storyLink': 'https://fintechnews.ch/proptech/swiss-real-estate-crowdfunding-platform-foxstone-raises-chf-10-million/53364/',
            'storyImage': 'https://x2u3s3r4.stackpathcdn.com/wp-content/uploads/2022/06/Foxstone-Raises-CHF-10-Million-to-Accelerate-Its-Growth-1440x564_c.jpg?x30842',
            'storyCategory': 'FUNDING PROPTECH',
            'storyDate': 'June 15',
            'storyTitle': 'Swiss Real Estate Crowdfunding Platform Foxstone Raises CHF 10 Million',
            'storyDesc': 'The online real estate investment platform Foxstone has completed a CHF 10 million Series A financing round, the company’s largest to date..',

        },
        {
            'storyLink': 'https://finance.yahoo.com/news/caltier-opens-series-5m-round-104100670.html',
            'storyImage': 'https://s.yimg.com/ny/api/res/1.2/YBAoCRIkmIVveF2WuJCgSg--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTMyNjtjZj13ZWJw/https://s.yimg.com/uu/api/res/1.2/FrQSJBfybCfh0qqqmsZKPA--~B/aD0xNjM7dz00ODA7YXBwaWQ9eXRhY2h5b24-/https://media.zenfs.com/en/business-wire.com/e2a45ce55c534b56adbc8855ef51d41f',
            'storyCategory': 'News',
            'storyDate': 'June 20',
            'storyTitle': 'CalTier Opens its Series A $5M Round of Funding',
            'storyDesc': 'The innovative Fintech Crowdfunding platform, today announces it has launched its Series A $5M round of funding following successfully closing its $1.1M Seed round. This Series A round will help CalTier improve its platform, offer additional services, and expand its growing investor community.',

        },
        {
            'storyLink': 'https://finance.yahoo.com/news/palladius-capital-management-partners-realtymogul-133000998.html',
            'storyImage': 'https://s.yimg.com/ny/api/res/1.2/XR_GyQlGLAAYblds73HtvQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTU0MDtjZj13ZWJw/https://s.yimg.com/uu/api/res/1.2/suWf1SWpJAkYGFCJFfX19w--~B/aD0yNzA7dz00ODA7YXBwaWQ9eXRhY2h5b24-/https://media.zenfs.com/en/business-wire.com/7c113d4569116a282a6507deb55f0f8a',
            'storyCategory': 'News',
            'storyDate': 'March 14',
            'storyTitle': 'Palladius Capital Management Partners with RealtyMogul to Provide Accredited Investors with Access to Palladius Real Estate Fund I, L.P.',
            'storyDesc': 'PREF I targets investments in multifamily and student housing properties across the country',

        },
        {
            'storyLink': 'https://www.theedgemarkets.com/article/cover-story-real-estate-ownership-redefined',
            'storyImage': 'https://assets.theedgemarkets.com/DE6-Kunang-Resort-tem1427_20220623203632_theedgemarkets.jpg?hkDp0jRQT_1zIZVL_ZWMCjbl7pqE3Fhc',
            'storyCategory': 'News',
            'storyDate': 'June 27',
            'storyTitle': 'Cover Story: Real estate ownership redefined',
            'storyDesc': 'Non-fungible tokens, or NFTs, have taken the world by storm in recent years, emerging as a new paradigm for the hyper-digitalised generation.',

        },
        {
            'storyLink': 'https://www.theedgemarkets.com/article/cover-story-real-estate-ownership-redefined',
            'storyImage': 'https://www.financialexpress.com/wp-content/uploads/2022/06/virtual-reality1.jpg',
            'storyCategory': 'News',
            'storyDate': 'June 27',
            'storyTitle': 'Metaverse: Virtual Realty to become a Virtual Reality',
            'storyDesc': 'In metaverse, real estate is a virtual ecosystem, where technologies such as virtual reality, augmented reality, seamlessly come together to create a real-world user experience.',

        },
    ];
    return (
        <>
        <Helmet>
        <title>Araa.land - Your Global Real Estate Marketplace</title>
        <meta name="description" content="Find Any global Real Estate crowdfunding & tokenization assets from one convenient place" />
        <meta property="og:title" content="Browse and Find Real Estate: Crowdfunding & Web3 | Araa Transcendence Technologies | Dubai" />
        <meta property="og:description" content="We make it easy for Real Estate buyers to Browse and Find any Tokenized or Crowdfunded physical Real Estate asset from hundres of Crowdfunding sites in addition to Virtual Land and Virtual Real Estate in all Metaverses and Web3.0. Araa is the ultimate Real Estate specialized Aggregator Platform."/>
        <meta property="og:image" content="https://static.wixstatic.com/media/339d55_0b202cc8cd3847baa70fb5bd45f668a1~mv2.png/v1/fill/w_2500,h_2500,al_c/339d55_0b202cc8cd3847baa70fb5bd45f668a1~mv2.png"/>
        <meta property="og:image:width" content="2500"/>
        <meta property="og:image:height" content="2500"/>
        <meta property="og:url" content="https://app.araa.land"/>
        <meta property="og:site_name" content="Araa"/>
        <meta property="og:type" content="website"/>
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:title" content="Browse and Find Real Estate: Crowdfunding & Web3 | Araa Transcendence Technologies | Dubai"/>
        <meta property="twitter:description" content="We make it easy for Real Estate buyers to Browse and Find any Tokenized or Crowdfunded physical Real Estate asset from hundres of Crowdfunding sites in addition to Virtual Land and Virtual Real Estate in all Metaverses and Web3.0. Araa is the ultimate Real Estate specialized Aggregator Platform."/>
        <meta property="twitter:image" content="https://static.wixstatic.com/media/339d55_0b202cc8cd3847baa70fb5bd45f668a1~mv2.png/v1/fill/w_2500,h_2500,al_c/339d55_0b202cc8cd3847baa70fb5bd45f668a1~mv2.png"/>
        <link rel="canonical" href="https://app.araa.land" />
        </Helmet>
        <div className="main-content welcome">
            {/* <div className="main-content__left ">
                <Scrollspy items={['your-assets', 'top-stories', 'help']} className={`list-unstyled sidebar ${sidebar && 'active'}`} currentClassName="active">
                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="/dashboard"> <span className="d-flex align-items-center me-2"><img src={assetLogo} alt="icon" /></span> <span className="d-block">Your Assets</span></a></li>

                    {/* <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#top-stories" > <span className="d-flex align-items-center me-2"><i className="fa-solid fa-bullhorn"></i></span> <span className="d-block">Top Stories</span></a></li> */}
                    {/*
                <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#help"> <span className="d-flex align-items-center me-2"><i className="fa-solid fa-question"></i></span> <span className="d-block">Help</span></a></li>
*/}
              {/*  </Scrollspy>
                <button className="btn btn-primary sidebar-toggler" onClick={((e) => handleSidebar(e))}>
                    <span></span><span></span><span></span>
                </button>
                <button className="btn btn-primary sidebar-close" onClick={((e) => handleSidebar(e))}>
                    X
                </button>
            </div> */}
            <div className="main-content__right ">
                <div className="container-fluid">
                    <div className="position-relative">
                            <h1 className=" p-3 m-5 text-center" >Your Gateway to Global Real Estate Investments</h1>
                        <div className="row m-0 mt-lg-4" id="your-assets">
                            <div className="col-12 col-md-10 col-lg-8 asset-item">
                                {/* <Link to={"physical-assets"} className="text-decoration-none text-body d-block"> */}
                                <h1 className="mt-4 mt-lg-0 seo-optimization"><button className="btn btn-primary box-shadow-light text-center w-100 font20" onClick={() => navigate('/physical-assets', { state: "physical-estate" })}>Physical Assets</button></h1>
                                {/* </Link> */}
                                <div className="asset-item-inner" >
                                    <div className="pt-1 px-3 asset-item-inner__top">
                                        <h4>Real Estate Crowdfunding Market</h4>
                                        <p className="mb-1">Recent Listings</p>
                                    </div>
                                    <PhysicalAssetsTransactions />
                                </div>
                                <div className="view-more-button">
                                    <button className="btn btn-primary p-2"><Link to='/browse#physical-assets' className="text-white">View More</Link></button>
                                </div>
                            </div>
                            {/* <div className="col-12 col-md-6 col-lg-5 asset-item">
                                <Link to={"virtual-assets"} className="text-decoration-none text-body d-block">
                                    <h1 className="mt-4 mt-lg-0 p-2 box-shadow-light text-center seo-optimization">Virtual Digital Assets</h1>
                                </Link>

                                <div className="asset-item-inner" >
                                    <div className="pt-1 px-3 asset-item-inner__top">
                                        <h4>metaverse, web3 & Gaming platforms</h4>
                                        <p className="mb-1">Recent Transactions</p>
                                    </div>
                                    <VirtualTransactionTable></VirtualTransactionTable>
                                </div>
                                <div className="view-more-button">
                                    <button className="btn btn-primary p-2"><Link to='/virtual-assets' className="text-white">View More</Link></button>
                                </div>
                            </div> */}
                            {/* <div className="col-12 col-md-6 col-lg-4 asset-item">
                                <Link to={"hybrid-assets"} className="text-decoration-none text-body d-block">
                                    <h3 className="mt-4 mt-lg-0">Hybrid Assets</h3>
                                </Link>
                                <div className="asset-item-inner" >
                                    <div className="pt-1 px-3 asset-item-inner__top">
                                        <h4>real estate physical to digital link</h4>
                                        <p className="mb-1"> Recent Transactions </p>
                                    </div>
                                    <WorkInProgress style={{maxWidth:'251px'}}></WorkInProgress>
                                    //  <TransactionTable></TransactionTable> 
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="mt-5 row" id="bg">
                        <div className="col-sm-6 pt-5 araa-description">
                            <h6 className="text-center py-5 font20">WHAT IS ARAA</h6>
                            <p className="text-break">Araa is the go-to destination for anyone looking to invest in digitized real estate.   Our platform makes it easy to search and find tradeable real estate, including fractionalized and  tokenized properties,  from hundreds of crowdfunding sites from across the world.</p>
                            {/* <p className="d-flex justify-content-center">
                                <button className="btn mx-2 btn-css">Real Estate Gateway</button>
                                <button className="btn mx-2 btn-css">Why Araa?</button>
                            </p> */}
                        </div>
                        <div className="col-sm-6 p-0">
                            <img className="w-100 h-100 img-ratio" src="/images/flatiron araa.jpg" alt="image"/>
                        </div>
                    </div>
                    <div className="my-5 row custom_cards">
                            <div className="m-3 text-center col">
                                <h3 className="my-2">ONE PLATFORM</h3>
                                <p className="my-2">One single convenient platform where real estate investors can easily browse tokenized and crowdfunded properties globally, eliminating the need to navigate countless websites & marketplaces.</p>
                            </div>
                            <div className="m-3 text-center col">
                                <h3 className="my-2">TOOLS & INSIGHTS</h3>
                                <p className="my-2">Innovative tools and data-driven insights to help make informed decisions about your investments in the real estate crowdfunding & tokenized market as well as those real estates in the Metaverse.</p>
                            </div>
                            <div className="m-3 text-center col">
                                <h3 className="my-2">GLOBAL REACH</h3>
                                <p className="my-2">We enable real estate marketplaces and issuers to reach a wider global audience interested in buying real estate fractional opportunities all around the world - instead of exclusively relying only on non-specialized social media platforms.</p>
                            </div>
                            <div className="m-3 text-center col">
                                <h3 className="my-2">LIQUIDITY</h3>
                                <p className="my-2">A thriving secondary market & exchange with high trading volume is critical for success of the real estate crowdfunding industry. Our ultimate objective is to create an exchange realizes the potential of the real estate tokenization, crowdfunding & tokenization.</p>
                            </div>
                    </div>
                    <div className="our_purpose_bg m-0">
                    <div className="" style={{marginBottom:'120px'}}>
                        <h2 className="my-4 font30">Real Estate Secondary Market and Exchange</h2>
                        <p className="my-2 font20">We're committed to developing the secondary market and unlocking true liquidity for investors, so you can trade units of real estate with ease. With Araa you'll have access to all the revelant data and marktet intelligence you need to make informed decisions.</p>
                        <p className="my-2 font20">From virtual land to physical property , we're the ultimate aggregator and marketplace for all things digitized real estate.</p>
                    </div>
                    <div className="mt-5 font20 text-break" >
                        <h2 className="my-4 font30" >OUR PURPOSE</h2>
                        <h5 className="my-4 font30">TRANSFORMING REAL ESTATE INTO TRADABLE ASSETS TO UNLOCK LIQUIDITY</h5>
                        <p className="my-2">At Araa, our goal is to empower the world of real estate by making it easier to monetize properties and accelerate the adoption of digitized real estate.<br/>
                        {readMore ? 
                        (<p>We're building a global community of real estate professionals and enthusiasts, and providing them with the tools and insights they need to succeed.<br/><br/>
                        We aggregate information from all physical and virtual real estate platforms, so you can easily access the information and resources you need to make informed decisions. Whether you're an investor, developer, or just someone who loves real estate, we're here to help you navigate the ever-evolving world of digitized property and make the most of your investment.<br/><br/>
                        Ultimately, we want to build the global exchange for tradeable real estate to unlock true liquidity</p>)
                            :null
                    }</p>
                    <p style={{fontSize:'14px'}}><span className='cursor-pointer' onClick={()=>setReadMore(!readMore)}><i>{ readMore?"Read less":"... Read More"}</i></span></p>
                    <p className="button-browse-listing mt-3">
                        {/* <button className="btn btn-primary p-3" onClick={()=>setReadMore(!readMore)}>{ readMore?"Read less":"Read More"}</button> */}
                        <button className="btn btn-primary p-3 text-black" onClick={()=>navigate('/browse')} style={{borderRadius:'22px', width:'170px'}} >Browse Listings</button>
                        </p>
                    </div>
                    </div>
                    <div className="browse-list-bg">
                        <div className="row m-0">
                            <p className="col-sm-6 custom-div-img">
                                {/* <img className="w-100" src="https://static.wixstatic.com/media/339d55_9b33bd4416b546cd8c9ad7e4c252ea39~mv2.jpg/v1/fill/w_692,h_913,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/339d55_9b33bd4416b546cd8c9ad7e4c252ea39~mv2.jpg" alt="image"/> */}
                            </p>
                            <div className="col-sm-6 mt-5 px-5">
                                <p className="mt-5 pt-5 font22">At Araa's, we're passionate about helping real estate crowdfunding platforms succeed in the exciting world of fractional ownership and tokenized real estate. Our real estate aggregator platform is designed to increase visibility and demand for listings, bringing more traffic to your platform and growing your community. Whether you're selling tokenized real estate, fractional ownership or real estate crowdfunding units, or even digital real estate or virtual lands and NFTs, we're here to help you get the exposure you need to thrive.</p>
                            <div className="mt-5">
                                <p className="font22">Log in as a Platform Owner / Administrator and update your company’s profile</p>
                                <p><Link to='/login?partner=1' className="text-decoration-none"><button className="btn btn-primary p-3 m-2 text-black" style={{borderRadius:'22px'}}>Sign in as a Platform</button></Link></p>
                            </div></div>

                        </div>
                    </div>
                    {/* <div className="row mt-3 py-3 stories-wrapper" id="top-stories" >
                        <div className="col-12">
                            <h5>Top Stories</h5>
                        </div>
                        {storiesData && storiesData!==undefined  ? (
                        <div>
                            // { storiesData.map((storyItem,index)=>{
                            //     return <StoriesItem data={storyItem} key={`story-item-${index}`}></StoriesItem>
                            // })} 
                            {fetchData?.map((storyItem,index)=>(
                                <NewsItems data={storyItem} key={"fetchData"+index}/>
                            ))}
                        </div>
                        ) : null }
                        {fetchData.length===0 &&(<h5>Could not fetch news.</h5>)}
                    </div> */}
                    {/*<div className="row mt-3 py-3" id="help">
                        <h2>Help</h2>
                        
                        <WorkInProgress style={{maxWidth:'400px'}} loadingText/>
                    </div>
                        */}
                </div>
            </div>
        </div>
        </>
    );
}

export default Welcome;
