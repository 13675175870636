import Daniel from '../images/daniel.jpg';
import Barry from '../images/barry.jpg';
import Toufi from '../images/toufi.jpg';

import Dino from '../images/dino.jpg';
import Bassam from '../images/bassam.jpg';
import Andy from '../images/andy.png';
import Alex from '../images/alex.jpg';
import Dann from '../images/dann.png';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
const About = () =>{
    const navigate=useNavigate();
    const team1 = [
        {
	    "name": "Daniel Saliba",
            "role": "CEO",
            "img": Daniel,
            "desc": "Successful track record – Private Equity Commercial Real Estate. Investment & Asset Management. Entrepreneur"
	},{
	    "name": "Barry Rowe",
            "role": "CTO",
            "img": Barry,
            "desc": "Successful 15 years in tech development, AI, Blockchain. PhD in Math. Ability to develop new technology most efficiently & expeditiously"
	},{
	    "name": "Toufi Saliba",
            "role": "Tech Strategy",
            "img": Toufi,
            "desc": "20 years technopreneur, focus on AI, Cryptograhpy & Cybersecurity, co-author TODA internet protocol, Chair IEEE International Protocols for AI Security"
	}

    ]

    const team2 = [
        {
	    "name": "Dino Antzoulis",
            "role": "Real Estate",
            "img": Dino,
            "desc": "18+ years investment experience in global commercial Real Estate and technology"
	},{
	    "name": "Bassam Falah",
            "role": "Growth Strategy",
            "img": Bassam,
            "desc": "Venture builder, growth hacker, real estate investor, startup incubator, entrepreneur"
	},{
	    "name": "Andy Watson",
            "role": "Active Advisor",
            "img": Andy,
            "desc": "Ex CEO of $20B+ Fund, Private Equity and extensive experience and network in international Real Estate,"
	},{
	    "name": "Alexandre Hayek",
            "role": "Governance",
            "img": Alex,
            "desc": "President of World Association of PPP Units & Professionals, Previous Nominee to World Bank Presidency, Investment Banker, and Advisor to Governments"
	},{
	    "name": "Dann Toliver",
            "role": "Tech Advisor",
            "img": Dann,
            "desc": "20 years in technology, tech engineer, architect, researcher & TODA protocol co-author"
	}
    ]

    return(<>
        <Helmet>
        <title>About | Araa Real Estate Marketplace & Exchange</title>
        <meta name="description" content="Introducing the future of real estate investing &#9472; Araa is building a global marketplace for tradeable real estate. Our platform allows for easy access and browsing of crowdfunded and fractionalized real estate listed and aggregated from all around the world, " /> 
        <meta property="og:title" content="About | Araa Real Estate Marketplace & Exchange" />
        <meta property="og:description" content="Introducing the future of real estate investing – Araa is building a global marketplace for tradeable real estate. Our platform allows for easy access and browsing of crowdfunded and fractionalized real estate listed and aggregated from all around the world, " />
        <meta property="og:image" content="https://static.wixstatic.com/media/339d55_6a24db0a3d844c06950a55881e78f481%7Emv2.jpg/v1/fit/w_2500,h_1330,al_c/339d55_6a24db0a3d844c06950a55881e78f481%7Emv2.jpg" />
        <meta property="og:image:width" content="2500" />
        <meta property="og:image:height" content="1330" />
        <meta property="og:url" content="https://app.araa.land/about" />
        <meta property="og:site_name" content="Araa " />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About | Araa Real Estate Marketplace & Exchange" />
        <meta name="twitter:description" content="Introducing the future of real estate investing – Araa is building a global marketplace for tradeable real estate. Our platform allows for easy access and browsing of crowdfunded and fractionalized real estate listed and aggregated from all around the world, " />
        <meta name="twitter:image" content="https://static.wixstatic.com/media/339d55_6a24db0a3d844c06950a55881e78f481%7Emv2.jpg/v1/fit/w_2500,h_1330,al_c/339d55_6a24db0a3d844c06950a55881e78f481%7Emv2.jpg" />
        <link rel="canonical" href="https://app.arra.land/about" />
        </Helmet>
        <div className="main-content">
        <div className="main-content__left">
        </div>
        <div className="main-content__right">
        {/* <div className="container-fluid" style={{marginTop:30}}>
                    <button className='btn btn-primary p-2' style={{display:'flex',alignItems:'center'}}onClick={()=>navigate(-1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-big-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M20 15h-8v3.586a1 1 0 0 1 -1.707 .707l-6.586 -6.586a1 1 0 0 1 0 -1.414l6.586 -6.586a1 1 0 0 1 1.707 .707v3.586h8a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1z"></path>
</svg>
                  &nbsp; Back
                    </button>
                </div> */}
            <div className="about-page">
                <div className='mx-auto' style={{maxWidth:'1000px'}}>
	          <h1 className="company-title">Company</h1>
                    <p className='text-center'>Introducing the future of real estate investing – Araa is building a global marketplace for tradeable real
estate. Our platform allows for easy access and browsing of crowdfunded and fractionalized real estate
aggregated from all around the world, giving buyers the ability to find, purchase, and manage digitized
properties from a single location. But we don't stop there. In our quest to help thousands of global real estate
crowdfunding and tokenization platforms succeed, we're also building a secondary market to provide true
liquidity for real estate investments, allowing for easy buying and selling of digitized real estate. We
therefore help fulfil the promise of real estate liquidity and diversification.</p>

                    <p className='text-center'>Our vision goes beyond just making it easier to invest in real estate, we want to accelerate the adoption of
digitized real estate and enable hundreds of millions of property owners to benefit. We believe in the
potential for web3.0 (and beyond) and digitization to revolutionize the real estate industry, and we're
dedicated to helping all stakeholders partake in this new era of wealth creation. From real estate developers
to individual home owners, land and farm owners to investors, our services and products are designed to
empower everyone to join the digital revolution and reap the benefits of the future of real estate investing.
Join us on our mission to reshape the largest asset class in the world and enable hundreds of millions of
people to participate and benefit from new technologies shaping the real estate industry. Join our
community!</p>
            <div style={{display:'flex',marginTop:'50px'}}>
                <div>
	            <h3 className='team-header'>The Team</h3>
                <p>Founding Community Members: <br/><br/>All accomplished globally with international reach and a wide variety of skills and background</p>
                </div>
                <div>
	              <div className="teamRow" >
	    {team1.map((item,index)=>{
                        return (
                          <div className="teamCard1">
                            <img src={item.img} alt="about-us" className='img-fluid team1-img' loading='lazy'/>
			    <label>{item.name}</label>
			    <label className='text-green'>{item.role}</label>
			    <p className="text-grey">{item.desc}</p>

	                  </div>
			)
		        })}
	              </div>
	              <div className="teamRow" >
	                {team2.map((item,index)=>{
                        return (
                          <div className="teamCard2">
                            <img src={item.img} alt="about-us" className='img-fluid team2-img' loading='lazy'/>
                            <label>{item.name}</label>
                            <label className='text-green'>{item.role}</label>
                            <p className="text-grey">{item.desc}</p>
                          </div>
			)
		        })}
	              </div>
                </div>
            </div>
        	  </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default About;
