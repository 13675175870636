import './App.css';
import './Style/custom-style.scss';
import { BrowserRouter, Link, Route } from "react-router-dom";
import { Routes, useNavigate } from "react-router";
import CookieConsent from "react-cookie-consent";
import {Header, Footer,Invite} from './Components';
import { useEffect, useState,lazy,Suspense } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { HelmetProvider } from 'react-helmet-async';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import AdminTable from './Components/AdminTable';
import { useDispatch, useSelector } from 'react-redux';
const AdminEditForm =lazy(()=>import('./Components/AdminEditForm'));
const PrivateRoute =lazy(()=>import('./Components/PrivateRoute/Private')) 
const About= lazy(()=>import('./Components/About'));
const Login= lazy(()=>import('./Components/Login'));
const Welcome= lazy(()=>import('./Components/Welcome'));
const PhysicalAssets= lazy(()=>import('./Components/PhysicalAssets'))
const PhysicalAssetDetails= lazy(()=>import('./Components/PhysicalAssetDetails'))
const VirtualAssets= lazy(()=>import('./Components/VirtualAssets'))
const HybridAssets=  lazy(()=>import('./Components/HybridAssets'))
const Dashboard= lazy(()=>import('./Components/Dashboard'))
const Leaderboards= lazy(()=>import('./Components/Leaderboards'))
const Community= lazy(()=>import('./Components/Community'))
const Tools= lazy(()=>import('./Components/Tools'))
const Browse= lazy(()=>import('./Components/Browse'))
const Timestamp= lazy(()=>import('./Components/Timestamp'))
const Profile = lazy(()=>import('./Components/Profile'))
const ProfileAccount= lazy(()=>import('./Components/ProfileAccount'))
const ProfileSummary= lazy(()=>import('./Components/ProfileSummary'))
const NoPageFound = lazy(()=>import('./Components/NoPageFound'))
const Test= lazy(()=>import('./Components/Test'))
const ForgotPassword= lazy(()=>import('./Components/ForgotPassword'))
const SignUp= lazy(()=>import('./Components/SignUp'))
const LoadUser= lazy(()=>import('./Components/LoadUser'))
const CustomForm=  lazy(()=>import('./Components/CustomForm'))
const BuyPropIDs= lazy(()=>import('./Components/BuyPropIDs'))
const PartnerProfile =lazy(()=>import('./Components/PartnerProfile'));
const Greet =lazy(()=>import('./Components/Greet'));
const ConfirmForm =lazy(()=>import('./Components/ConfirmForm'));

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch=useDispatch();
  const userData=useSelector(state=>state.auth.user);
  const helmetContext = {};
  const handleAcceptCookie = () => {};
  const handleDeclineCookie = () => {};

  const handleLoading = () => {
  setIsLoading(false);
  }
  useEffect(()=>{
    handleLoading();
    if(sessionStorage.getItem('userData')){
      try{
        dispatch({type:'LOGIN_SUCCESS',payload:JSON.parse(sessionStorage.getItem('userData'))})
      }catch{}
    }
  },[])
  return (
    <HelmetProvider context={helmetContext}>
    <BrowserRouter>
      { !isLoading ? <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between',minHeight:'100vh'}}>
        <Header />
        <main className='main'>
        <ErrorBoundary>
          <Suspense fallback={<div className="loader"><ThreeDots color="#8FF129"></ThreeDots></div>}>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path='/form' element={<CustomForm/>} />
              <Route path='/confirm-form' element={<ConfirmForm/>} />
              <Route path='/buy_property_dids' element={<BuyPropIDs/>} />
              <Route path='/propid' element={<Timestamp/>} />
              <Route path='/dashboard' element={<Dashboard/>} />
              <Route path='/profile' element={<Profile/>} >
                  <Route path="summary" element={<ProfileSummary />} />
                  <Route path="account" element={<ProfileAccount />} />
              </Route>
              <Route path='/partner-profile' element={<PartnerProfile/>}/>
            </Route>
            <Route path='/about' element={<About />} />
            <Route path='/' element={<Welcome/>} />
            <Route path='/greet' element={<Greet/>} />
            <Route path='/platforms' element={<Leaderboards/>} />
            <Route path='/community' element={<Community/>} />
            <Route path='/tools' element={<Tools/>} />
            <Route path='/browse' element={<Browse/>} />
            <Route path='/physical-assets' element={<PhysicalAssets/>} />
            <Route path='/hybrid-assets' element={<HybridAssets/>} />
            <Route path="physical-assets/:assetName" element={<PhysicalAssetDetails />}/>
            <Route path='/virtual-assets' element={<VirtualAssets/>} />
            <Route path='login' element={<Login />} />
            <Route path='signup' element={<SignUp />} />
            <Route path='forgot_password' element={<ForgotPassword />} />
            <Route path='load_user' element={<LoadUser />} />
              <Route path='/admin-formupdate' element={<AdminEditForm/>} />
              <Route path='/admin-table' element={<AdminTable/>} />         
            <Route path='test' element={<Test />} />
            <Route path='*' element={<NoPageFound />} />
          </Routes>
          </Suspense>
          </ErrorBoundary>
        </main>
        <Invite/>
        <Footer />
      </div> :  <div className="loader"><ThreeDots color="#8FF129"></ThreeDots></div> }
    </BrowserRouter>
    <CookieConsent
     enableDeclineButton 
     onAccept={handleAcceptCookie}  
     onDecline={handleDeclineCookie}
     buttonStyle={{ backgroundColor: "#8FF129" , color:'white'}}
     buttonText="Accept"
     location="bottom"
     >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </HelmetProvider>
  );
}

export default App;
