import { useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import formatPrice from "../CustomFunctions/formatPrice";
import { DashboardThunk } from "../Reduxstore/thunks";
import CustomTable from "./Tables/CustomTable";

const Test = () =>{
    const dispatch = useDispatch();
    const {dashboard} = useSelector(state=> state.dashboard);
    let data ;
    if(dashboard!=''){
        data = dashboard.assets.digital_land.ethereum['0xb15069b02e4E9177cb473CbeF3a37Ba1Ed704b26'].erc721
        
        // console.log(data);

        var myPlot = data.map(each=>{
           var {name , image , performance:{purchase_price,current_price}} = each;
            return {col1:{name,image},col2:purchase_price,col3: current_price } 
        })
    }
    var columns = useMemo(
        () => [
            {
            Header: 'Name',
            accessor: 'col1', // accessor is the "key" in the data
            disableSortBy: true,
            
            Cell:({row})=>{
                const {name,image} = row.original.col1
                return(
                //    console.log(row.original.col1)
                    <div className="icon-wrapper">
                        <div className="image-wrapper">
                            <img src={image} alt="icon" className='img-fluid' loading='lazy'/>
                        </div>
                        <div className="d-inline-block ms-3"><p className="asset-name mb-0">{name} </p></div>
                    </div>
                )
            }
            },
            {
            Header: 'Purchase Price',
            accessor: 'col2',
            },
            {
            Header:'Current Price',
            accessor:'col3'
            }
        ],
        []
    );

    useEffect(()=>{
        dispatch(DashboardThunk());
    },[])
    
    return(
    <div className="mt-5">
    <div className="table-wrapper">
        {dashboard!=="" ? <CustomTable data={{columns,myPlot,sortData:false,myName:'Hariom'}}></CustomTable> : <h3>Loading....</h3>}
    </div>
    </div>
    )
}
export default Test;