import AboutUsImage from '../images/about-us-primary.png';
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
const BuyPropIDs = () =>{
    return(
        <>
        <Helmet>
        <title>Buy Property from Araa.land - Your Global Real Estate Marketplace</title>
        <meta name="description" content="Buy Property from Araa.land - Find Any global Real Estate crowdfunding & tokenization assets from one convenient place" />
        <link rel="canonical" href="https://app.arra.land/buy_property_dids" />
        </Helmet>
        <div className="main-content">
        <div className="main-content__left">

        </div>
        <div className="main-content__right">
            <div className="no-page" style={{height:'calc(100vh - 280px)'}}>
                <div style={{maxWidth:'500px', 'text-align': 'left', 'height': '100%', 'margin-right':'auto'}}>
	            <div style={{'height': '50px'}}></div>
                    <h3>Buy Property DIDs</h3>
	            <p>Cost per PropertyID: $29</p>

	            <p>Email <a href="mailto:admin@araa.land">admin@araa.land</a> to pay by wire or bank transfer.</p>
	            <p>Paypal and credit card purchase coming soon.</p>
	        </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default BuyPropIDs;
