import { useParams } from "react-router-dom";
import { useState } from "react";
import Scrollspy from 'react-scrollspy';
import MyChart from "./MyChart";


import myAssetsLogo from '../images/icons/dashboard/my-assets.svg';
import performanceLogo from '../images/icons/dashboard/performance.svg';
import importAssetLogo from '../images/icons/dashboard/import-assets.svg';
import forYouLogo from '../images/icons/dashboard/for-you.svg';
import learnAndEanLogo from '../images/icons/dashboard/learn-and-earn.svg';
import notificationsLogo from '../images/icons/dashboard/notifications.svg';
import inviteLogo from '../images/icons/dashboard/invite.svg';
import { Helmet } from "react-helmet-async";



const PhysicalAssetDetails = () =>{
    const [activePortfolioBtn,setactivePortfolioBtn] = useState(); 

    const handleActive = (id) =>{
        setactivePortfolioBtn(id)
    }

    const myChartOptions = {
        maintainAspectRatio:false,
        responsive: true,
        scales: {
          x: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: false,
            }
          },
          y: {
            ticks: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: false,
            }
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
            text: '$ 17,643.41',
            align: 'start',
            padding: {
              top: 20,
            },
            font: {
              size: 50,
              family: "'AlbulaPro-Bold',sans-serif",
            }
          },
          subtitle: {
            display: true,
            text: '$ 17,643.41',
            align: 'start',
            padding: {
              top: 20,
            },
            font: {
              size: 20,
              family: "'AlbulaPro-Bold',sans-serif",
            },
          },
          tooltip: {
            padding: 20,
            bodyFont: {
              size: 36,
            },
            backgroundColor: '#8FF129',
            cornerRadius: 20,
            caretSize: 10,
            caretPadding: 20,
            callbacks: {
              title: function() {},
              beforeLabel: function() {},
            },
            displayColors:false,
          },
      
        },
    };

    const chartData = {
    labels:[2012,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022],
    datasets: [
        {
        data: [0, 20, 45, 60 ,85, 120, 250, 450,600,750,950 ],
        borderColor: '#8FF129',
        backgroundColor: '#8FF129',
        // pointStyle: 'none',
        pointRadius: 5,
        pointHoverRadius: 10,
        },
    ],
    };

    const {assetName} = useParams();

    const assetDescriptions = {
	    "crowdstreet":
["CrowdStreet is an American real estate software solutions company that focuses helping real estate investors find investment opportunities and helps commercial real estate firms find and manage investors. The company is based out of Portland, Oregon.","Through its platforms it is a marketplace for commercial real estate that helps both investors to invest in crowdfunded commercial real estate and helps commercial real estate firms and developers to easily raise capital and manage investors."],
	    "realtymogul": ["RealtyMogul.com is a real estate crowdfunding platform for accredited and institutional investors focused on USA. Since its inception in 2013, over $1B has been invested by investors in debt and equity products, financing more than 550 commercial and residential properties."],
            "propertyshare": ["A tech platform  that provides its users access to institutional grade commercial real estate with property management and liquidity through resale in India."],
            "lofty": ["Lofty is a crowdfunding marketplace that focuses on rent generating properties in the USA.  Those properties are tokenized using the Algorand blockchain.  Minimum investment starts with $50 only."],


	'thesandbox':['...'],
	'decentraland': ['...'],
        'nftworlds': ['...'],
	'somniumspace': ['...']
    }
    const assetDescription = assetDescriptions[assetName.toLowerCase().replace(" ","")];

    // const financialItemList = [
    //     {label:'PLATFORM',value:assetName},
    //     {label:'CUSTODIAN',value:'Yes'},
    //     {label:'KYC REQUIRED',value:'Yes'},
    //     {label:'REGULATED',value:'Yes, DIFC'},
    //     {label:'LICENSE',value:'354C'},
    //     {label:'CURRENCY',value:'AED'},
    //     {label:'SECONDARY MARKET',value:'No'},
    //     {label:'COMMISSON',value:'Yes'},
    //     {label:'FUNDING',value:'USD 9MIL'},
    // ];
    const financialItemList = [
      {label:'PLATFORM',value:'-'},
      {label:'CUSTODIAN',value:'-'},
      {label:'KYC REQUIRED',value:'-'},
      {label:'REGULATED',value:'-'},
      {label:'LICENSE',value:'-'},
      {label:'CURRENCY',value:'-'},
      {label:'SECONDARY MARKET',value:'-'},
      {label:'COMMISSON',value:'-'},
      {label:'FUNDING',value:'-'},
  ];

    const date =  new Date().toUTCString();

    // const chartContentList = [
    //     {label:'MARKET CAP',value:'AED 2.1T'},
    //     {label:'VOLUME (24H)',value:'AED 119.7B'},
    //     {label:'CIRCULATING SUPPLY',value:'19M'},
    //     {label:'TYPICAL HOLD TIME',value:'100 Days'},
    //     {label:'TRADING ACTIVITY',value:'79% BUY 21% SELL'},
    //     {label:'PERFORMANCE',value:`Updated ${date}`},
    // ];
    const chartContentList = [
      {label:'MARKET CAP',value:'-'},
      {label:'VOLUME (24H)',value:'-'},
      {label:'CIRCULATING SUPPLY',value:'-'},
      {label:'TYPICAL HOLD TIME',value:'-'},
      {label:'TRADING ACTIVITY',value:'-'},
      {label:'PERFORMANCE',value:`-`},
  ];


    return(
      <>
        <Helmet>
        <title>Find Physical Realestate here on Arra Land.</title>
        <meta name="description" content="Find Any global Real Estate crowdfunding from one convenient place" />
        <link rel="canonical" href="https://app.arra.land/physical-assets" />
        </Helmet>
        <div className="main-content physical-assets-details">
            <div className="main-content__left ">
            <Scrollspy items={ ['my-assets', 'performance','import-assets','for-you','learn-and-earn','notifications','invite'] } className="list-unstyled sidebar" currentClassName={'active'}>
	            {/*
                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#my-assets"> <span className="d-flex align-items-center me-2"><img src={myAssetsLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Assets</span></a></li>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#performance" > <span className="d-flex align-items-center me-2"><img src={performanceLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Performance</span></a></li>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#import-assets"> <span className="d-flex align-items-center me-2"><img src={importAssetLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Import Assets</span></a></li>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#for-you"> <span className="d-flex align-items-center me-2"><img src={forYouLogo} alt="icon" loading='lazy'/></span> <span className="d-block">For You </span></a></li>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#lear-and-earn"> <span className="d-flex align-items-center me-2"><img src={learnAndEanLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Learn & Earn </span></a></li>

                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#notifications"> <span className="d-flex align-items-center me-2"><img src={notificationsLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Notifications </span></a></li>
                    
                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#invite"> <span className="d-flex align-items-center me-2"><img src={inviteLogo} alt="icon" loading='lazy'/></span> <span className="d-block">Invite Friends </span></a></li>
                    */}
                </Scrollspy>
            </div>
            <div className="main-content__right">
              <div className="container-fluid">
                <div className="row mt-4" id="my-assets">
                    <div className="col-12 col-lg-12">
                        <h3 className="text-capitalize">{assetName}</h3>
                        {/* <div className="chart-wrapper p-0">
                            <div className="d-flex flex-wrap align-items-start justify-content-between p-5">
                                <div>
                                    <h2> <sup className="currency">USD</sup>0.00
                                      // 9.55 M  
                                    //  <sup className="price-change">+10.65%</sup> 
                                    </h2>
                                    //  <h5>Market Statistics</h5> 
                                </div>
                                // <div className="d-flex button-wrapper flex-wrap">
                                  <button className={`btn ${activePortfolioBtn==='dash-1h' ? 'active' :''}`} id="dash-1h" onClick={((e)=>{handleActive(e.target.id)})}>1H</button>
                                  <button  className={`btn ${activePortfolioBtn==='dash-2h' ? 'active' :''}`} id="dash-2h" onClick={((e)=>{handleActive(e.target.id)})}>24H</button>
                                  <button  className={`btn ${activePortfolioBtn==='dash-1w' ? 'active' :''}`} id="dash-1w" onClick={((e)=>{handleActive(e.target.id)})}>1W</button>
                                  <button  className={`btn ${activePortfolioBtn==='dash-1m' ? 'active' :''}`} id="dash-1m" onClick={((e)=>{handleActive(e.target.id)})}>1M</button>
                                  <button  className={`btn ${activePortfolioBtn==='dash-1y' ? 'active' :''}`} id="dash-1y" onClick={((e)=>{handleActive(e.target.id)})}>1Y</button>
                                  <button  className={`btn ${activePortfolioBtn==='dash-all' ? 'active' :''}`} id="dash-all" onClick={((e)=>{handleActive(e.target.id)})}>ALL</button>
                                </div> //
                            </div>
                            // <div className="chart px-5 py-2">
                                <MyChart options={myChartOptions} data={chartData}></MyChart>
                            //</div> 
                            <div className="mt-3">
                                <div className="chart-content px-5 py-4">
                                <div className="d-flex flex-wrap" style={{gap:'20px',marginTop:'20px'}}>
                                          { chartContentList.map((item,index)=>{
                                              return(
                                                  <div className="pe-4 flex-fill" key={`financial-item-${index}`}>
                                                      <p className="mb-2">{item.label}</p>
                                                      <p className="m-0 text-dark">{item.value}</p>
                                                  </div>
                                              )})
                                          }
                                        </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-12 mt-5 col-lg-12 ">
                        <div className="asset-details__info">
                            <ul className="nav nav-tabs" id="browseTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button" role="tab" aria-controls="overview" aria-selected="true">OVERVIEW</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="financials-tab" data-bs-toggle="tab" data-bs-target="#financials" type="button" role="tab" aria-controls="financials" aria-selected="false">FINANCIALS</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="ratings-tab" data-bs-toggle="tab" data-bs-target="#ratings" type="button" role="tab" aria-controls="ratings" aria-selected="false">RATINGS</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="resources-tab" data-bs-toggle="tab" data-bs-target="#resources" type="button" role="tab" aria-controls="resources" aria-selected="false">RESOURCES</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="community-talk-tab" data-bs-toggle="tab" data-bs-target="#community-talk" type="button" role="tab" aria-controls="community-talk" aria-selected="false">COMMUNITY TALK</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="browseTabContent">
                                <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                  <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                     Overview
                                      </button>
                                  </h2>
                                  <div id="collapseOne" className="collapse show" aria-labelledby="headingOne">
                                      <div className="accordion-body">
                                        <h3>Overview</h3>
                                        <p>
	                                    {assetDescription.map((item, index) => {
				                return (<p>{item}</p>)
					    })}
	                                   
	                                    {/*
                                            {assetName} makes real estate investing, easy, hassle-free, and less risky for people by allowing people to invest for as low as AED 500 (approximately $140). 
                                            <br />
                                            Through {assetName}, users can reap all the benefits of direct real estate investments (i.e., by owning the properties) and reduce their risk by allocating their capital across a number of properties all through an award-winning digital platform.
					    */}
                                        </p>
                                      </div>
                                  </div>    
                                </div>
                                <div className="tab-pane fade" id="financials" role="tabpanel" aria-labelledby="financials-tab"> 
                                  <h2 className="accordion-header" id="headingTwo">
                                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                     Financials
                                      </button>
                                  </h2>
                                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo">
                                      <div className="accordion-body">
                                        <h2>Financials</h2>
                                        <div className="d-flex flex-wrap" style={{gap:'20px',marginTop:'20px'}}>
                                          { financialItemList.map((item,index)=>{
                                              return(
                                                  <div className="pe-4" key={`financial-item-${index}`}>
                                                      <p className="mb-2">{item.label}</p>
                                                      <p className="m-0 text-dark">{item.value}</p>
                                                  </div>
                                              )})
                                          }
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                <div className="tab-pane fade" id="ratings" role="tabpanel" aria-labelledby="ratings-tab"> 

                                  <h2 className="accordion-header" id="headingThree">
                                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                     Financials
                                      </button>
                                  </h2>
                                  <div id="collapseThree" className="collapse" aria-labelledby="headingThree">
                                      <div className="accordion-body">
                                          <h2> Work in Progress.....</h2>
                                      </div>
                                  </div>
                                
                                </div>
                                <div className="tab-pane fade" id="resources" role="tabpanel" aria-labelledby="resources-tab"> 
                                
                                  <h2 className="accordion-header" id="headingFour">
                                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                      Resources
                                      </button>
                                  </h2>
                                  <div id="collapseFour" className="collapse" aria-labelledby="headingFour">
                                      <div className="accordion-body">
                                        <h2> Work in Progress.....</h2>
                                      </div>
                                  </div>
                                
                                </div>
                                <div className="tab-pane fade" id="community-talk" role="tabpanel" aria-labelledby="community-talk-tab"> 

                                  <h2 className="accordion-header" id="headingFive">
                                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                      Community Talk
                                      </button>
                                  </h2>
                                  <div id="collapseFive" className="collapse" aria-labelledby="headingFive">
                                      <div className="accordion-body">
                                        <h2> Work in Progress.....</h2>
                                      </div>
                                  </div>
                                
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
        </>
    )
}

export default PhysicalAssetDetails;
