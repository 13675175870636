const formatCurrency = (price, currency) => {
    if (currency === "USD") {
        return "USD" + " $" + price
    }
    if (currency === "EURO") {
        return "EUR" + " €" + price
    }
    if (currency === "POUND") {
        return "GBP" + " £" + price
    }
    if (currency === "CZK") {
        return "CZK" + " Kč" + price
    }
    return "USD" + " $" + price;
};

export default formatCurrency
