import {  ThreeDots } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import NoDataFound from './NoDataFound';
import Currency from './Currency';
import formatDecimal from './../CustomFunctions/formatDecimal'
import { useState } from 'react';
import formatPrice from '../CustomFunctions/formatPrice';
import CustomTable from './Tables/CustomTable';
import { useMemo } from 'react';

const PerformanceTable = (props) =>{
    const {isloading,dashboard='',error} = useSelector(state => state.dashboard);
    let myAssets ={physical:{}, virtual:{}};
    if(dashboard!==''){
	for (var k in ((dashboard.assets.digital_land || {}).ethereum || {})){
            myAssets.virtual = dashboard.assets.digital_land.ethereum[k];
            break;
	}
	if (dashboard.assets.land_link) {
            myAssets.physical = dashboard.assets.land_link;
	}
	// console.log("local land_link");
	// console.log(dashboard.assets.land_link);

        var USDExchangeRate = dashboard.exchange_rates.ETH_TO_USD;

        var total = 0;

        let allAssets = [];

	//fill up the allAssets list with the various assets:
        //firs the virtual assets:
	if (myAssets.virtual.erc721){
            myAssets.virtual.erc721.forEach((each)=>{
                let value = each.performance.current_price * USDExchangeRate
                total+=value;
                allAssets.push(each);
            });
        }
 
	Object.entries(myAssets.physical).forEach(([platform, props]) => {
	    Object.entries(props).forEach(([propName, propObj]) => {
		let value = propObj.price;
		if (propObj.amount > 0){
		    value = value * propObj.amount;
		}
                
		if (value && value == value) {
      	            total+=value;
		}
	        allAssets.push(propObj);
            });
	});

        props.handleTotal(total);

        var myPlot;
        myPlot = allAssets.map(each=>{
            const {name , image , token_symbol ,performance:{purchase_price,current_price},currency,price} = each;
            // console.log("YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY");
	    // console.log(each);
	    var url = each['url'];
	    if (url == undefined) {
	        url = each['external_url'];
	    }
            let floor = typeof(current_price)=='number' ? formatDecimal(current_price) : 'N/A';

            let gainLoss = typeof(current_price)=='number' ? formatDecimal(current_price - purchase_price) : 'N/A';

            let usdValue = price ? price : typeof(current_price)=='number' ? formatPrice(formatDecimal(USDExchangeRate * current_price)) : 'N/A';

            return {
                name:{name,image,token_symbol, url},
                price:{currency,price :price ? price : purchase_price},
                floor: {currency,floor},
                gainLoss: {currency,gainLoss},
                usdEqv: usdValue
            } 
        })
    }
    const columns = useMemo(
    () => [
        {
        Header: 'Name',
        accessor: 'name',
        disableSortBy: true,
        Cell:({row})=>{
            const {name,image,token_symbol,url} = row.original.name;
            const [imageError,setImageError] = useState(false);

            const handleImageError = () =>{
                setImageError(true);
            }
            // console.log("eeeeeeeeeeeeeeeeeeeeeee")
	    // console.log(url)
            return(
                <div className="icon-wrapper" onClick={()=>{window.open(url)}}>
		
                    <div className={`image-wrapper ${imageError ? 'no-image' : ''}`}>
                         { !imageError && <img src={image} alt="asset" onError={handleImageError} className='img-fluid' loading='lazy'/>}
                         { imageError && <i class="fa-solid fa-mountain-sun" style={{fontSize:'2rem',color:'#8FF129' }}></i>}
                    </div>
                    <div className="d-inline-block ms-3">
                        <p className=" mb-0 custom-tooltip" tooltip-data={name}><span className='asset-name text-nowrap'>
                        {name}</span> </p>
                        <p className="mb-0 asset-subname">{token_symbol}</p>
                    </div>
		 
                </div>
            )
        }
        },
        {
        Header: 'Location',
        accessor: 'location',
        disableSortBy: true,
        Cell:({row})=>{
            // console.log(row.original)
            const {location} = row.original;
            return(
                <div className="icon-wrapper">
                    <div className="d-inline-block ms-3">
                        <p className=" mb-0 custom-tooltip" tooltip-data={location}>
                            <span className='asset-name text-nowrap'>{location ? location:'-'}</span> </p>
                        <p className="mb-0 asset-subname">{location? location:'-'}</p>
                    </div>
		 
                </div>
            )
        } 
        },
        {
        Header: 'Acquisition Price',
        accessor: 'price',
        disableSortBy: true,
        Cell:({row})=>{
            const {currency,price} = row.original.price
            return(
                <span><Currency name={currency}/><span className='ms-1'>{price}</span></span>
            )
        }
        },
        {
        Header:'Floor',
        accessor:'floor',
        disableSortBy: true,
        Cell:({row})=>{
            const {currency,floor} = row.original.floor
            return(
                <span><Currency name={currency}/><span className='ms-1'>{floor}</span></span>
            )
        }
        },
        {
        Header:'Gain / Loss',
        accessor:'gainLoss',
        disableSortBy: true,
        Cell:({row})=>{
            const {currency,gainLoss} = row.original.gainLoss
            return(
                <span><Currency name={currency}/><span className='ms-1'>{gainLoss}</span></span>
            )
        }
        },
        {
        Header:'USD Eqv',
        accessor:'usdEqv',
        Cell:({row})=>{
            const usdEqv = row.original.usdEqv
            return(
                <span><Currency name={'USD'} /><span className='ms-1'>{usdEqv}</span></span>
            )
        }
        },
    ],
    []
    )
	
    return(
        <>
            { error!==undefined && myAssets.virtual==undefined && error && !isloading ? (
                    <NoDataFound style={{maxWidth:'403px'}}/> ): null
            }
            { myAssets.virtual!==undefined && !isloading && <div className="table-wrapper performance-table">
	        { myPlot && myPlot.length > 0 && 
                <CustomTable data={{columns,myPlot,sortData:true}} /> 
		}
	        { myPlot && myPlot.length == 0 && 
                <div>No assets added</div> 
		}


                {/* <table className=" table table-responsive table-borderless table-hover">
                    <thead>
                    <tr>
                        <th className='bg-white'>Name</th>
                        <th className='bg-white text-center'>Acquisition Price</th>
                        <th className='bg-white text-center'>Floor</th>
                        <th className='bg-white text-center'>Gain / Loss</th>
                        <th className='bg-white text-center'>USD Eqv.</th>
                    </tr>
                    </thead>
                    <tbody>
                    
                    {  isloading!==true &&  myAssets!=={} ? myAssets.virtual.erc721.map((item,index)=>{
                        return  <PerformanceItem handleTooltip={handleTooltip} data={item} ExchangeRate={USDExchangeRate} key={index}></PerformanceItem> 
                    }) : null}

                    { isloading!==true &&  myAssets!=={} && Object.entries(myAssets.physical.lofty).map((item,index)=>{
                        return <PerformanceItem handleTooltip={handleTooltip} data={item[1]} ExchangeRate={USDExchangeRate} key={index}></PerformanceItem>
                    })}

                    { isloading!==true &&  myAssets!=={} && Object.entries(myAssets.physical.realty_mogul).map((item,index)=>{
                        return <PerformanceItem handleTooltip={handleTooltip} data={item[1]} ExchangeRate={USDExchangeRate} key={index}></PerformanceItem>
                    })}
                    {tooltip && <ReactTooltip effect="solid"/>}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={4}>Total </th>
                            <th className='text-center'><Currency name={'USD'}/> {formatPrice(formatDecimal(total))}</th>
                        </tr>
                    </tfoot>
                </table> */}
            </div> }

            { isloading &&
                <div className="loader" style={{position:'relative',inset:'0',transform:'none'}}><ThreeDots color="#8FF129"></ThreeDots></div> 
            }
        </>
    );
}

export default PerformanceTable;
