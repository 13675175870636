import * as Yup from 'yup';

const country=[
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas, The",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burma",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia",
    "Cuba",
    "Curacao",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territories",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (UAE)",
    "United Kingdom (UK)",
    "United States of America (USA)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
    ];
    const services_offered=[
      'Crowdfunding',
      'Tokenization (Blockchain)',
      'Fractional Ownership'
    ]
    const geographies=[
      'North America',
      'South America',
      'Eastern Europe',
      'Western Europe',
      'Northern Europe',
      'Southern Europe',
      'Northern Africa',
      'Central Africa',
      'Eastern Africa',
      'Western Africa',
      'Southern Africa',
      'Western Asia (Middle East, GCC)',
      'Western Asia (Turkey, Georgia, Armenia, Azerbaijan)',
      'Central Asia',
      'Northern Asia (Russia)',
      'Southern Asia',
      'Southern-East Asia',
      'East Asia',
      'Oceania'
    ];
    const is_crowdfunding=['Yes','No'];
    const investment_type=['Equity','Debt','REIT'];
    
    const property_types=[
      'Commercial',
      'Residential',
      'Multi-family',
      'Single-family',
      'Retail',
      'Industrial',
      'Hotel',
      'Office',
      'Land',
      'Farms',
      'Infrastructure'
    ];
    
    const minimum_investment=[
      '$50 or less',
      '$51 to $150',
      '$151 to $500',
      '$501 to $999',
      '$1000 to $2499',
      '$2500 to $4999',
      '$5000 to $9999',
      '$10000 to $19999',
      '$20000 to $34999',
      '$35000 or more'
    ]
    
    const investor_target=['Accredited Investors','Non-Accredited Investors'];
    
    const deals_raised=[
      'Less than 9',
      '10 to 19',
      '20 to 49',
      '50 to 99',
      '100 to 149',
      '150 to 199',
      '200 to 249',
      '250 to 299',
      '300 to 399',
      '400 to 499',
      '500 to 749',
      '750 to 999',
      '1000 to 1999',
      'more than 2000'
    ];
    
    const deals_exited=[
      'Less than 9',
      '10 to 19',
      '20 to 49',
      '50 to 99',
      '100 to 149',
      '150 to 199',
      '200 to 249',
      '250 to 299',
      '300 to 399',
      '400 to 499',
      '500 to 749',
      '750 to 999',
      '1000 to 1999',
      'more than 2000'
    ];
    
    const has_secondary_market=['Yes','No'];
    const secondary_market_open_time=['24/7','Partial'];
    const has_property_management=[
      'Yes',
      'No',
      'Property Management provided by a trusted third party',
      'Not necessary'
    ];
    
    const has_expert_real_estate_investors=['Yes','No'];
    
    const does_deal_review=['Yes','No'];
    
    const is_regulated=['Yes','No','In the process'];
    
    const list_on_araa=[
      'Yes',
      'No'
    ];
    
    const qlookup = {
      'q1': 'launch_year',
      'q2': 'country',
      'q3': 'hq_city',
      'q4': 'name',
      'q5': 'url',
      'q6': 'services_offered',
      'q7': 'blockchains',
      'q8': 'geographies',
      'q9': 'is_crowdfunding',
      'q10': 'investment_type',
      'q11': 'property_types',
      'q12': 'minimum_investment',
      'q13': 'investor_target',
      'q14': 'total_funding',
      'q15': 'deals_raised',
      'q16': 'deals_exited',
      'q17': 'has_secondary_market',
      'q18': 'secondary_market_open_time',
      'q19': 'has_property_management',
      'q20': 'has_expert_real_estate_investors',
      'q21': 'does_deal_review',
      'q22': 'is_regulated',
      'q23': 'regulated_by_country',
      'q24': 'regulated_by_agency',
      'q25': 'list_on_araa',
      'q26': 'email_contact'
    }

    const validation=Yup.object().shape({
      launch_year:Yup.string().required('Please select a date.'),
      country:Yup.string().required('Please select a country'),
      hq_city:Yup.string().min(1,'Too short').required('Please enter your platform name.'),
      name:Yup.string().min(2,'Too short').required('Please enter your platform name.'),
      url:Yup.string().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,'Enter correct url!').required('Please enter website'),
      services_offered:Yup.array().min(1,'Please select services you offer').required('Mandatory field'),
      geographies:Yup.array().min(1,'Please select geographic locations').required('Mandatory fiel'),
      is_crowdfunding:Yup.string().min(1,'Please select an option').required('Please select an option'),
      investment_type:Yup.array().min(1,'Please select investment types').required('Mandatory field'),
      property_types:Yup.array().min(1,'Please select property types').required('Mandatory field'),
      minimum_investment:Yup.string().min(1,'Please select an option').required('Please select an option'),
      investor_target:Yup.array().min(1,'Please select types of investors').required('Mandatory field'),
      total_funding:Yup.number().min(1,'Please enter total funding amount').required('Please select an option').typeError("Must be a number"),
      deals_raised:Yup.string().min(1,'Please select an option').required('Please select an option'),
      deals_exited:Yup.string().min(1,'Please select an option').required('Please select an option'),
      has_secondary_market:Yup.string().min(1,'Please select an option').required('Please select an option'),
      secondary_market_open_time:Yup.string().min(1,'Please select an option').required('Please select an option'),
      has_property_management:Yup.string().min(1,'Please select an option').required('Please select an option'),
     
      has_expert_real_estate_investors:Yup.string().min(1,'Please select an option').required('Please select an option'),
      does_deal_review:Yup.string().min(1,'Please select an option').required('Please select an option'),
      is_regulated:Yup.string().min(1,'Please select an option').required('Please select an option'),
     
      list_on_araa:Yup.string().min(1,'Please select an option').required('Please select an option'),
      email_contact:Yup.string().email("Email must be valid").required("Email is required"),
    });

export {country,services_offered,geographies,is_crowdfunding,investment_type,property_types,minimum_investment,investor_target,deals_raised,deals_exited,has_secondary_market,secondary_market_open_time,has_property_management,has_expert_real_estate_investors,does_deal_review,is_regulated,list_on_araa,validation, qlookup};
