const addAssetFormInputs = [{
    label:'Asset Name',
    id:'add-asset-name',
    type:'text',
    placeholder:'Enter Asset Name',
    value:'name'
},
{
    label:'Asset Location',
    id:'add-asset-location',
    type:'text',
    placeholder:'Enter Location',
    value:'location'
},
{
    label:'Platform',
    id:'add-asset-platform',
    type:'text',
    placeholder:'Enter Platform',
    value:'platform'
},
{
    label:'Currency',
    id:'add-asset-currency',
    type:'text',
    placeholder:'Enter Currency',
    value:'currency'
},
{
    label:'Price / Share Or Price / Token',
    id:'add-asset-price',
    type:'number',
    placeholder:'Enter Price / Share Or Price / Token',
    value:'price_per_share'
},
{
    label:'Number of Total Shares',
    id:'add-asset-total-shares',
    type:'number',
    placeholder:'Enter Total Shares',
    value:'share_number'
},
{
    label:'Date Purchased',
    id:'add-asset-date',
    type:'date',
    placeholder:'Enter Date Purchased',
    value:'date_purchased'
},
{
    label:'Asset URL',
    id:'add-asset-url',
    type:'url',
    placeholder:'Enter Asset URL',
    value:'url'
},
{
    label:'Asset Image URL',
    id:'add-image-url',
    type:'url',
    placeholder:'Enter Image URL',
    value:'image_url'
},
]

export default addAssetFormInputs;
