import { useState } from "react"
import showToast from "../../CustomFunctions/showToast";
import inviteImage from '../../images/invite-primary.png'

const Invite = () => {
  const [values, setValues] = useState({
    email: ''
  });

  const { email } = values;

  const formInputs = [{
    label: 'Email address',
    type: 'email',
    id: 'invite-friend-email',
    placeholder: 'Enter Email',
    name: 'email'
  }];

  const handleInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = () => {
    email !== '' ? showToast(`${email} Invited Successfully !`, 'info') : showToast('Enter Email', 'error')
  }

  const handleReset = () => {
    setValues({ email: '' })
  }
  return (
    <div className="modal fade" id="invite-friends" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content p-4">
          <div className="modal-header border-0">
            <h5 className="modal-title w-100 text-center" id="staticBackdropLabel">Invite Friends</h5>
          </div>
          <div className="modal-body">
            <h4 className="text-center">Join Araa , The Global Real Estate Maretplace</h4>
            <div className="d-flex justify-content-center">
              <img src={inviteImage} alt="invite" style={{ maxWidth: '200px' }} className="img-fluid" loading="lazy"/>
            </div>
            <form action="">
              {formInputs.map((input, index) => {
                return (
                  <div className="mb-3" key={index}>
                    <label htmlFor={input.id} className="form-label">{input.label}</label>
                    <input type={input.type} className="form-control" id={input.id} placeholder={input.placeholder ? input.placeholder : ''} value={email} name={input.name} onChange={((e) => handleInput(e))} />
                  </div>
                )
              })}
            </form>
          </div>
          <div className="modal-footer border-0">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleReset}>Close</button>
            <button type="button" className="btn btn-primary" onClick={handleSubmit}>Invite</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Invite;