import { useDispatch , useSelector} from "react-redux";
import { useNavigate } from 'react-router';
import { useEffect, useState } from "react";
import { Link, NavLink } from 'react-router-dom';

import axios from 'axios';
import { Helmet } from "react-helmet-async";

const LoadUser = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const fetchPlatformData = () => {
      axios(
         {url: process.env.REACT_APP_BASE_API+'/get_user',
          method: "get",
          headers:{
            'X-API-KEY':process.env.REACT_APP_API_KEY,
          }
        }).then((res) => {
	    // console.log("=====+++++++++++++++++++++++++++++++==========ss");
	    // console.log(res);
	    
            sessionStorage.setItem('userData', JSON.stringify(res.data));

	    if (res.data['is_form_submitted'] == false && res.data['is_platform_user'] == true) 
	        navigate("/form");
	    else
	        navigate('/');
        })
        .catch((err) => {
          console.error(err);
        });
    };
    useEffect(() => {
      fetchPlatformData();
    }, []);


    const {isloading ,isuserloggedin,user,error} = useSelector(state=> state.auth);
    return(
      <>
      <Helmet>
        <title>Araa.land - Your Global Real Estate Marketplace</title>
        <meta name="description" content="Find Any global Real Estate crowdfunding & tokenization assets from one convenient place" />
        <link rel="canonical" href="https://app.araa.land/load_user" />
      </Helmet>
        <div className="load_user">

        </div>
        </>
    )
}

export default LoadUser;
