import { Link } from "react-router-dom";

const imageURLs={
        arrived:"/images/icons/website/arrived.ico",
        brickx:"/images/icons/website/brickx.png",
        crowdrealestate:"/images/icons/website/crowdrealestate.png",
        crowdstreet:"/images/icons/website/crowdstreet.png",
        driftwoodcapital:"/images/icons/website/driftwoodcapital.png",
        estateguru:"/images/icons/website/estateguru.png",
        ethis:'/images/icons/website/ethis.ico',
        farmtogether:"/images/icons/website/farmtogether.png",
        finloo:"/images/icons/website/finloo.png",
        fundimmo:"/images/icons/website/fundimmo.png",
        fundthatflip:"/images/icons/website/fundthatflip.png",
        housers:"/images/icons/website/housers.png",
        lande:"/images/icons/website/lande.webp",
        landex:"/images/icons/website/landex.png",
        realt:"/images/icons/website/realt.png",
        realtymogul:"/images/icons/website/realtymogul.png",
        redswan:"/images/icons/website/redswan.jpg",
        rendity:"/images/icons/website/rendity.png",
        ruebushgroup:"/images/icons/website/ruebushgroup.png",
        uown:"/images/icons/website/uown.png",
        upstone:"/images/icons/website/upstone.png",
        upvest:"/images/icons/website/upvest.png",
        urbanitae:"/images/icons/website/urbanitae.png",
        venturecrowd:"/images/icons/website/venturecrowd.ico"
}

const CrowdFundingItem = (props) => { 
    // const {platformid,platformicon,platformname,pricechange, location,licensing,equity,debt,reit,tokenized,fiat,crypto,funding,fundvalue} = props.data;
    let platformname=props.data.form_data?.collection;
    if (!platformname) {
        if(props.data?.collection) {
            platformname=props.data?.collection;
	} else if (props.data?.form_data.name) {
            platformname=props.data?.form_data.name;
        } else if (props.form_data?.name) {
            platformname=props.data?.name;
	}
    }

    let {platformid,platformicon,pricechange,geographies,investment_type,deals_exited,property_types,services_offered,url,total_funding,deals_raised}=props.data.form_data;
    if(deals_exited && deals_exited.includes('Less')){
        deals_exited=deals_exited.split(' ').at(-1);
    }else if(deals_exited){
        deals_exited=deals_exited.split(' ').at(-1);
    }
    if(deals_raised && deals_raised.includes('Less')){
        deals_raised=deals_raised.split(' ').at(-1);
    }else if(deals_raised){
        deals_raised=deals_raised.split(' ').at(-1);
    }
    try{
        total_funding=Number(total_funding)
        if(isNaN(total_funding)){
            total_funding='N/A'
        }
    }catch{
        total_funding='N/A'
    }
    var logo_src = imageURLs[platformname]
    if (!logo_src) {
        if (props.data.form_data.logo) {
	    logo_src = props.data.form_data.logo;
	} else {
	    logo_src = '/images/icons/website/default_logo.jpg';
	}
    }

    if (url && !url.startsWith("https://") && !url.startsWith("http://")){
        url = "http://"+url;
    }
    
    return (
        <tr className="text-center">
            <td className="text-start"  style={{position:"sticky",left:'0px'}}>
            <a href={url ? url :''} target="_blank" rel="noopener noreferer">
            <div className="icon-wrapper">
                    <div className="image-wrapper">
                        <img src={logo_src} alt="icon" className='img-fluid' loading='lazy'/>
                        
                    </div>
                    <div className="d-inline-block ms-3 "><p className="asset-name">{platformname}</p>
                    {/* <p className="mb-0 price-change">{pricechange}</p> */}
                    </div>
                </div>
            </a>
            </td>
                <td>{geographies ? geographies.join(','):'N/A'}</td>
                <td>{investment_type ? (investment_type.includes('Equity')?"Yes":"No"):"N/A"}</td>
                <td>{investment_type ? (investment_type.includes('Debt')?"Yes":"No"):"N/A"}</td>
                <td>{investment_type ? (investment_type.includes('REIT')?"Yes":"No"):"N/A"}</td>
                <td>{total_funding ?  new Intl.NumberFormat('en-US').format(total_funding) : 'N/A'}</td>
                <td>{deals_raised ? new Intl.NumberFormat('en-US').format(deals_raised) : 'N/A'}</td>
                <td>{"-"}</td>
                <td>{deals_exited ? new Intl.NumberFormat('en-US').format(deals_exited) : 'N/A'}</td>
                <td>{property_types ? (property_types.includes('Residential')?"Yes":"No"):"N/A"}</td>
                <td>{property_types ? (property_types.includes('Commercial')?"Yes":"No"):"N/A"}</td>
                <td>{property_types ? (property_types.includes('Land')?"Yes":"No"):"N/A"}</td>
            <td>{services_offered ? (services_offered.includes('Tokenization (Blockchain)')?"Yes":"No"):"N/A"}</td>
        </tr> 
    )
}

const CrowdFundingTable = (props) =>{
    
    return(
        <div className="table-wrapper table-head-colored m-0 p-0" style={{
        backgroundColor: 'rgba(222, 223, 226, 0.35)',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        border: '1px solid #DEDFE2',
        borderBottom: 'none'}}>
            <table className="table table-responsive table-borderless table-hover" style={{backgroundColor:'rgb(255,255,255,1)'}}>
                <thead>
                    <tr className="text-center">
                        <th className="text-start position-sticky" style={{left:'0px'}} role='button' onClick={()=>props.setColumnSort({isSortedDesc:!props.columnSort.isSortedDesc})}>Platform&nbsp;&nbsp;&nbsp;
                            { props.columnSort.isSortedDesc
                                ? <i class="fa-solid fa-caret-down"></i>
                                : <i class="fa-solid fa-caret-up"></i>
                            }</th>
                        <th colSpan={4}>Types of Investments</th>
                        <th colSpan={4}>Historical Information on Deals and $</th>
                        <th colSpan={3}>Type of Properties</th>
                        <th>Technology</th>
                    </tr>
                    <tr>
                            <th className="position-sticky" style={{left:'0px'}}></th>
                            <th>Location</th>
                            <th>Equity</th>
                            <th>Loan/Debt</th>
                            <th>REIT</th>
                            <th>USD Funded</th>
                            <th># Transactions</th>
                            <th>USD Exits</th>
                            <th># of Exits</th>
                            <th>Residential</th>
                            <th>Commerical</th>
                            <th>Land</th>
                            <th>Tokenization</th>
                    </tr>
                </thead>
                <tbody>
                    { props.data && props.data.length > 0 && props.data.map((item,index)=>{
                        if(item.form_data)
                        return  <CrowdFundingItem key={`crowdFundingItem${index}`} data={item}></CrowdFundingItem>;
                    })}
                    {props.data.length===0 &&(<tr><td colSpan={13}>No Records Found</td></tr>)}
                </tbody>
            </table>
        </div>
    );
}

export default CrowdFundingTable;
