import Scrollspy from 'react-scrollspy';
import Select from 'react-select';
import ReactPaginate from 'react-paginate'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchThunk } from '../Reduxstore/thunks';
import { ThreeDots } from 'react-loader-spinner';
import NoDataFound from './NoDataFound';
import WorkInProgress from './WorkInProgress';
import { platformSearchOptions, irrPercentOptions, aprPercentOptions, propertyTypeOptions, cocPercentOptions, priceOptions, trendingCitiesOptions, countriesOptions, blockchainOptions, currencyOptions, virtualWorldOptions } from '../CustomFunctions/filterOptions';
// import BrowseVirtualLand from './Cards/BrowseVirtualLand';
import PhysicalProperty from './Cards/PhysicalProperty'
import { useNavigate } from 'react-router';
//import BrowseCrowdFunding from './Cards/BrowseCrowdFunding';
import CheckboxesTags from './CustomCheckbox'
import { Helmet } from 'react-helmet-async';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const initialActiveTab = 'crowdfunding';
const initialValues = { type: 'Digital Land', limit: 60, page: 0 };
const initialcrowdFValues = { type: 'Land Link', limit: 60, page: 0 ,"target_irr": irrPercentOptions[0],"target_coc": cocPercentOptions[0],"target_apr": aprPercentOptions[0],"city": [],"country":countriesOptions[0] ,"property_type":propertyTypeOptions[0] ,"platform":platformSearchOptions[0] ,"price": priceOptions[0],query:''}
const intitalallValues = { limit: 60, page: 0 };
let debounce;
let debounce2;
const Browse = () => {
    const navigate = useNavigate();
    const [sidebar, setSidebar] = useState(false);
    const [values, setValues] = useState({ ...initialValues });
    const [formKey, setFormKey] = useState({});
    const [crowdFValues, setcrowdFValues] = useState({ ...initialcrowdFValues });
    const [allValues, setallValues] = useState({ ...intitalallValues });
    const [activeTab, setActiveTab] = useState(initialActiveTab);
    const [showFilterInSidebar, setShowFilterInSidebar] = useState(false);
    const { isloading, search: { results = '', total = '' }, error } = useSelector(state => state.search);

    // setallValues['type'] = 'All'
    // values['type'] = 'Digital Land';
    // values['limit'] = '50'
    // crowdFValues['type'] = 'Land Link';
    // crowdFValues['limit'] = '50';
    // allValues['limit'] = '50';
    // allValues['page'] = '0';
    //allValues['collection'] = 'lofty';
   
    const handleChange = key => event => {
        setValues({ ...values, [key]: event.target.value })
    }
    const handleCrowdFundChange = key => event => {
        setcrowdFValues({ ...crowdFValues, [key]: event.target.value })
    }
    // const handleAllChange = key => event => {
    //     setallValues({ ...allValues, [key]: event.target.value })
    // }

    const handleCrowdFToggler = (elem) => {
        if (elem.target.checked) {
            setcrowdFValues({ ...crowdFValues, 'criteria': [...crowdFValues['criteria'], elem.target.value] })
        } else {
            setcrowdFValues({
                ...crowdFValues, 'criteria': [...crowdFValues['criteria']].filter(function (btn) {
                    return btn !== elem.target.value
                })
            });
        }
    }

    const virtualLandOptions = [
        { value: 'filter-item-1', label: 'Filter' },
        { value: 'filter-item-2', label: 'Filter Item 2' },
        { value: 'filter-item-3', label: 'Filter Item 3' },
        { value: 'filter-item-4', label: 'Filter Item 4' },
    ];

    // const PhysicalAssetsData = [
    //     {
    //         title: "Embassy Business Hub",
    //         subtitle: "Yelahanka , Bangalore",
    //         image: "https://propmedia2.propertyshare.in/website/property/M2psRzRmUUZsc2F0SFhUYkxoOHRLUT09/media-v2/images/listingbanner/540x420/1654068898-embassy-business-hub-bangalore-1.jpg",
    //         area: "1,74,497 SF",
    //         price: "₹7,450",
    //         yieldpercent: "10%",
    //         irr: "18.8%"
    //     },
    //     {
    //         title: "Two Inverkip Street",
    //         subtitle: "Greenock , United Kingdom",
    //         image: "https://propmedia2.propertyshare.in/website/property/Ly9hYVNOR3NiUS9YM0ZBb09VelZIZz09/media-v2/images/listingbanner/540x420/1646040960-greenock-uk-united-kingdom-1.jpg",
    //         area: "41,583 SF",
    //         price: "£106",
    //     },
    //     {
    //         title: "Embassy Tech Square",
    //         subtitle: "Outer Ring Road , Bangalore",
    //         image: "https://propmedia1.propertyshare.in/website/property/d3Joby9VTnlncndkZit1ZlAxQ2ZMdz09/media-v2/images/listingbanner/540x420/1638176089-embassy-tech-square-bangalore-1.jpg",
    //         area: "84,512 SF",
    //         yieldpercent: "8.62%",
    //         irr: "15.9%"
    //     },
    //     {
    //         title: "Embassy Tech Square",
    //         subtitle: "Outer Ring Road , Bangalore",
    //         image: "https://propmedia1.propertyshare.in/website/property/d3Joby9VTnlncndkZit1ZlAxQ2ZMdz09/media-v2/images/listingbanner/540x420/1638176089-embassy-tech-square-bangalore-1.jpg",
    //         area: "84,512 SF",
    //         price: "₹13,253",
    //         yieldpercent: "8.62%",
    //         irr: "15.9%"
    //     },
    //     {
    //         title: "Two Inverkip Street",
    //         subtitle: "Greenock , United Kingdom",
    //         image: "https://propmedia2.propertyshare.in/website/property/Ly9hYVNOR3NiUS9YM0ZBb09VelZIZz09/media-v2/images/listingbanner/540x420/1646040960-greenock-uk-united-kingdom-1.jpg",
    //         area: "41,583 SF",
    //         price: "£106",
    //         yieldpercent: "8.02%",
    //         irr: "15.7%"
    //     },
    //     {
    //         title: "Embassy Business Hub",
    //         subtitle: "Yelahanka , Bangalore",
    //         image: "https://propmedia2.propertyshare.in/website/property/M2psRzRmUUZsc2F0SFhUYkxoOHRLUT09/media-v2/images/listingbanner/540x420/1654068898-embassy-business-hub-bangalore-1.jpg",
    //         area: "1,74,497 SF",
    //         price: "₹7,450",
    //         yieldpercent: "10%",
    //         irr: "18.8%"
    //     },

    // ];

    const dispatch = useDispatch();

    const fetchAssetsAll = () => {
        let reset_val = Date.now();
        setFormKey({ unique_key: reset_val });
        setallValues({ ...intitalallValues });
        // dispatch(SearchThunk());
    };

    const fetchAssetsVirtual = () => {
        let reset_val = Date.now();
        setFormKey({ unique_key: reset_val });
        setValues({ ...initialValues });
        // dispatch(SearchThunk());
    };

    const fetchAssetsCrowdfunding = () => {
        let reset_val = Date.now();
        setFormKey({ unique_key: reset_val });
        setcrowdFValues({ ...initialcrowdFValues });
        // dispatch(SearchThunk());
    };

    const searchData = () => {
        if (document.getElementById("virtual-land-tab").attributes['aria-selected'].value === 'true') {
            const str = Object.keys(values).map(key => `${key}=${values[key]}`).join("&");
            dispatch(SearchThunk(str));
        }
    }

    const searchCrowdFundData = () => {
        if (document.getElementById("crowdfunding-tab").attributes['aria-selected'].value === 'true') {
            let str = "";
            let loop=Object.keys(crowdFValues).filter(e=>{
                switch(typeof crowdFValues[e]){
                    case "number": if(crowdFValues[e]>0 || e==="page" ){
                        return e
                    }
                        break;
                    case "string": if(crowdFValues[e].length > 0){
                        return e
                    }
                        break;
                    case "object": if(Array.isArray(crowdFValues[e])){
                        return crowdFValues[e].length > 0
                    }else {
                        return crowdFValues[e].value.length > 0
                    }
                        break;
                    return null
                }
        })  
            str= loop.map(key => {
                 switch(key){
                    case "type" : 
                    case "page":
                    case 'query':
                    case "limit" : return `${key}=${crowdFValues[key]}`
                        break;
                    case "city" : return `${key}=${"in:"+crowdFValues[key].map(e=>e.value)}`
                        break;
                    default: return `${key}=${crowdFValues[key].value}`
                        break;
                }
            //   return `${key}=${(key==='city' ? "in:" :"")+crowdFValues[key].value}`
            }).join("&");
            clearTimeout(debounce)
            debounce=setTimeout(()=>{
                dispatch(SearchThunk(str));
            },600)
        }
    }

    // const searchAllData = () => {
    //     if (document.getElementById("browse-all-tab").attributes['aria-selected'].value === 'true') {
    //         const str = Object.keys(allValues).map(key => `${key}=${allValues[key]}`).join("&");
    //         dispatch(SearchThunk(str));
    //     }
    // }

    useEffect(() => {
        searchData();
    }, [values])

    useEffect(() => {
        searchCrowdFundData();
    }, [crowdFValues])

    // useEffect(() => {
    //     searchAllData();
    // }, [allValues])

    useEffect(() => {
        const lhash = window.location.hash;
        if (lhash === "#virtual-assets") {
            document.getElementById("virtual-land-tab").click();
            window.location.replace("#");
            // slice off the remaining '#' in HTML5:    
            if (typeof window.history.replaceState == 'function') {
                window.history.replaceState({}, '', window.location.href.slice(0, -1));
            }
        } else if (lhash === "#physical-assets") {
            document.getElementById("crowdfunding-tab").click();
            window.location.replace("#");
            // slice off the remaining '#' in HTML5:    
            if (typeof window.history.replaceState == 'function') {
                window.history.replaceState({}, '', window.location.href.slice(0, -1));
            }
        }
    }, []);

    useEffect(()=>{
        window.addEventListener('resize',closeSidebar)         

        return ()=>  window.removeEventListener('resize',closeSidebar)
    },[sidebar])

    function closeSidebar(){
        if(window.innerWidth > 991 && sidebar){
            clearTimeout(debounce2)
            debounce2=setTimeout(()=>setSidebar(false),100)
        }
    }
    
    useEffect(() => {
        switch (activeTab) {
            case 'browse-all': fetchAssetsAll();
                break;
            case "crowdfunding": fetchAssetsCrowdfunding()
                break;
            case "virtual-land": fetchAssetsVirtual()
                break;
            default: ;
                break;
        }
    }, [activeTab])

    const handlePageClick = (event) => {
        switch (activeTab) {
            case "browse-all": setallValues({ ...allValues, limit: 60, page: event.selected })
                break;
            case "crowdfunding": setcrowdFValues(({ ...crowdFValues, limit: 60, page: event.selected, type: 'Land Link' }))
                break;
            case 'virtual-land': setValues(({ ...values, limit: 60, page: event.selected, type: 'Digital Land' }))
                break;
            // case 'hybrid-link':
            //     break;
            default: setallValues({ ...allValues, limit: 60, page: event.selected })
                break
        }
    };
    const handleCityCheckbox = (list) => {
        const finalList = list.map((e) => e);
        setcrowdFValues({ ...crowdFValues, city: [...finalList] })
    }

    const handleCrowdReset=()=>{
        setcrowdFValues(JSON.parse(JSON.stringify(initialcrowdFValues)))
    }
    const handleSidebar = (e) => {
        setSidebar(!sidebar);
        e.preventDefault();
    }

    return (
        <>
        <Helmet>
        <title>Browse | Araa </title>
        <meta name="description" content="All Real Estate in the Crowdfunding, Tokenization and property fractional ownership space in addition to Virtual Lands and properties found in one platform: Browse, Find, Save and Monitor all Digitized Real Estate including Tokenized Real Estate from Lofty, Realtymogul, Crowdstreet, Redswan, Roofstock, Equity Multiple, SmartCrowd, Stake, Farmtogether, Acetrader, Fundrise, Rendity, Upstone, Finloo, Fundimmo, EstateGuru, Brickx etc.." />
        <meta property="og:title" content="Browse | Araa " />
        <meta property="og:description" content="All Real Estate in the Crowdfunding, Tokenization and property fractional ownership space in addition to Virtual Lands and properties found in one platform: Browse, Find, Save and Monitor all Digitized Real Estate including Tokenized Real Estate from Lofty, Realtymogul, Crowdstreet, Redswan, Roofstock, Equity Multiple, SmartCrowd, Stake, Farmtogether, Acetrader, Fundrise, Rendity, Upstone, Finloo, Fundimmo, EstateGuru, Brickx etc.." />
        <meta property="og:image" content="https://static.wixstatic.com/media/339d55_6a24db0a3d844c06950a55881e78f481%7Emv2.jpg/v1/fit/w_2500,h_1330,al_c/339d55_6a24db0a3d844c06950a55881e78f481%7Emv2.jpg" />
        <meta property="og:image:width" content="2500" />
        <meta property="og:image:height" content="1330" />
        <meta property="og:url" content="https://app.araa.land/browse" />
        <meta property="og:site_name" content="Araa " />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Browse | Araa " />
        <meta name="twitter:description" content="All Real Estate in the Crowdfunding, Tokenization and property fractional ownership space in addition to Virtual Lands and properties found in one platform: Browse, Find, Save and Monitor all Digitized Real Estate including Tokenized Real Estate from Lofty, Realtymogul, Crowdstreet, Redswan, Roofstock, Equity Multiple, SmartCrowd, Stake, Farmtogether, Acetrader, Fundrise, Rendity, Upstone, Finloo, Fundimmo, EstateGuru, Brickx etc.." />
        <meta name="twitter:image" content="https://static.wixstatic.com/media/339d55_6a24db0a3d844c06950a55881e78f481%7Emv2.jpg/v1/fit/w_2500,h_1330,al_c/339d55_6a24db0a3d844c06950a55881e78f481%7Emv2.jpg" />
        <link rel="canonical" href="https://app.arra.land/browse" />
        </Helmet>
        <div className="main-content browse">
            <div className="main-content__left ">
                <Scrollspy items={[]} className={`list-unstyled sidebar ${sidebar && 'active'}`} currentClassName={'active'}>
                    {/* <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#listing"> <span className="d-flex align-items-center me-2"><i className="fa-solid fa-bars"></i></span> <span className="d-block">Listings</span></a></li> */}
                    {activeTab !== "browse-all" && (<li className='bg-primary'>
                        <div >
                            <button className="btn w-100 text-start text-white p-0" onClick={() => setShowFilterInSidebar(!showFilterInSidebar)}>Customize your search</button>
                            {showFilterInSidebar && (<>
                                {activeTab === "virtual-land" && (<FilterVirtualLand key={formKey.unique_key} values={values} setValues={setValues} handleChange={handleChange} virtualLandOptions={virtualLandOptions} fetchAssetsVirtual={fetchAssetsVirtual} ></FilterVirtualLand>)}
                                {activeTab === "crowdfunding" && (<FilterCrowdFunding crowdFValues={crowdFValues} setcrowdFValues={setcrowdFValues} handleChange={handleChange} handleCrowdFundChange={handleCrowdFundChange} handleCrowdFToggler={handleCrowdFToggler} handleCityCheckbox={handleCityCheckbox} onReset={handleCrowdReset}></FilterCrowdFunding>)}
                            </>)}
                        </div>
                    </li>)}
                </Scrollspy>
                <button className="btn btn-primary sidebar-toggler" onClick={((e) => handleSidebar(e))}>
                    <span></span><span></span><span></span>
                </button>
                <button className="btn btn-primary sidebar-close" onClick={((e) => handleSidebar(e))}>
                    X
                </button>
            </div>
            <div className="main-content__right m-0 p-0" >
                {/* <div className="container-fluid" style={{ marginTop: 30 }}>
                    <button className='btn btn-primary p-2' style={{ display: 'flex', alignItems: 'center' }} onClick={() => navigate(-1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M20 15h-8v3.586a1 1 0 0 1 -1.707 .707l-6.586 -6.586a1 1 0 0 1 0 -1.414l6.586 -6.586a1 1 0 0 1 1.707 .707v3.586h8a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1z"></path>
                        </svg>
                        &nbsp; Back
                    </button>
                </div> */}
                <h2 className='p-3 text-center' style={{marginTop:30}}>Browse all Real Estate Crowdfunding & Tokenization Markets From One Place: Araa</h2>
                <div className="container-fluid">
                    <div className="row mt-0 mt-lg-5" id='listing'>
                        <div className="col-12">
                            <ul className="nav nav-tabs" id="browseTab" role="tablist">
                            <li><button className='btn' onClick={()=>navigate(-1)}><ArrowBackIosIcon/></button></li>
                                {/* <li className="nav-item" role="presentation">
                                <h1 className="seo-optimization"><button className={`nav-link ${activeTab === "browse-all" ? "active" : ""}`} id="browse-all-tab" data-bs-toggle="tab" data-bs-target="#browse-all" type="button" role="tab" aria-controls="browse-all" aria-selected={activeTab === "browse-all"} onClick={(e) => {setActiveTab("browse-all")}}>
                                        All
                                        </button>
                                    </h1>
                                </li> */}
                                <li className="nav-item" role="presentation">
                                <h1 className="seo-optimization"><button className={`nav-link ${activeTab === "crowdfunding" ? "active" : ""}`} id="crowdfunding-tab" data-bs-toggle="tab" data-bs-target="#crowdfunding" type="button" role="tab" aria-controls="crowdfunding" aria-selected={activeTab === "crowdfunding"} onClick={(e) => {setActiveTab('crowdfunding')}}>
                                Crowdfunded &Tokenized
                                        </button></h1>
                                </li>
	                        
                                <li className="nav-item" style={{"display":"none"}} role="presentation">
                                <h1 className="seo-optimization"><button className={`nav-link ${activeTab === "virtual-land" ? "active" : ""}`} id="virtual-land-tab" data-bs-toggle="tab" data-bs-target="#virtual-land" type="button" role="tab" aria-controls="virtual-land" aria-selected={activeTab === "virtual-land"} onClick={(e) => {setActiveTab("virtual-land")}}>
                                        Virtual Land
                                        </button>
                                        </h1>
                                </li>
                                <li className="nav-item" role="presentation">
                                <h1 className="seo-optimization"><button className={`nav-link ${activeTab === "hybrid-link" ? "active" : ""}`} id="hybrid-link-tab" data-bs-toggle="tab" data-bs-target="#hybrid-link" type="button" role="tab" aria-controls="hybrid-link" aria-selected={activeTab === "hybrid-link"} onClick={(e) => {setActiveTab("hybrid-link")}}>
                                        Hybrid Link
                                        </button>
                                        </h1>
                                </li>
                            </ul>
                            <div className="tab-content" id="browseTabContent">
                                {/* <div className="tab-pane fade" id="browse-all" role="tabpanel" aria-labelledby="browse-all-tab">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            All
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne">
                                            <div className="accordion-body">
                                                <div className="container-fluid g-0">
                                                    <div className="row">
                                                        {results && !isloading && results.map((item, index) => {
                                                            return <PhysicalProperty key={index} data={item} />
                                                        })}
                                                        {isloading && <div className='full-page'> <div className="loader" style={{ position: 'relative', inset: '0', transform: 'none' }}>
                                                            <ThreeDots color="#8FF129">
                                                            </ThreeDots>
                                                        </div></div>}
                                                        {error !== undefined && !isloading && error && <NoDataFound style={{ maxWidth: '400px' }}></NoDataFound>}

                                                        {total !== undefined && !error && !isloading && total === 0 && <NoDataFound style={{ maxWidth: '400px' }} nomatch></NoDataFound>}
                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel="next >"
                                                            onPageChange={handlePageClick}
                                                            pageRangeDisplayed={3}
                                                            marginPagesDisplayed={3}
                                                            pageCount={Math.ceil(total / 60)}
                                                            initialPage={allValues.page}
                                                            previousLabel="< previous"
                                                            renderOnZeroPageCount={null}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div> */}
                                <div className="tab-pane fade show active" id="crowdfunding" role="tabpanel" aria-labelledby="crowdfunding-tab">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={(e) => {setActiveTab('crowdfunding')}}>
                                        Crowdfunded &Tokenized
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree">
                                            <div className="accordion-body">
                                                <div className="container-fluid g-0">
                                                    <div className="row mt-5">
                                                        {results && !isloading && results.map((item, index) => {
                                                            return <PhysicalProperty key={index} data={item} />
                                                        })}
                                                        {isloading && <div className='full-page'> <div className="loader" style={{ position: 'relative', inset: '0', transform: 'none' }}>
                                                            <ThreeDots color="#8FF129">
                                                            </ThreeDots>
                                                        </div></div>}
                                                        {error !== undefined && !isloading && error && <NoDataFound style={{ maxWidth: '400px' }}></NoDataFound>}

                                                        {total !== undefined && !error && !isloading && total === 0 && <NoDataFound style={{ maxWidth: '400px' }} nomatch></NoDataFound>}
                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel="next >"
                                                            onPageChange={handlePageClick}
                                                            pageRangeDisplayed={3}
                                                            marginPagesDisplayed={3}
                                                            pageCount={Math.ceil(total / 60)}
                                                            initialPage={crowdFValues.page}
                                                            previousLabel="< previous"
                                                            renderOnZeroPageCount={null}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div className="tab-pane fade" id="virtual-land" role="tabpanel" aria-labelledby="virtual-land-tab">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={(e) => {setActiveTab('virtual-land')}}>
                                            Virtual Land
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo">
                                            <div className="accordion-body">
                                                <div className="container-fluid g-0">
                                                    <div className="row mt-5">
                                                        {results && !isloading && results.map((item, index) => {
                                                            return <PhysicalProperty key={index} data={item} />
                                                        })}

                                                        {isloading && <div className='full-page'> <div className="loader" style={{ position: 'relative', inset: '0', transform: 'none' }}>
                                                            <ThreeDots color="#8FF129">
                                                            </ThreeDots>
                                                        </div></div>}
                                                        {error !== undefined && !isloading && error && <NoDataFound style={{ maxWidth: '400px' }}></NoDataFound>}

                                                        {total !== undefined && !error && !isloading && total === 0 && <NoDataFound style={{ maxWidth: '400px' }} nomatch></NoDataFound>}
                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel="next >"
                                                            onPageChange={handlePageClick}
                                                            pageRangeDisplayed={3}
                                                            marginPagesDisplayed={3}
                                                            pageCount={Math.ceil(total / 60)}
                                                            initialPage={values.page}
                                                            previousLabel="< previous"
                                                            renderOnZeroPageCount={null}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div className="tab-pane fade" id="hybrid-link" role="tabpanel" aria-labelledby="hybrid-link-tab">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" onClick={(e) => {setActiveTab('hybrid-link')}}>
                                            Hybrid Link
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive">
                                            <div className="accordion-body">
                                                <WorkInProgress style={{ maxWidth: '400px' }} loadingText />
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}


function FilterVirtualLand(props) {
    return (<form action="" className='mt-3'>
        <div className="row mt-2 mb-4">
            <div className="col-12">
                <div className="input-wrapper">
                    <input type="search" className="form-control" id="browse-virtual-search" placeholder="Search any real estate digital: crowdfunded/tokenized/virtual NFT, ranked by worlds, communities, lands, and owners." onInput={e => props.setValues({
                        ...props.values,
                        'query': e.target.value
                    })} />
                </div>
            </div>
	    {/*
            <div className="col-12 mt-3 d-flex align-items-center flex-wrap  justify-content-xl-between">
                <Select placeholder={'RECENTLY LISTED'} options={props.virtualLandOptions} className="react-select-style-2" classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => console.log(e.value)} />

                <div className="custom-toggler mt-3">
                    <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                    </label>
                    <p className='m-0'> ON SALE</p>
                </div>
            </div>
            */}
        </div>
        <div className="mt-3 p-4 filter-menu ">
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>Worlds</p>
                <Select placeholder={'Select World'} options={virtualWorldOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setValues({
                    ...props.values,
                    'collection': e.value
                })} />

                {
                    /* <select onChange={((e)=>setValues({...values,['collection']:e.target.value}))} value={values.collection}>
                    //     <option value="value1">Value 1</option>
                    //     <option value="value2">Value 2</option>
                    //     <option value="value3">Value 3</option>
                    // </select> */
                }
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>Price Range <span className='text-capitalize'>( Million )</span></p>
                <div className="py-2 px-3 d-flex align-items-center border">
                    <p className='m-0 bg-light-custom px-2'>{props.values.price || 20}</p>
                    <div className="ms-3 d-flex">
                        <span>0</span>
                        <div className="custom-slider mx-2">
                            <input type="range" min={0} step={1} max={20} className="slider" onChange={props.handleChange('price')} id="myRange" />
                        </div>
                        <span>20</span>
                    </div>
                </div>
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>Blockchain</p>
                <Select placeholder={'Select Blockchain'} options={blockchainOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setValues({
                    ...props.values,
                    'blockchain': e.value
                })} />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>Currency</p>
                <Select placeholder={'Select Currency'} options={currencyOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setValues({
                    ...props.values,
                    'currency': e.value
                })} />
            </div>
            <div className='filter-menu__item d-lg-none'>
                <p className='m-0 text-uppercase mb-2'>Reset</p>
                <div className='d-flex align-items-center justify-content-center' style={{
                    minHeight: '42px'
                }}>
                    <button className='btn btn-primary m-0' onClick={props.fetchAssetsVirtual}>
                        <i className="fa-solid fa-rotate-right" style={{
                            color: '#8FF129'
                        }}></i>
                    </button>
                </div>
            </div>
        </div>
    </form>);
}

function FilterCrowdFunding(props) {
    return (<form action="" className='mt-3' onReset={props.onReset}>
        <div className="row mt-2 mb-4">
            <div className="col-12">
                <div className="input-wrapper">
                    <input name="query" type="text" className="form-control" id="browse-crowdfund-search" placeholder="Search your platforms or Investment based on your criteria" value={props.crowdFValues.query} onChange={(e)=>props.setcrowdFValues({...props.crowdFValues,query:e.target.value})}/>
                </div>
            </div>
        </div>
        <div className="mt-3 p-4 filter-menu ">
            {/* <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>PRICE PER SQM</p>
                <Select placeholder={'Select Price'} options={priceSqmOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setcrowdFValues({
                    ...props.crowdFValues,
                    'priceSqm': e.value
                })} />
            </div> */}
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>IRR %</p>
                <Select placeholder={'Select IRR'} options={irrPercentOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setcrowdFValues({
                    ...props.crowdFValues,
                    'target_irr': {...e}
                })} 
                value={props.crowdFValues.target_irr}
                />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>Cash-On-Cash %</p>
                <Select placeholder={'Select COC'} options={cocPercentOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setcrowdFValues({
                    ...props.crowdFValues,
                    'target_coc': {...e}
                })}
                value={props.crowdFValues.target_coc}
                />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>APR %</p>
                <Select placeholder={'Select APR'} options={aprPercentOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setcrowdFValues({
                    ...props.crowdFValues,
                    'target_apr': {...e}
                })} 
                value={props.crowdFValues.target_apr}
                />
            </div>
            {/* <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>RETURN CALCULATOR</p>
                <div className="py-2 px-3 d-flex align-items-center border">
                    <p className='m-0 bg-light-custom px-2'>{props.crowdFValues.returnPercentage || 20}%</p>
                    <div className="ms-3 d-flex">
                        <span>0%</span>
                        <div className="custom-slider mx-2">
                            <input type="range" min={0} step={1} max={60} value={20} className="slider" onChange={props.handleCrowdFundChange('returnPercentage')} id="myRange" />
                        </div>
                        <span>60%</span>
                    </div>
                </div>
            </div> */}
            {/* <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>TRENDING CITIES</p>
                <Select placeholder={'Select City'} options={trendingCitiesOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setcrowdFValues({
                    ...props.crowdFValues,
                    'trendingCity': e.value
                })} />
            </div> */}
            {/* <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>CITY</p>
                <Select placeholder={'Select City'} options={trendingCitiesOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setcrowdFValues({
                    ...props.crowdFValues,
                    'city': e.value
                })} />
            </div> */}
            <CheckboxesTags checklist={trendingCitiesOptions} entity={props.crowdFValues} onChange={props.handleCityCheckbox} />
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>Country</p>
                <Select placeholder={'Select Country'} options={countriesOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setcrowdFValues({
                    ...props.crowdFValues,
                    'country': {...e}
                })} 
                value={props.crowdFValues.country}
                />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>Property Type</p>
                <Select placeholder={'Select Property Type'} options={propertyTypeOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setcrowdFValues({
                    ...props.crowdFValues,
                    'property_type': {...e}
                })} 
                value={props.crowdFValues.property_type}
                />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>Platform Search</p>
                <Select placeholder={'Select Platform Search'} options={platformSearchOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setcrowdFValues({
                    ...props.crowdFValues,
                    'platform': {...e}
                })} 
                value={props.crowdFValues.platform}
                />
            </div>
            <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>Price</p>
                <Select placeholder={'Select Price'} options={priceOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setcrowdFValues({
                    ...props.crowdFValues,
                    'price': {...e}
                })
            }
                value={props.crowdFValues.price}
                />
            </div>            
            {/* <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>Price<span className='text-capitalize'></span></p>
                <div className="py-2 px-3 d-flex align-items-center border">
                    <p className='m-0 bg-light-custom px-2'>{props.crowdFValues.price || 36}</p>
                    <div className="ms-3 d-flex">
                        <span>0</span>
                        <div className="custom-slider mx-2">
                            <input type="range" min={0} step={1} max={36} className="slider" onChange={props.handleChange('price')} id="myRangeForPrice" />
                        </div>
                        <span>36</span>
                    </div>
                </div>
            </div> */}
            {/* <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>ASSETS CLASS COMPARISON</p>
                <Select placeholder={'Select Asset Class'} options={assetClassOptions} classNamePrefix={'react-select'} components={{
                    IndicatorSeparator: () => null
                }} onChange={e => props.setcrowdFValues({
                    ...props.crowdFValues,
                    'assetClass': e.value
                })} />
            </div> */}
            {/* <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>CRITERIA</p>
                <div className="d-flex flex-wrap">
                    {['blockchain', 'nft', 'tokenized'].map((btn, index) => {
                        return <div key={`criteria-btn-${index}`} className={`toggler-wrapper mb-3`}>
                            <div className="custom-toggler">
                                <label className="switch">
                                    <input type="checkbox" value={btn} onClick={e => props.handleCrowdFToggler(e)} />
                                    <span className="slider round"></span>
                                </label>
                                <p className='m-0 text-capitalize'> {btn}</p>
                            </div>
                        </div>;
                    })}

                </div>
            </div> */}
            {/* <div className='filter-menu__item'>
                <p className='m-0 text-uppercase mb-2'>LOCKUP PERIOD <span className='text-capitalize'>( Months )</span></p>
                <div className="py-2 px-3 d-flex align-items-center border">
                    <p className='m-0 bg-light-custom px-2'>{props.crowdFValues.lockupPeriod || 36}</p>
                    <div className="ms-3 d-flex">
                        <span>0</span>
                        <div className="custom-slider mx-2">
                            <input type="range" min={0} step={1} max={36} className="slider" onChange={props.handleChange('lockupPeriod')} id="myRange" />
                        </div>
                        <span>36</span>
                    </div>
                </div>
            </div> */}
             <div className='w-100'> 
                <p className='m-0 text-uppercase mb-2'><button className='btn btn-primary w-100' type='reset'>Reset</button></p>
            </div>
        </div>
    </form>);
}

export default Browse;
