import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
const Footer = () => {
    return (
        <footer className="footer bg-dark text-white p-4">
            <ul className="list-unstyled d-flex">
                <li><a className="text-decoration-none text-white" href="https://www.araa.land/" rel='noopener' target='_blank'>Home</a></li>
                <li><a className="text-decoration-none text-white" href="https://www.araa.land/about" rel='noopener' target='_blank'>About Us</a></li>
                {/* <li><a className="text-decoration-none text-white" href="/login?partner=1">Platform Partners Sign in</a></li> */}
                {/*<li><a className="text-decoration-none text-white" href="company">Company</a></li>
    <li><a className="text-decoration-none text-white" href="blog">Blog</a></li> */}
            </ul>
            <div className='d-flex'>
                <div className='mx-3'>
                    <p>Disclaimer: The information provided on this website is for general informational purposes only. We do not guarantee the accuracy, reliability, or completeness of the information provided. The use of the information on this website is at your own risk. We are not responsible for any errors or omissions, or for any losses, damages, or injuries arising from the use of the information on this website</p>
                    <p>sIn addition, we do not endorse any of the opportunities that appear on this website. Investment opportunities that appear on this website are speculative and involve substantial risk. People should not invest unless they can afford to lose all their capital. People should consult with a professional advisor before making any investment decisions. Private placements are illiquid and cannot be easily sold or exchanged. Any information provided on this website should not be considered as financial advice. We do not guarantee anyany investment returns</p>
                    <p className='mt-5'>© 2020/32 by Land Transcendence Technologies Ltd DIFC - Dubai - UAE</p>
                </div>
                <SocialShare />
            </div>
        </footer>
    );
}

function SocialShare() {
    return (
        <div className='social-share'>
            <h6>Follow us on </h6>
            <a href="https://twitter.com/AraaMarketplace" target="_blank" ><TwitterIcon fontSize="large" sx={{ color: "white" }} /></a>
            <a href="https://www.youtube.com/@araacrowd" target="_blank" ><YouTubeIcon fontSize="large" sx={{ color: "white" }}/></a>
            <a href="https://www.linkedin.com/company/araa-tradable-real-estate" target="_blank" ><LinkedInIcon fontSize="large" sx={{ color: "white" }}/></a>
        </div>
    )
}

export default Footer;
