import React, { Component } from 'react';
import { WithRouter } from './WithRouter';

function FallbackUI(){
    return (
    <div className="p-5">
        <h1>Something went wrong.</h1>
    </div>)
}

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // Log the error to an error reporting service
      console.error(error, errorInfo);
    }

    componentDidUpdate(prevProps) {
      // Reset the error boundary state when the route changes
      if (this.props.location !== prevProps.location) {
        this.setState({ hasError: false });
      }
    }
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <FallbackUI/>
      }
  
      return this.props.children; 
    }
  }

export default WithRouter(ErrorBoundary);
