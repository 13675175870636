import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { getUser, isLoggedIn, logOut } from "../CustomFunctions/auth";

const Profile = () => {
    const user = getUser();
// console.log("isloggedin", isLoggedIn());
    const handleLogout = () =>{
	logOut();
    }

    return(
        <>
        <Helmet>
        <title>Your profile in Araa Land , a Global Real Estate Marketplace</title>
        <meta name="description" content="Your profile in Araa Land , a Global Real Estate Marketplace.Find Any global Real Estate crowdfunding & tokenization assets from one convenient place" />
        <link rel="canonical" href="https://app.arra.land/profile" />
        </Helmet>
        <div className="main-content">
            <div className="main-content__left">
            </div>
            <div className="main-content__right">
                <div className="pt-4" style={{height:'calc(100vh - 280px'}}>
                    <div className="user-profile p-0 m-0 border-0 ">
                        { user && user!==undefined ? <div className="d-flex flex-wrap align-item-center justify-content-between pb-4 border-bottom px-3">
                            <div className="d-flex">
                                <div className="image-wrapper border-0 me-3">
                                    <img src={`https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`} alt="user" className='img-fluid' loading='lazy'/>
                                </div>
                                <div>
                                    <p className='mb-0 mt-2 user-profile-name'>{user.firstName} {user.lastName}</p>
                                    <p className='mb-0 mt-2 user-profile-location'>{user.location ? user.location : 'N/A'}</p>
                                </div>
                            </div>
                        </div> : null }
                        <div className="d-flex flex-wrap pt-3 pb-2 px-4 user-profile-stats justify-content-between">
                            <div>
                                <p className='mb-1 user-profile-stats-head'>Posts</p>
                                <p className='mb-0 user-profile-stats-value'>2.6K</p>
                            </div>
                            <div>
                                <p className='mb-1 user-profile-stats-head'>Posts</p>
                                <p className='mb-0 user-profile-stats-value'>2.6K</p>
                            </div>
                            <div>
                                <p className='mb-1 user-profile-stats-head'>Posts</p>
                                <p className='mb-0 user-profile-stats-value'>2.6K</p>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-danger d-block mt-5 py-2 px-5 mx-auto" onClick={handleLogout}>Logout</button>
                    {/* <div className="d-flex justify-content-between w-50 mx-auto mt-5">
                        <NavLink to={'summary'}>Profile Summary Child Page</NavLink>
                        <NavLink to={'account'}>Profile Account Child Page</NavLink>
                    </div>
                    <Outlet/> */}
                </div>
            </div>
        </div>
        </>
    )
}

export default Profile;
