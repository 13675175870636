import { Helmet } from "react-helmet-async";
import workInProgressImage from "../images/work-in-progress-primary.png";

const HybridAssets = () =>{
    return(
        <>
        <Helmet>
        <title>Find your perfect kind of hybrid assest is here.</title>
        <meta name="description" content="Find Any hybrid assets here" />
        <link rel="canonical" href="https://app.arra.land/hybrid-assets" />
        </Helmet>
        <div  className="no-page">
            <div className="d-flex flex-column align-items-center">
                <div style={{maxWidth:'60vw', 'fontSize': '20px'}}>
                <p>Araa believes in the convergence of Physical and Digital before such time where we live in a 'Ready Player One' world.  Our mission is to enable and accelerate such new wealth creation as well as enable the existing real estate world to partake in web3+.</p><p>  As a dedicated real estate marketplace, we will be listing various assets from different platforms that are on the forefront of digital to physical convergence.  Stay tuned!</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default HybridAssets;
