import { useDispatch , useSelector} from "react-redux";
import { CreateUserthunk } from "../Reduxstore/thunks";
import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import showToast from "../CustomFunctions/showToast";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

const SignUp = () => {
    const dispatch = useDispatch();
    const queryParameters = new URLSearchParams(window.location.search)
    const isPartner = queryParameters.get("partner")
    const [userInput, setUserInput] = useState({
        email : '',
        password : '',
	firstName : '',
	lastName: '',
	isPlatformUser: 'regular',
	isloading: false
    });

    const isloading = useSelector(state=> state.isloading);

    const {email,password, firstName, lastName, isPlatformUser} = userInput;

    const handleChange = key => event => {
	    // console.log([key, event.target.value]);
        setUserInput({...userInput, [key] : event.target.value})
    }

    const handleCreateUser = (e) =>{
        if(email == ''){
            showToast('Enter Email','error');
        }else if(firstName == ''){
            showToast('Enter First Name','error');
        }else if(lastName == ''){
            showToast('Enter Last Name','error');
        }else if(password == ''){
            showToast('Enter Password','error');
        }else{
	    let body = {"email": email, "first_name": firstName, "last_name": lastName, "password": password};
	    if (isPartner)
		body['is_platform_user'] = true;
	    else
		body['is_platform_user'] = false;
            dispatch(CreateUserthunk(body,userInput));    
        }
        e.preventDefault();
    }

    return(
        <>
        <Helmet>
        <title>SignUp to Arra Land , your place to search perfect property.</title>
        <meta name="description" content="Signup to Arra Land to find Any global Real Estate crowdfunding & tokenization assets from one convenient place" />
        <link rel="canonical" href="https://app.arra.land/signup" />
        </Helmet>
        <div className="signup">
            <div className="d-block w-100 py-5 px-5" style={{height:'calc(100vh - 220px'}}>
                <form action="" className="mt-4 mx-auto text-start shadow p-5 position-relative" style={{maxWidth:'700px',backgroundColor:'#f9f9f9',borderRadius:'20px'}}>

                <h3 className="text-dark text-center mb-4">{ isPartner ? "Partner Registration" : "User Registration" } </h3>
                    <div class="mb-3 row">
                        <label for="user-email" class="col-sm-2 col-form-label">Email</label>
                        <div class="col-sm-10">
                        <input type="text" class="form-control" id="user-email" onChange={handleChange('email')} />
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="user-email" class="col-sm-2 col-form-label">{ isPartner ? "Company Name":"First Name"}</label>
                        <div class="col-sm-10">
                        <input type="text" class="form-control" id="user-firstName" onChange={handleChange('firstName')} />
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="user-email" class="col-sm-2 col-form-label">{ isPartner ? "Website" :  "Last Name"}</label>
                        <div class="col-sm-10">
                        <input type="text" class="form-control" id="user-lastName" onChange={handleChange('lastName')} />
                        </div>
                    </div>
	            <div class="mb-3 row">
                        <label for="user-password" class="col-sm-2 col-form-label" >Password</label>
                        <div class="col-sm-10">
                        <input type="password" class="form-control" id="user-password" onChange={handleChange('password')}/>
                        </div>
                    </div>
	            <div class="mb-3 row">
	                {/*
                        <label for="user-password" class="col-sm-2 col-form-label" >Login Type</label>
	                
                        <div class="col-sm-10">
                          <label for="user-password" class="col-form-label" style={{"margin-left":"15px", "margin-right":"15px"}}>User</label>
                          <input type="radio" id="userTypeChoice1" style={{"margin-top": "-3px","vertical-align": "middle", "margin-right":"25px"}} name="userType" value="regular" onChange={handleChange('isPlatformUser')} />
                          <label for="user-password" class="col-form-label" style={{"margin-top": "-3px","vertical-align": "middle", "margin-right": "25px"}} >Platform</label>
	                  <input type="radio" id="userTypeChoice2" style={{"margin-top": "-3px","vertical-align": "middle", "margin-right": "25px"}} name="userType" value="platform" onChange={handleChange('isPlatformUser')} />

                        </div>
			*/}
	                { isPartner ? <input type="hidden" id="userTypeChoice2" style={{"margin-top": "-3px","vertical-align": "middle", "margin-right": "25px"}} name="userType" value="platform"/> : <input type="hidden" id="userTypeChoice1" style={{"margin-top": "-3px","vertical-align": "middle", "margin-right":"25px"}} name="userType" value="regular"/> }

                    </div>
    
                    <button className="d-block btn py-2 px-3 btn-primary mt-4 mx-auto" onClick={handleCreateUser} style={{minWidth:'200px',height:'35px'}}> { !isloading ? <span>Sign Up</span> : <span className="d-flex justify-content-center align-items-center"><ThreeDots color="#fff" height={17}></ThreeDots></span> }</button>
                
	            
                    <Link className="navbar-brand" to={isPartner ? '/login?partner=1':'/login'}>
		        Login
		    </Link>
                    <Link className="navbar-brand" to={isPartner ? '/forgot_password?partner=1':'/forgot_password'}>
		        Forgot Password
		    </Link>
                </form>
            </div>
            
        </div>
        </>
    )
}

export default SignUp;
