import { Link } from 'react-router-dom';
import React,{useState, useEffect} from 'react';
import { useFormik } from 'formik';
import Scrollspy from 'react-scrollspy';
import axios from 'axios';
import showToast from './../CustomFunctions/showToast';
import { useLocation, useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import {qlookup,validation} from './FormOptions';
import PlatForm, { HorizontalLinearStepper } from './PlatForm'
import getUser from './../CustomFunctions/auth';

const CustomForm = () => {
  const navigate = useNavigate();
  const [sidebar,setSidebar] = useState(false);
  const [initialValues, setInitialValues] = useState({
    launch_year:'',
    country:'',
    hq_city:'',
    name:'',
    url:'',
    logo:'',
    services_offered: [],
    blockchains:'',
    geographies:[],
    is_crowdfunding:'',
    investment_type: [],
    property_types: [],
    minimum_investment:'',
    investor_target: [],
    total_funding:'',
    deals_raised:'',
    deals_exited:'',
    has_secondary_market:'',
    secondary_market_open_time:'',
    has_property_management:'',
    has_expert_real_estate_investors:'',
    does_deal_review:'',
    is_regulated:'',
    regulated_by_country:'',
    regulated_by_agency:'',
    list_on_araa:'',
    email_contact:''
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,    
    // validationSchema:validation,
    onSubmit: values => {
      //submit data to backend
      localStorage.setItem('formdata',JSON.stringify(values))
      navigate('/confirm-form')
      const body=JSON.parse(JSON.stringify(values));
      // console.log(body);
      // for (var key in qlookup) {
      //   body[qlookup[key]] = values[key];
      // }
    //   axios(
    //      {url: process.env.REACT_APP_BASE_API+'/platform_form',
    //       method: "post",
	  //       data: body,
    //       withCredentials:true,
    //       headers:{
    //         'X-API-KEY':process.env.REACT_APP_API_KEY
    //       }
    //     }).then((res) => {
	  // showToast("Form data posted!", "success")
	  // setTimeout(()=>{navigate("/")}, 3000);
    //       //redirect to new page now.
    //     })
    //     .catch((err) => {
	  // showToast("Error posting data", "error")
    //       console.error(err);
    //     });

    },
  });

  const handleSidebar = (e) =>{
    setSidebar(!sidebar);
    e.preventDefault();
  }
  
  useEffect(()=> {
      axios(
         {url: process.env.REACT_APP_BASE_API+'/platform_form',
          method: "get",
          withCredentials:true,
          headers:{
            'X-API-KEY':process.env.REACT_APP_API_KEY,
          }
        }).then((res) => {
		// console.log("oooooooooooooooooo");
            // console.log(res);
            if(res.data){
            const newValues = {...initialValues,...res.data}
            if(newValues['user_id']){
                newValues['user_id']=newValues['user_id']?.['$oid'];
            }
            setInitialValues(newValues)
            }
	    // for (let i=1;i<27;i++) {
      //           newValues['q'+i] = res.data[qlookup['q'+i]];
      //       }
           
		
            //fill in the form with default data from the api call.
        })
        .catch((err) => {
          console.error(err);
          try{
            if(localStorage.getItem('formdata')){
              let data=JSON.parse(localStorage.getItem('formdata'))
              setInitialValues({...data})
            }
          }catch{}
        });
    return ()=>{
      if(window.location.pathname!=='/confirm-form'){
        showToast("Are you sure you want to exit without registration ?", "warning");
      }
    }
  },[])


  const user = getUser();

  return (<>
    <Helmet>
    <title>Come join Arra Land and become a part of bigger realestate community.</title>
    <meta name="description" content="Come join Arra land and become a part of bigger restate community" />
    <link rel="canonical" href="https://app.arra.land/form" />
    </Helmet>
    <div className="main-content">
      <div className="main-content__left ">
        <Scrollspy items={ ['listing','store'] } className={`list-unstyled sidebar ${sidebar && 'active'}`} currentClassName={'active'}>
	    {/*<li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#listing"> <span className="d-flex align-items-center me-2"><i className="fa-solid fa-bars"></i></span> <span className="d-block">Listings</span></a></li>*/}
            {/* <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#store" > <span className="d-flex align-items-center me-2"><img src={myStoreIcon} alt="icon" loading='lazy' /></span> <span className="d-block">My Store</span></a></li> */}
        </Scrollspy>
        <button className="btn btn-primary sidebar-toggler" onClick={((e)=>handleSidebar(e))}>
            <span></span><span></span><span></span>
        </button>
        <button className="btn btn-primary sidebar-close" onClick={((e)=>handleSidebar(e))}>
            X
        </button>
      </div>

	  <div className="main-content__right p-3 mt-5">
     {/* <div className="container-fluid" style={{marginTop:30}}>
                    <button className='btn btn-primary p-2' style={{display:'flex',alignItems:'center'}}onClick={()=>navigate(-1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-big-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M20 15h-8v3.586a1 1 0 0 1 -1.707 .707l-6.586 -6.586a1 1 0 0 1 0 -1.414l6.586 -6.586a1 1 0 0 1 1.707 .707v3.586h8a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1z"></path>
</svg>
                  &nbsp; Back
                    </button>
                </div> */}
        {/* <PlatForm formik={formik} viewDetails={true}></PlatForm> */}
      { user.platform_name == 'admin' ? <Link to="/admin-formupdate" style={{"margin-top": "10px", "display": "block"}}>To Admin Form</Link>: ""}
	  <HorizontalLinearStepper formik={formik} viewDetails={true}/>
      </div>
    </div>
    </>
  );
};

export default CustomForm;
