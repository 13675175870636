import React from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
country,
services_offered,
geographies,
is_crowdfunding,
investment_type,
property_types,
minimum_investment,
investor_target,
deals_raised,
deals_exited,
has_secondary_market,
secondary_market_open_time,
has_property_management,
has_expert_real_estate_investors,
does_deal_review,
is_regulated,
list_on_araa
} from './FormOptions';

function safeChecked(props, key, ele){
    if (props.formik.values[key] && props.formik.values[key].includes(ele)) {
        return true;
    }
    return false;
}


//this is for "*" in questions.
// const MandatoryStar=()=>(<span style={{color:'red'}}>*</span>);
const MandatoryStar=()=>(<></>);

export  function PlatForm(props) {
  const handleFileChange=(e)=>{
    const file = e.currentTarget.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      props.formik.setFieldValue("logo", reader.result);
    };
    reader.readAsDataURL(file);
  }
    return (
        <form onSubmit={props.formik.handleSubmit} className="customform">
                <div>
                  <label htmlFor="launch_year">What year did the platform launch ? <MandatoryStar /></label><br />
                  <input id="launch_year" name="launch_year" type="date" onChange={props.formik.handleChange} value={props.formik.values.launch_year} />
                  {props.formik.errors.launch_year ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.launch_year}</p> : null}
                </div>
                <div>
                  <label htmlFor="country">In which Country is the platform based ? <MandatoryStar /></label><br />
                  <select name="country" id="country" value={props.formik.values.country} onChange={props.formik.handleChange}>
                    <option value="">Choose</option>
                    {country?.map(ele => <option value={ele} key={"country" + ele}>{ele}</option>)}
                  </select>
                  {props.formik.errors.country ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.country}</p> : null}
                </div>
                <div>
                  <label htmlFor="hq_city">Which City is your company headquartered in ?</label><br />
                  <input id="hq_city" name="hq_city" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.hq_city} />
                  {props.formik.errors.hq_city ? <p style={{
      color: 'red',
      margin: '2px'
    }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.hq_city}</p> : null}
                </div>
                <div>
                  <label htmlFor="name">What is the name of your platform? <MandatoryStar /></label><br />
                  <input id="name" name="name" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.name} />
                  {props.formik.errors.name ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.name}</p> : null}
                </div>
                <div>
                  <label htmlFor="url">What's your website url? <MandatoryStar /></label><br />
                  <input id="url" name="url" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.url} />
                  {props.formik.errors.url ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.url}</p> : null}
                </div>
                <div>
                  <label htmlFor="services_offered">What kind of service do you offer? select one or more as applicable: <MandatoryStar /></label><br />
                  {services_offered?.map(ele => <div key={"services_offered"+ele}>
                    <input id={"services_offered" + ele} name="services_offered" type="checkbox" onChange={props.formik.handleChange} value={ele} checked={
safeChecked(props, "services_offered", ele)
			   		    } />
                      <label htmlFor={"services_offered" + ele}>{ele}</label><br />
                  </div>)}
                  {
      /* <div>
      <input
           id={"services_offered"+"Other"}
           name="services_offered"
           type="checkbox"
           onChange={formik.handleChange}          
         />
         <label htmlFor={"services_offered"+"Other"}>Other</label><br/>
      <input
       id={"services_offered"+"Other2"}
       name="services_offered"
       type="text"
       className='form-control'
       onChange={formik.handleChange}
       value={formik.values.services_offered}
      />
      </div> */
    }
                  {props.formik.errors.services_offered ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.services_offered}</p> : null}
                </div>
                <div>
                  <label htmlFor="blockchains">If you offer Tokenization leveraging Blockchain, what Blockchain do you use? pls write the name(s) such as: Algorand, Ethereum, Cardano, Polygon, Avalanche, etc...</label><br />
                  <input id="blockchains" name="blockchains" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.blockchains} />
                  {props.formik.errors.blockchains ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.blockchains}</p> : null}
                </div>
                <div>
                  <label htmlFor="geographies">What geographies are you currently present in or focus on? Where is the real estate? <MandatoryStar /></label><br />
                 {geographies.map(ele => <div key={"geographies"+ele}>
                    <input id={"geographies" + ele} name={"geographies"} type="checkbox" onChange={props.formik.handleChange} value={ele} checked={
safeChecked(props, "geographies", ele)} />
                    <label htmlFor={"geographies" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.geographies ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.geographies}</p> : null}
                </div>
                <div>
                  <label htmlFor="is_crowdfunding">Are you a marketplace for real estate crowdfunding?</label><br />
                  {is_crowdfunding.map(ele => <div key={"is_crowdfunding"+ele}>
                    <input id={"is_crowdfunding" + ele} name="is_crowdfunding" type="radio" onChange={props.formik.handleChange} value={ele} checked={

safeChecked(props, "is_crowdfunding", ele)} />
                    <label htmlFor={"is_crowdfunding" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.is_crowdfunding ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.is_crowdfunding}</p> : null}
                </div>
                <div>
                  <label htmlFor="investment_type">What type of investment can be found on your platform?</label><br />
                  {investment_type.map(ele => <div key={"investment_type"+ele}>
                      <input id={"investment_type" + ele} name="investment_type" type="checkbox" onChange={props.formik.handleChange} value={ele} checked={
safeChecked(props, "investment_type", ele)
	
			
		      } />
                      <label htmlFor={"investment_type" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.investment_type ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.investment_type}</p> : null}
                </div>
                <div>
                  <label htmlFor="property_types">What property type do you specialize in</label><br />
                  {property_types.map(ele => <div key={"property_types"+ele}>
                      <input id={"property_types" + ele} name="property_types" type="checkbox" onChange={props.formik.handleChange} value={ele} checked={
safeChecked(props, "property_types", ele)
		      } />
                      <label htmlFor={"property_types" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.property_types ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.property_types}</p> : null}
                </div>
                <div>
                  <label htmlFor="minimum_investment">What is the minimum investment required?</label><br />
                  {minimum_investment.map(ele => <div key={"minimum_investment"+ele}>
                      <input id={"minimum_investment" + ele} name='minimum_investment' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.minimum_investment === ele} />
                      <label htmlFor={"minimum_investment" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.minimum_investment ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.minimum_investment}</p> : null}
                </div>
                <div>
                  <label htmlFor="investor_target">What type of investors do you target</label><br />
                  {investor_target.map(ele => <div key={"investor_target"+ele}>
                      <input id={"investor_target" + ele} name="investor_target" type="checkbox" onChange={props.formik.handleChange} value={ele} checked={
safeChecked(props, "investor_target", ele)
		      } />
                      <label htmlFor={"investor_target" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.investor_target ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.investor_target}</p> : null}
                </div>
                <div>
                  <label htmlFor="total_funding">What is the total funding raised through your platform since inception? please answer in USD equivalent and consider all properties / deals that have so far successfully funded.
          </label><br />
                  <input id="total_funding" name="total_funding" type="number" inputMode="numeric" min={0} className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.total_funding} />
                  {props.formik.errors.total_funding ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.total_funding}</p> : null}
                </div>
                <div>
                  <label htmlFor="deals_raised">How many 'properties' or 'deals' have been successfully raised through your platform?</label><br />
                  {deals_raised.map(ele => <div key={"deals_raised"+ele}>
                  <input id={"deals_raised" + ele} name='deals_raised' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.deals_raised === ele} />
                  <label htmlFor={"deals_raised" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.deals_raised ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.deals_raised}</p> : null}
                </div>
                <div>
                  <label htmlFor="deals_exited">How many exits have you had on your platform? i.e. how many realized deals? </label><br />
                  {deals_exited.map(ele => <div key={"deals_exited"+ele}>
                      <input id={"deals_exited" + ele} name='deals_exited' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.deals_exited === ele} />
                      <label htmlFor={"deals_exited" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.deals_exited ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.deals_exited}</p> : null}
                </div>
                <div>
                  <label htmlFor="has_secondary_market">Does your platform offer a secondary market?</label><br />
                  {has_secondary_market.map(ele => <div key={"has_secondary_market"+ele}>
                  <input id={"has_secondary_market" + ele} name='has_secondary_market' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.has_secondary_market === ele} />
                  <label htmlFor={"has_secondary_market" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.has_secondary_market ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.has_secondary_market}</p> : null}
                </div>
                <div>
                  <label htmlFor="secondary_market_open_time">If you answered yes to 'secondary market', is the secondary market open 24/7 or partial?</label><br />
                  {secondary_market_open_time.map(ele => <div key={"secondary_market_open_time"+ele}>
                      <input id={"secondary_market_open_time" + ele} name='secondary_market_open_time' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.secondary_market_open_time === ele} />
                      <label htmlFor={"secondary_market_open_time" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.secondary_market_open_time ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.secondary_market_open_time}</p> : null}
                </div>
                <div>
                  <label htmlFor="has_property_management">Does your platform provide property management and/or asset management for the deals on your marketplace?</label><br />
                  {has_property_management.map(ele => <div key={"has_property_management"+ele}>
                      <input id={"has_property_management" + ele} name='has_property_management' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.has_property_management === ele} />
                      <label htmlFor={"has_property_management" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.has_property_management ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.has_property_management}</p> : null}
                </div>
                <div>
                  <label htmlFor="has_expert_real_estate_investors">Are there member(s) of your team who are expert real estate investors with real estate investment and management background?</label><br />
                  {has_expert_real_estate_investors.map(ele => <div key={"has_expert_real_estate_investors"+ele}>
                      <input id={"has_expert_real_estate_investors" + ele} name='has_expert_real_estate_investors' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.has_expert_real_estate_investors === ele} />
                      <label htmlFor={"has_expert_real_estate_investors" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.has_expert_real_estate_investors ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.has_expert_real_estate_investors}</p> : null}
                </div>
                <div>
                  <label htmlFor="does_deal_review">Does your platform review the deals?</label><br />
                  {does_deal_review.map(ele => <div key={"does_deal_review"+ele}>
                      <input id={"does_deal_review" + ele} name='does_deal_review' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.does_deal_review === ele} />
                      <label htmlFor={"does_deal_review" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.does_deal_review ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.does_deal_review}</p> : null}
                </div>
                <div>
                  <label htmlFor="is_regulated">Is your platform / company regulated?</label><br />
                  {is_regulated.map(ele => <div key={"is_regulated"+ele}>
                      <input id={"is_regulated" + ele} name='is_regulated' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.is_regulated === ele} />
                      <label htmlFor={"is_regulated" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.is_regulated ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.is_regulated}</p> : null}

                </div>
                <div>
                  <label htmlFor="regulated_by_country">If regulated, please state which country?</label><br />
                  <input id="regulated_by_country" name="regulated_by_country" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.regulated_by_country} />
                  {props.formik.errors.regulated_by_country ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.regulated_by_country}</p> : null}
                </div>
                <div>
                  <label htmlFor="regulated_by_agency">If regulated, please state by which agency/agencies</label><br />
                  <input id="regulated_by_agency" name="regulated_by_agency" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.regulated_by_agency} />
                  {props.formik.errors.regulated_by_agency ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.regulated_by_agency}</p> : null}
                </div>
                <div>
                  <label htmlFor="logo">Upload your website logo</label><br />
                  <input id="logo" name="logo" type="file" className='form-control' onBlur={handleFileChange} src={props.formik.values.logo}  accept='image/*'/>
                  {props.formik.errors.logo ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.logo}</p> : null}
           {props.formik.values.logo && props.formik.values.logo.length >0 && (<p className='my-2'><img src={props.formik.values.logo} alt="logo" width='150' height='150'/></p>)}
                </div>
                <div>
                  <label htmlFor="list_on_araa">Are you interested in having your deals also listed on our aggregator? Araa aggregator does not charge any fees for listings. Araa aggregator just directs traffic/users to your own marketplace, user can decide to buy directly from your platform. If yes, please contact listings@araa.land</label><br />
                  {list_on_araa.map(ele => <div key={"list_on_araa"+ele}>
                      <input id={"list_on_araa" + ele} name='list_on_araa' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.list_on_araa === ele} />
                      <label htmlFor={"list_on_araa" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.list_on_araa ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.list_on_araa}</p> : null}
                </div>
                <div>
                  <label htmlFor="email_contact">If you are interested please provide an email address and contact details below</label><br />
                  <input id="email_contact" name="email_contact" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.email_contact} />
                  {props.formik.errors.email_contact ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.email_contact}</p> : null}
                </div>      
                <p id="submitbtndiv">
                {props.viewDetails ?
                    <button id="viewDetailsButton" type="submit" className='btn btn-primary text-black'>Confirm Your Details</button>:
                    <button id="submitbtn" type="submit" className='btn btn-primary text-black'>Submit</button>
                  }
                </p>
        </form>
        );
}

const steps = [
  "The Basics",
  "The Offer",
  "Your clients",
  "Your platform",
  "Boost",
];

export  function HorizontalLinearStepper(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  // const [bringFocusTo]

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    let temp=[]
    for(let i=0;i<=step;i++){
      temp[i]=true;
    }
    setCompleted([...temp]);
    setActiveStep(step);
  };

  const isStepFailed = (step) => {
    let num;
    switch(step){
      case 0: 
      const step0Questions=['launch_year','country','hq_city','name','url'];
      num=step0Questions.filter(e=>props.formik.errors[e])
      return num.length > 0
        break;
      case 1:
      const step1Questions=['services_offered','blockchains','geographies','is_crowdfunding','investment_type','property_types'];
      num=step1Questions.filter(e=>props.formik.errors[e])
      return num.length > 0
          break;
      case 2:
      const step2Questions=['minimum_investment','investor_target','total_funding','deals_raised','deals_exited']
      num=step2Questions.filter(e=>props.formik.errors[e])
      return num.length > 0
          break;
      case 3:
      const step3Questions=['has_secondary_market','secondary_market_open_time','has_property_management','has_expert_real_estate_investors','does_deal_review','is_regulated','regulated_by_country','regulated_by_agency','logo']
      num=step3Questions.filter(e=>props.formik.errors[e])
      return num.length > 0
          break;
      case 4:
      const step4Questions=['list_on_araa','email_contact']
      num=step4Questions.filter(e=>props.formik.errors[e])
      return num.length > 0
          break;
      default:return true
        break;
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    localStorage.setItem('formdata', JSON.stringify(props.formik.values));
    setCompleted(newCompleted);
    handleNext();
   document.querySelector('#bring-focus-to')?.scrollIntoView()
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleFileChange=(e)=>{
    const file = e.currentTarget.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      props.formik.setFieldValue("logo", reader.result);
    };
    reader.readAsDataURL(file);
  }

  return (
    <Box sx={{ width: '100%',marginTop:'30px' }}>
      <h1 className='p-3 text-center' style={{marginTop:30}}>Helping real esate platforms to reach wider globle audience.</h1>
      <div style={{padding:'20px'}}>
      <article id="bring-focus-to"></article>
      <Stepper nonLinear activeStep={activeStep} alternativeLabel sx={{
        '& .MuiStepLabel-iconContainer .Mui-active ,& .MuiStepLabel-iconContainer .Mui-completed  ':{
          color:'#8FF129 !important'
        },
        '& .MuiStepIcon-root':{
          fontSize:'2.3rem'
        },
        '& .MuiStepLabel-alternativeLabel':{
          fontSize:'1.25rem'
        }
      }} >
        {steps.map((label, index) => {
          const labelProps = {};
          if (isStepFailed(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                Error
              </Typography>
            );
            labelProps.error = true;
          }
          return (
            <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)} >
              <StepLabel {...labelProps}>{label}</StepLabel>
            </StepButton>
          </Step>
          );
        })}
      </Stepper>
      </div>
      <form onSubmit={props.formik.handleSubmit} className="customform">
                {activeStep=='0' && (
                <>
                <h3 className='px-4'>General info about your platform:</h3>
                <div>
                  <label htmlFor="launch_year">What year did the platform launch ? <MandatoryStar /></label><br />
                  <input id="launch_year" name="launch_year" type="date" onChange={props.formik.handleChange} value={props.formik.values.launch_year} />
                  {props.formik.errors.launch_year ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.launch_year}</p> : null}
                </div>
                <div>
                  <label htmlFor="country">In which Country is the platform based ? <MandatoryStar /></label><br />
                  <select name="country" id="country" value={props.formik.values.country} onChange={props.formik.handleChange}>
                    <option value="">Choose</option>
                    {country?.map(ele => <option value={ele} key={"country" + ele}>{ele}</option>)}
                  </select>
                  {props.formik.errors.country ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.country}</p> : null}
                </div>
                <div>
                  <label htmlFor="hq_city">Which City is your company headquartered in ?</label><br />
                  <input id="hq_city" name="hq_city" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.hq_city} />
                  {props.formik.errors.hq_city ? <p style={{
      color: 'red',
      margin: '2px'
    }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.hq_city}</p> : null}
                </div>
                <div>
                  <label htmlFor="name">What is the name of your platform? <MandatoryStar /></label><br />
                  <input id="name" name="name" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.name} />
                  {props.formik.errors.name ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.name}</p> : null}
                </div>
                <div>
                  <label htmlFor="url">What's your website url? <MandatoryStar /></label><br />
                  <input id="url" name="url" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.url} />
                  {props.formik.errors.url ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.url}</p> : null}
                </div>
                </>)}
                {activeStep=='1' && (
                <>
                <h3 className='px-4'>What’s your specialization? Ie. Geography, property types, investment types etc…</h3>
                <div>
                  <label htmlFor="services_offered">What kind of service do you offer? select one or more as applicable: <MandatoryStar /></label><br />
                  {services_offered?.map(ele => <div key={"services_offered"+ele}>
                    <input id={"services_offered" + ele} name="services_offered" type="checkbox" onChange={props.formik.handleChange} value={ele} checked={
	               safeChecked(props, "services_offered", ele)
	
			    } />
                      <label htmlFor={"services_offered" + ele}>{ele}</label><br />
                  </div>)}
                  {/* <div key={"services_offered"+"other"}>
                    <input id={"services_offered" + "other"} name="services_offered" type="checkbox" onChange={props.formik.handleChange} value={'other'} checked={
	               safeChecked(props, "services_offered", 'other')
	
			    } />
                      <label htmlFor={"services_offered" + 'other'}>{'other'}</label><br />
                  </div> */}
                  {
      /* <div>
      <input
           id={"services_offered"+"Other"}
           name="services_offered"
           type="checkbox"
           onChange={formik.handleChange}          
         />
         <label htmlFor={"services_offered"+"Other"}>Other</label><br/>
      <input
       id={"services_offered"+"Other2"}
       name="services_offered"
       type="text"
       className='form-control'
       onChange={formik.handleChange}
       value={formik.values.services_offered}
      />
      </div> */
    }
                  {props.formik.errors.services_offered ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.services_offered}</p> : null}
                </div>
                <div>
                  <label htmlFor="blockchains">If you offer Tokenization leveraging Blockchain, what Blockchain do you use? pls write the name(s) such as: Algorand, Ethereum, Cardano, Polygon, Avalanche, etc...</label><br />
                  <input id="blockchains" name="blockchains" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.blockchains} />
                  {props.formik.errors.blockchains ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.blockchains}</p> : null}
                </div>
                <div>
                  <label htmlFor="geographies">What geographies are you currently present in or focus on? Where is the real estate? <MandatoryStar /></label><br />
                 {geographies.map(ele => <div key={"geographies"+ele}>
                    <input id={"geographies" + ele} name={"geographies"} type="checkbox" onChange={props.formik.handleChange} value={ele} checked={
safeChecked(props, "geographies", ele)
	
		    } />
                    <label htmlFor={"geographies" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.geographies ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.geographies}</p> : null}
                </div>
                <div>
                  <label htmlFor="is_crowdfunding">Are you a marketplace for real estate crowdfunding?</label><br />
                  {is_crowdfunding.map(ele => <div key={"is_crowdfunding"+ele}>
                    <input id={"is_crowdfunding" + ele} name="is_crowdfunding" type="radio" onChange={props.formik.handleChange} value={ele} checked={
safeChecked(props, "is_crowdfunding", ele)
	
		    } />
                    <label htmlFor={"is_crowdfunding" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.is_crowdfunding ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.is_crowdfunding}</p> : null}
                </div>
                <div>
                  <label htmlFor="investment_type">What type of investment can be found on your platform?</label><br />
                  {investment_type.map(ele => <div key={"investment_type"+ele}>
                      <input id={"investment_type" + ele} name="investment_type" type="checkbox" onChange={props.formik.handleChange} value={ele} checked={
safeChecked(props, "investment_type", ele)
	
		      } />
                      <label htmlFor={"investment_type" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.investment_type ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.investment_type}</p> : null}
                </div>
                <div>
                  <label htmlFor="property_types">What property type do you specialize in</label><br />
                  {property_types.map(ele => <div key={"property_types"+ele}>
                      <input id={"property_types" + ele} name="property_types" type="checkbox" onChange={props.formik.handleChange} value={ele} checked={
safeChecked(props, "property_types", ele)
	
		      } />
                      <label htmlFor={"property_types" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.property_types ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.property_types}</p> : null}
                </div></>)}
                {activeStep=='2' && (
                <>
                <h3 className='px-4'>Your clients and some specifics about your platform</h3>
                <div>
                  <label htmlFor="minimum_investment">What is the minimum investment required?</label><br />
                  {minimum_investment.map(ele => <div key={"minimum_investment"+ele}>
                      <input id={"minimum_investment" + ele} name='minimum_investment' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.minimum_investment === ele} />
                      <label htmlFor={"minimum_investment" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.minimum_investment ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.minimum_investment}</p> : null}
                </div>
                <div>
                  <label htmlFor="investor_target">What type of investors do you target</label><br />
                  {investor_target.map(ele => <div key={"investor_target"+ele}>
                      <input id={"investor_target" + ele} name="investor_target" type="checkbox" onChange={props.formik.handleChange} value={ele} checked={
safeChecked(props, "investor_target", ele)
	
		      } />
                      <label htmlFor={"investor_target" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.investor_target ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.investor_target}</p> : null}
                </div>
                <div>
                  <label htmlFor="total_funding">What is the total funding raised through your platform since inception? please answer in USD equivalent and consider all properties / deals that have so far successfully funded.
          </label><br />
                  <input id="total_funding" name="total_funding" type="number" inputMode="numeric" min={0} className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.total_funding} />
                  {props.formik.errors.total_funding ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.total_funding}</p> : null}
                </div>
                <div>
                  <label htmlFor="deals_raised">How many 'properties' or 'deals' have been successfully raised through your platform?</label><br />
                  {deals_raised.map(ele => <div key={"deals_raised"+ele}>
                  <input id={"deals_raised" + ele} name='deals_raised' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.deals_raised === ele} />
                  <label htmlFor={"deals_raised" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.deals_raised ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.deals_raised}</p> : null}
                </div>
                <div>
                  <label htmlFor="deals_exited">How many exits have you had on your platform? i.e. how many realized deals? </label><br />
                  {deals_exited.map(ele => <div key={"deals_exited"+ele}>
                      <input id={"deals_exited" + ele} name='deals_exited' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.deals_exited === ele} />
                      <label htmlFor={"deals_exited" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.deals_exited ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.deals_exited}</p> : null}
                </div></>)}
                {activeStep=='3' && (
                <>
                <h3 className='px-4'>Your platform skillset and added benefits</h3>
                <div>
                  <label htmlFor="has_secondary_market">Does your platform offer a secondary market?</label><br />
                  {has_secondary_market.map(ele => <div key={"has_secondary_market"+ele}>
                  <input id={"has_secondary_market" + ele} name='has_secondary_market' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.has_secondary_market === ele} />
                  <label htmlFor={"has_secondary_market" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.has_secondary_market ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.has_secondary_market}</p> : null}
                </div>
                <div>
                  <label htmlFor="secondary_market_open_time">If you answered yes to 'secondary market', is the secondary market open 24/7 or partial?</label><br />
                  {secondary_market_open_time.map(ele => <div key={"secondary_market_open_time"+ele}>
                      <input id={"secondary_market_open_time" + ele} name='secondary_market_open_time' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.secondary_market_open_time === ele} />
                      <label htmlFor={"secondary_market_open_time" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.secondary_market_open_time ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.secondary_market_open_time}</p> : null}
                </div>
                <div>
                  <label htmlFor="has_property_management">Does your platform provide property management and/or asset management for the deals on your marketplace?</label><br />
                  {has_property_management.map(ele => <div key={"has_property_management"+ele}>
                      <input id={"has_property_management" + ele} name='has_property_management' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.has_property_management === ele} />
                      <label htmlFor={"has_property_management" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.has_property_management ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.has_property_management}</p> : null}
                </div>
                <div>
                  <label htmlFor="has_expert_real_estate_investors">Are there member(s) of your team who are expert real estate investors with real estate investment and management background?</label><br />
                  {has_expert_real_estate_investors.map(ele => <div key={"has_expert_real_estate_investors"+ele}>
                      <input id={"has_expert_real_estate_investors" + ele} name='has_expert_real_estate_investors' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.has_expert_real_estate_investors === ele} />
                      <label htmlFor={"has_expert_real_estate_investors" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.has_expert_real_estate_investors ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.has_expert_real_estate_investors}</p> : null}
                </div>
                <div>
                  <label htmlFor="does_deal_review">Does your platform review the deals?</label><br />
                  {does_deal_review.map(ele => <div key={"does_deal_review"+ele}>
                      <input id={"does_deal_review" + ele} name='does_deal_review' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.does_deal_review === ele} />
                      <label htmlFor={"does_deal_review" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.does_deal_review ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.does_deal_review}</p> : null}
                </div>
                <div>
                  <label htmlFor="is_regulated">Is your platform / company regulated?</label><br />
                  {is_regulated.map(ele => <div key={"is_regulated"+ele}>
                      <input id={"is_regulated" + ele} name='is_regulated' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.is_regulated === ele} />
                      <label htmlFor={"is_regulated" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.is_regulated ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.is_regulated}</p> : null}

                </div>
                <div>
                  <label htmlFor="regulated_by_country">If regulated, please state which country?</label><br />
                  <input id="regulated_by_country" name="regulated_by_country" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.regulated_by_country} />
                  {props.formik.errors.regulated_by_country ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.regulated_by_country}</p> : null}
                </div>
                <div>
                  <label htmlFor="regulated_by_agency">If regulated, please state by which agency/agencies</label><br />
                  <input id="regulated_by_agency" name="regulated_by_agency" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.regulated_by_agency} />
                  {props.formik.errors.regulated_by_agency ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.regulated_by_agency}</p> : null}
                </div>
                <div>
                  <label htmlFor="logo">Upload your website logo</label><br />
                  <input id="logo" name="logo" type="file" className='form-control' onBlur={handleFileChange} src={props.formik.values.logo}  accept='image/*'/>
                  {props.formik.errors.logo ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.logo}</p> : null}
           {props.formik.values.logo && props.formik.values.logo.length >0 && (<p className='my-2'><img src={props.formik.values.logo} alt="logo" width='150' height='150'/></p>)}
                </div></>)}
                {activeStep=='4' && (
                <>
                <h3 className='px-4'>Boosting your listings and campaigns?</h3>
                <div>
                  <label htmlFor="list_on_araa"> Are you looking to expand your reach and attract more potential buyers to your real estate deals? Consider duplicating your listings on our Araa aggregator! Our platform does not charge any fees for listings, and we simply direct traffic and users to your own marketplace. This means users can decide to buy directly from your platform, giving you greater visibility and potentially increasing your sales. If you're interested in taking advantage of this opportunity, please contact us at [listings@araa.land]. We look forward to hearing from you and working together to grow your real estate business.</label><br />
                  {list_on_araa.map(ele => <div key={"list_on_araa"+ele}>
                      <input id={"list_on_araa" + ele} name='list_on_araa' type="radio" onChange={props.formik.handleChange} value={ele} checked={props.formik.values.list_on_araa === ele} />
                      <label htmlFor={"list_on_araa" + ele}>{ele}</label><br />
                  </div>)}
                  {props.formik.errors.list_on_araa ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.list_on_araa}</p> : null}
                </div>
                <div>
                  <label htmlFor="email_contact">If you are interested please provide an email address and contact details below</label><br />
                  <input id="email_contact" name="email_contact" type="text" className='form-control' placeholder="Your answer" onChange={props.formik.handleChange} value={props.formik.values.email_contact} />
                  {props.formik.errors.email_contact ? <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
             <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
           </svg>&nbsp;{props.formik.errors.email_contact}</p> : null}
                </div></>)}
        
        <article className='m-3 p-3'>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
            Almost done! review & confirm.
            </Typography>
            {/* <Typography sx={{ mt: 2, mb: 1 }}>
              Thanks for registering! By creating a profile, you're giving your business greater visibility and the opportunity to connect with potential clients from around the world.<br/>
              We're committed to accelerate the adoption of digitized real estate – we are here to support your platform and helping you achieve success in the real estate industry. If you have any questions or need assistance in improving your profile, please don't hesitate to reach out to us.<br/><br/>
              Thank for updating / registering your platform, we look forward to seeing your business grow.<br/><br/>
              Best regards, Araa Team
            </Typography> */}
            
              {/* <Button onClick={handleReset}>Reset</Button> */}
             { props.viewDetails ?
                    <button id="viewDetailsButton" type="submit" className='btn btn-primary text-black'>Confirm Your Details</button>:
                    <button id="submitbtn" type="submit" className='btn btn-primary text-black' >Submit</button>
                     }
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {activeStep + 1}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleComplete} sx={{ mr: 1 }}>
              Save & Next
              </Button>
              {/* {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))} */}
            </Box>
          </React.Fragment>
        )}
        </article>
        </form>
    </Box>
  );
}
export default PlatForm

// {activeStep === steps.length ? (
//   <React.Fragment>
//     <Typography sx={{ mt: 2, mb: 1 }}>
//       All steps completed - you&apos;re finished
//     </Typography>
//   </React.Fragment>
// ) : (
//   <React.Fragment>
//     {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
//     <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
//       <Button
//         color="inherit"
//         disabled={activeStep === 0}
//         onClick={handleBack}
//         sx={{ mr: 1 }}
//       >
//         Back
//       </Button>
//       <Box sx={{ flex: '1 1 auto' }} />
//       {isStepOptional(activeStep) && (
//         <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
//           Skip
//         </Button>
//       )}

//      { activeStep < steps.length - 1 && <Button onClick={handleNext}>
//      Save & Next
//       </Button>}
//     </Box> */}
//   </React.Fragment>
// )}
