import Scrollspy from 'react-scrollspy';
import Select from 'react-select';

import { useState, useEffect } from 'react';
import axios from 'axios';
import ToolsCompareTable from './ToolsCompareTable';
import WorkInProgress from './WorkInProgress';
import { assetClassOptions, irrPercentOptions, priceSqmOptions, trendingCitiesOptions, basedInOptions, assetTypesOptions } from '../CustomFunctions/filterOptions';
import { Helmet } from 'react-helmet-async';

const Tools = () =>{
    const [values, setValues] = useState({
        criteria:[]
    });
    const [sidebar,setSidebar] = useState(false);

    const handleSidebar = (e) =>{
        setSidebar(!sidebar);
        e.preventDefault();
    }

    const handleChange = key => event => {
        setValues({...values, [key] : event.target.value})
    }
    // console.log("Values State",values);
    
    const handleToggler = (elem) =>{
        if(elem.target.checked){
            setValues({...values,['criteria']:[...values['criteria'],elem.target.value]})
        }else{
            setValues({...values,['criteria']:[...values['criteria']].filter(function(btn) { 
                return btn !== elem.target.value 
            })});
        }
    }
    const [platformData, setPlatformData] = useState([]);
    //setPlatformData([])
    /*
    let compareData = [{
        platformname:"Decentraland",
        annualreturn:"10.03",
        return3y:"10.03",
        classification:"Equity Global",
        growth:"path to image.png",
        mininvest:"CAD $4,500.00"
    }];*/

    const fetchPlatformData = () => {
      axios(
	 {url: '/api/platform_data', 
          method: "get",
          headers:{
            'X-API-KEY':process.env.REACT_APP_API_KEY,
          }
        }).then((res) => {
		// console.log(3333333333);
		// console.log(res.data.platforms);
          setPlatformData(res.data.platforms);
        })
        .catch((err) => {
          console.error(err);
        });
    };	
    useEffect(() => {
      fetchPlatformData();
    }, []);

    return(
        <>
        <Helmet>
            <title>Tools to find your perfect property</title>
            <meta name='description' content="Tools to find your perfect property"></meta>
            <link rel="canonical" href="https://app.araa.land/tools"/>
        </Helmet>
        <div className="main-content tools">
            <div className="main-content__left ">
                <Scrollspy items={ ['listing','store'] } className={`list-unstyled sidebar ${sidebar && 'active'}`} currentClassName={'active'}>
                    <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#listing"> <span className="d-flex align-items-center me-2"><i class="fa-solid fa-bars"></i></span> <span className="d-block">Listings</span></a></li>

                    {/* <li><a className="d-flex align-items-center lh-2 text-decoration-none" href="#store" > <span className="d-flex align-items-center me-2"><img src={myStoreIcon} alt="icon" loading='lazy'/></span> <span className="d-block">My Store</span></a></li> */}

                </Scrollspy>
                <button className="btn btn-primary sidebar-toggler" onClick={((e)=>handleSidebar(e))}>
                    <span></span><span></span><span></span>
                </button>
                <button className="btn btn-primary sidebar-close" onClick={((e)=>handleSidebar(e))}>
                    X
                </button>
            </div>
            <div className="main-content__right m-0 p-0">
                <div className="container-fluid">
                    <div className="row mt-0 mt-lg-5" id='listing'>
                        <div className="col-12">
                                <ul className="nav nav-tabs" id="browseTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="compare-platform-tab" data-bs-toggle="tab" data-bs-target="#compare-platform" type="button" role="tab" aria-controls="compare-platform" aria-selected="true">Compare Platforms</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="compare-virtual-land-tab" data-bs-toggle="tab" data-bs-target="#compare-virtual-land" type="button" role="tab" aria-controls="compare-virtual-land" aria-selected="false">Compare Virtual Lands  </button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="compare-crowd-fund-tab" data-bs-toggle="tab" data-bs-target="#compare-crowd-fund" type="button" role="tab" aria-controls="compare-crowd-fund" aria-selected="false">Compare Crowd Funded Assets</button>
                                    </li> */}
                                </ul>
                                <div className="tab-content" id="browseTabContent">
                                    <div className="tab-pane fade show active" id="compare-platform" role="tabpanel" aria-labelledby="compare-platform-tab">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Compare Platforms
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne">
                                            <div className="accordion-body">
                                                <div className="container-fluid g-0">
                                                    <form action="">
                                                        <div className="row mt-2 mb-4">
                                                            <div className="col-12">
                                                                <div className="input-wrapper">
                                                                    <input type="text" className="form-control" id="leaderboard-search" placeholder="Search your platforms or Investment based on your criteria"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-3 p-4 filter-menu ">
                                                            <div className='filter-menu__item'>
                                                                <p className='m-0 text-uppercase mb-2'>Asset Types</p>
                                                                <Select placeholder={'Asset Types'}  options={assetTypesOptions} classNamePrefix={'react-select'} components={{IndicatorSeparator: () => null }} onChange={((e)=>setValues({...values,['assetTypes']:e.value}))}/>
                                                            </div>
                                                            <div className='filter-menu__item'>
                                                                <p className='m-0 text-uppercase mb-2'>Based In</p>
                                                                <Select placeholder={'Based In'}  options={basedInOptions} classNamePrefix={'react-select'} components={{IndicatorSeparator: () => null }} onChange={((e)=>setValues({...values,['basedIn']:e.value}))}/>
                                                            </div>
                                                            <div className='filter-menu__item'>
                                                                <p className='m-0 text-uppercase mb-2'>RETURN CALCULATOR</p>
                                                                <div className="py-2 px-3 d-flex align-items-center border">
                                                                    <p className='m-0 bg-light-custom px-2'>{values.returnPercentage || 20}%</p>
                                                                    <div className="ms-3 d-flex">
                                                                        <span>0%</span>
                                                                        <div className="custom-slider mx-2">
                                                                            <input type="range" min={0} step={1} max={60} className="slider" onChange={handleChange('returnPercentage')} id="myRange"/>
                                                                        </div>
                                                                        <span>60%</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='filter-menu__item'>
                                                                <p className='m-0 text-uppercase mb-2'>TRENDING CITIES</p>
                                                                <Select placeholder={'Select City'}  options={trendingCitiesOptions} classNamePrefix={'react-select'} components={{IndicatorSeparator: () => null }} onChange={((e)=>setValues({...values,['trendingCity']:e.value}))} />
                                                            </div>
                                                            <div className='filter-menu__item'>
                                                                <p className='m-0 text-uppercase mb-2'>ASSETS CLASS COMPARISON</p>
                                                                <Select placeholder={'Select Asset Class'}  options={assetClassOptions} classNamePrefix={'react-select'} components={{IndicatorSeparator: () => null }} onChange={((e)=>setValues({...values,['assetClass']:e.value}))}/>
                                                            </div>
                                                            <div className='filter-menu__item'>
                                                                <p className='m-0 text-uppercase mb-2'>CRITERIA</p>
                                                            <div className="d-flex flex-wrap">
                                                                    { ['blockchain','nft','tokenized'].map((btn,index)=>{
                                                                        return(
                                                                            <div key={`criteria-btn-${index}`} className={`toggler-wrapper mb-3`}>
                                                                                <div className="custom-toggler">
                                                                                    <label className="switch">
                                                                                        <input type="checkbox" value={btn} onClick={((e)=>handleToggler(e))}/>
                                                                                        <span className="slider round"></span>
                                                                                    </label>
                                                                                    <p className='m-0 text-capitalize'> {btn}</p>
                                                                                </div>
                                                                            </div>
                                                                            );
                                                                        })
                                                                    }
                                                                
                                                            </div>
                                                            </div>
                                                            <div className='filter-menu__item'>
                                                                <p className='m-0 text-uppercase mb-2'>LOCKUP PERIOD <span className='text-capitalize'>( Months )</span></p>
                                                                <div className="py-2 px-3 d-flex align-items-center border">
                                                                    <p className='m-0 bg-light-custom px-2'>{values.lockupPeriod || 36}</p>
                                                                    <div className="ms-3 d-flex">
                                                                        <span>0</span>
                                                                        <div className="custom-slider mx-2">
                                                                            <input type="range" min={0} step={1} max={36}  className="slider" onChange={handleChange('lockupPeriod')} id="myRange"/>
                                                                        </div>
                                                                        <span>36</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="row mt-5">
                                                        <div className="tools-content">
                                                            <p className='m-0 text-uppercase'>COMPARISON ANAYLYSIS WITH KEY METRICS </p>
                                                            <ToolsCompareTable data={platformData}></ToolsCompareTable>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="tab-pane fade" id="compare-virtual-land" role="tabpanel" aria-labelledby="compare-virtual-land-tab">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Compare Virtual Lands
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo">
                                            <div className="accordion-body">
                                                <WorkInProgress style={{maxWidth:'400px'}} loadingText/>
                                            </div>
                                        </div>              
                                    </div>
                                    {/* <div className="tab-pane fade" id="compare-crowd-fund" role="tabpanel" aria-labelledby="compare-crowd-fund-tab">
                                    <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Compare Crowd Funded Assets
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree">
                                            <div className="accordion-body">
                                                <WorkInProgress style={{maxWidth:'400px'}} loadingText/>
                                            </div>
                                        </div>      
                                    </div> */}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Tools;
