import { configureStore } from '@reduxjs/toolkit';
import {AddAssetReducer, AuthReducer, DashboardReducer, MarketPlaceReducer, PhysicalAssetReducer, SearchReducer, VirtualAssetReducer, AddTimestampReducer, GetTimestampReducer, AppendTimestampReducer, ForgotPasswordReducer, ResetPasswordReducer,AdminTableThunkReducer,AdminTableUpdateThunkReducer} from './reducer.js'


export const store = configureStore({
    reducer: {
     auth:AuthReducer,
     physicalAsset:PhysicalAssetReducer,
     virtualAsset:VirtualAssetReducer,
     marketplace:MarketPlaceReducer,
     search:SearchReducer,
     dashboard:DashboardReducer,
     addAsset:AddAssetReducer,
     addTimestamp: AddTimestampReducer,
     getTimestamp: GetTimestampReducer,
     appendTimestamp: AppendTimestampReducer,
     forgotPassword: ForgotPasswordReducer,
     resetPassword: ResetPasswordReducer,
     adminTable:AdminTableThunkReducer,
     updateAdminTable:AdminTableUpdateThunkReducer
    }
  });

  // console.log("STORE STATE >>>",store.getState())
