import { useEffect, useState, Fragment } from "react";
import { useDispatch , useSelector} from "react-redux";
import { TailSpin, ThreeDots} from 'react-loader-spinner';

import { GetTimestampsThunk, AddTimestampThunk, AppendTimestampThunk, WithdrawAssetThunk } from "../Reduxstore/thunks";

import showToast from './../CustomFunctions/showToast';

import AboutUsImage from '../images/about-us-primary.png';
import CustomTable from './Tables/CustomTable';
import { useMemo } from 'react';
import { sha256 } from 'js-sha256';

import addTimestampFormInputs from "../CustomFunctions/addTimestampFormInputs";
import appendTimestampFormInputs from "../CustomFunctions/appendTimestampFormInputs";

import { getUser, isLoggedIn, updateUser } from '../CustomFunctions/auth';

import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom';

import useExternalScript from './../CustomFunctions/useExternalScript';
import { Helmet } from "react-helmet-async";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


/* BROWSER FUNCTIONS */
let NETWORK = "testnet";

if (NETWORK == "testnet") {
    var server = 'https://testnet-algorand.api.purestake.io/ps2';
    var indexerServer = 'https://testnet-algorand.api.purestake.io/idx2';
} else {
    var server = 'https://mainnet-algorand.api.purestake.io/ps2';
    var indexerServer = 'https://mainnet-algorand.api.purestake.io/idx2';
}

const token = { 'X-API-Key': 'yNPj0iakUn3NIdtBhHRWi3IZxgxYW0gd9BGTIM4Y' }
const port = '';

//let algodClient;
//let indexerClient;

async function initAlgoSigner() {
    window.algodClient = new window.algosdk.Algodv2(token, server, port);
    window.indexerClient = new window.algosdk.Indexer(token, indexerServer, port);

    if (typeof window.AlgoSigner !== 'undefined') {
        //algosigner is installed
	try {
            let d = await window.AlgoSigner.connect();
            SDKSetup();
	    return true;
        } catch(e) {
            console.error(e);
	    showToast('Unknown error', 'error');
	    return false;
        }
    }
    else {
        showToast('AlgoSigner is NOT installed.', 'error');
	return false;
    }
}


window.initAlgoSignerSilent= async ()=>{
	// console.log("SILENT CALLED", "-=+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
     let res = await window.AlgoSigner.accounts({"ledger": "TestNet"});
     if (res.length > 0) {
         await initAlgoSigner();
     }
}

window.isAlgoSignerConnected = async ()=> {
     let res = await window.AlgoSigner.accounts({"ledger": "TestNet"});
     if (res.length > 0) {
         return true;
     }
     return false;
}

window.getAccounts = async ()=> {
     let res = await window.AlgoSigner.accounts({"ledger": "TestNet"});
     return res
}



function SDKSetup() {
    let elm_init = document.getElementById("");
    window.algodClient.healthCheck().do().then(d => {
        finishSetup();
    }).catch(e => {
        showToast('Unspecified error with Purestake API.', 'error');
        console.error(e);
    });
}

function finishSetup() {
    window.AlgoSigner.accounts({
        ledger: 'TestNet'
    }).then((d) => {
        // console.log("address:", d[0]['address']);
            /*
        gAlgoSignerAccountData = d;

        console.log(d);
        //gAccount['address'] = d[selectedAccount]['address'];
        //document.getElementById('account_address').innerHTML = gAccount['address'];
        var elm = document.getElementById("account_select");
        var ss = ""
        for (var i=0;i<d.length;i++) {
            ss += "<option value=\""+i+"\">Account #"+i+": "+d[i]['address']+"</option>"
        }
        elm.innerHTML = ss;
        refreshBalance();
*/

    }).catch((e) => {
  //      makeError("Unable to fetch account address");
        console.error(e);
    });

    //updateNetworkStatus();


}

async function AssetOptIn(sender, assetId) {
    let params = await window.algodClient.getTransactionParams().do();
	// console.log(params);
    let txn = new window.algosdk.Transaction({
        assetIndex: assetId,
        from: sender,
        amount: 0,
        to: sender,
        type: 'axfer',
        fee: Math.max(1000, params['fee']),
        firstRound: params['firstRound'],
        lastRound: params['lastRound'],
        genesisID: params['genesisID'],
        genesisHash: params['genesisHash'],
        flatFee: true
    });

    var txnRaw = {"txn": window.AlgoSigner.encoding.msgpackToBase64(txn.toByte())}

    let signedTxns = await window.AlgoSigner.signTxn([txnRaw])
    let binarySignedTxs = signedTxns.map((tx) => window.AlgoSigner.encoding.base64ToMsgpack(tx.blob));

    try {
        let res = await window.algodClient.sendRawTransaction(binarySignedTxs).do();
        // console.log(res)
        var txid = res['txId'];
        showToast("Success: Txid "+txid);
        return txid;
    } catch (error) {
        console.log(error)
        showToast(error['message'], 'error');      
        return false;
    }
}

/* SHARED FUNCTIONS */

const openAppendTimestamp = (txid) => {
	    // console.log("append")
        document.getElementById("add-timestamp").classList.add('hide-element');
        document.getElementById("append-timestamp").classList.remove('hide-element');
document.getElementById("digitalid-selection").classList.remove('hide-element')
}

const openAddTimestamp = () => {
	    // console.log("add")
        document.getElementById("add-timestamp").classList.remove('hide-element');
        document.getElementById("append-timestamp").classList.add('hide-element');
	document.getElementById("digitalid-selection").classList.add('hide-element');
}



function filesToHashes(callback) {
  function wrapper(i) {
    return () => {
    //   console.log(readers, i);
      var data = readers[i].result;

      //compute hash here...
      returnData['hashes'][i] = sha256(data);

    //   console.log("ee", i, returnData['hashes'], returnData['hashes'].length, totalFiles);
      if (Object.keys(returnData['hashes']).length == totalFiles) {
         var totalHash = "";
         for (var j=0;j<files.length;j++) {
           totalHash += returnData['hashes'][j];
         }
         totalHash = sha256(totalHash)
         returnData['totalHash'] = totalHash;
	//  console.log("total", returnData)
         callback(returnData);
      }
    }
  }

  var files = document.getElementById("files").files;
  var returnData = {"hashes": {}, "filenames": {}, "totalHash": ""};
  var readers = {}
  var totalFiles = files.length;

  for (var i=0;i<files.length;i++) {
    readers[i] = new FileReader();
    returnData['filenames'][i] = files[i].name

    readers[i].addEventListener("load", wrapper(i), false);
    readers[i].readAsDataURL(files[i]);
  }
}


const TimestampTable = (props)=>{
    let stateRef = props.stateRef;
	
    let timestampData = props.data.data;
    let user = props.data.user;
	// console.log("((((((((((((((((((((((((((((((((((((((((())))))))))))))))))))))")
	// console.log(user, timestampData);
    let myPlot;

/*TxID
Time
Hash
Withdraw
*/  
    /*
    timestampData = [
	             {"txID": "example id 1", 
                      "contentHash": "example hash 1",
                      "time": "timey",
		      "address": "1025 Kannen Road, San Francisco, CA, USA",
                      "link": "linky",
	              "asset_link": "asset_link",
		      "chain": "Algorand"},
	             {"txID": "example id 2", 
                      "contentHash": "example hash 2",
                      "time": "timey",
		      "address": "1025 Kannen Road, San Francisco, CA, USA",
                      "link": "linky",
	              "asset_link": "asset_link",
                      "chain": "Algorand"},

	             {"txID": "example id 3", 
                      "contentHash": "example hash 3",
                      "time": "timey",
	              "address": "1025 Kannen Road, San Francisco, CA, USA",
                      "link": "linky",
	              "asset_link": "asset_link",
		      "chain": "Ethereum"},

    ]
    */
    // console.log('rr', timestampData);
    if (timestampData.length == 0 || timestampData[timestampData.length-1].txID != "") { 
      timestampData.push(
	             {"txID": "",//"",//"example id 1", 
                      "contentHash": "",//"example hash 1",
                      "time": "",//"timey",
		      "address": "",//"1025 Kannen Road, San Francisco, CA, USA",
                      "link": "",//"linky",
	              "asset_link": "",//"asset_link",
		      "chain": "",//"Algorand"
		     }
      );
    }
    // console.log('rr2', timestampData);
   
    if(timestampData.length!==0){
        myPlot = timestampData.map(each=>{
            let {txID,contentHash,time,link, chain, address, asset_link } = each;
	    txID = {"id": txID, "chain": chain}
            return {txID,address, time, link, asset_link}
        })
    }
    
    
    const columns = useMemo(
    () => [
        {
        Header: 'TxID',
        accessor: 'txID',
        disableSortBy: true,
        Cell:({row})=>{
            /*const {world,landicon,landname} = row.original.world*/
	    //console.log(row.original, 'ii', timestampData);
	    const {id, chain} = row.original.txID;
	    if (id) {
              return(
                <div className="icon-wrapper">
                    <div className="d-inline-block ms-3"><p className="asset-name mb-0" alt={id}>{id.substring(0,12)+"..."} </p><p className="mb-0 asset-subname">{chain}</p></div>
                </div>
              )
            } else {
	      return (
		    <Fragment>
		      <div style={{"display": "flex", "flex-direction": "column"}}>
                        <button className="btn btn-primary py-2 " style={{minWidth:'150px', "whiteSpace": "nowrap"}}  onClick={openAddTimestamp} > <span>Add New Property ID</span> </button>
		        <div style={{"margin": "auto"}}>{user.dids_available} PIDs left <Link to="/buy_property_dids">Add More</Link></div>
		      </div>
		    </Fragment>
              )

	    }
	  }
        },
        {
        Header:'Address',
        accessor:'address'
        },
	{
        Header: 'Time',
        accessor: 'time',
        },

        {
          Header:'Asset Link',
          accessor:'asset_link',
	  disableSortBy: true,
  	  Cell:({row})=>{
            /*const {world,landicon,landname} = row.original.world*/
            const asset_link = row.original.asset_link
		  
            //let asset_id = row.original.asset_link.split("/")
            //asset_id = asset_id[asset_id.length-1];
	    //const asset_link = "/asset?id="+asset_id+"&chain="+row.original.txID.chain;
            if (asset_link) {
                return(
			<Fragment>
			<a href={asset_link} onClick={(e)=>{e.stopPropagation();}} target="_blank" rel="noopener noreferrer">Link</a>

			{/*
				Link to={asset_link}>Asset</Link>*/}
			</Fragment>
                )
            } else {
	        return (<p></p>)
	    }
          }	    
          
        },
    ],
    []
    )

    const rowProps = (row) => {
	if (row.values.txID['id']) {    
	    return {
              onClick: () => {
		//open append timestamp
                  if (stateRef) {
		      //update the state ref for the seleced row
	              stateRef({visible: true, id: row.values.txID['id']});
		  }
    		  openAppendTimestamp(row.values.txID['id']);
  		  //select this row... not pretty, but works
		  Array.from(document.getElementsByClassName("timestamp-row")).forEach((e)=>{e.style['background-color']="";});
		  document.getElementById("timestamp_"+row.values.txID['id']).style['background-color'] = "#ddefff";
		
	      },
	      id: "timestamp_"+row.values.txID['id'],
              "class": "timestamp-row",
              style: {
                cursor: "pointer"
              }
           }
        } else {
           return {}
	}
    }
    
    return(
        <div className="table-wrapper digitalid-table-item__content virtual-land-table">
             <CustomTable data={{columns,myPlot,sortData:true, rowProps}} />
        </div>
    )
}


const Timestamp = () =>{
    const navigate=useNavigate();
    let timestampData = [];
    const user = getUser();

    const [userState, setUserState ] = useState(user)
    updateUser((res)=>{
	if (JSON.stringify(res) != JSON.stringify(userState)) {
            setUserState(res);
	}
    });

    const [value, setValue] = useState({});
    const [getTimestampValue, setTimestampValue] = useState({"numTimestamps": 0});
    const [addTimestampData, setaddTimestampData] = useState({
                    jurisdiction: '',
	            id: '',
		    owner_name: '',
		    address: '',
		    gps_coordinates: '',
	            description: '',
		    files: ''
    });
    const [appendTimestampData, setappendTimestampData] = useState({
	            data_description: '',
		    data_link: ''
    });
    const [addTimestampFiles, setaddTimestampFiles] = useState([]);
      

    const [getSelectedTimestamp, setSelectedTimestamp] = useState({
        visible: false,
        id: '',   
    });
    const { jurisdiction, id, owner_name, address, gps_coordinates, description, files} = addTimestampData;
    const { data_description, data_link} = appendTimestampData;

    // console.log("qqq", data_description, data_link)

    const handleAddTimestampForm = (key, id) => event => {
        setaddTimestampData({...addTimestampData, [key] : event.target.value});
	if (id) {
             updateAddTimestampUpdateFiles(id);
	}
    };

    const handleAppendTimestampForm = (key, id) => event => {
	    // console.log("eeeeefff", key,id, event.target.value);
            setappendTimestampData({...appendTimestampData, [key] : event.target.value});
	    // console.log(appendTimestampData[key], appendTimestampData['data_description']);
	    // console.log(data_description, data_link, 'mm')
    };



    const dispatch = useDispatch()
    const hidePanelsExcept = (id) =>{
	let elm = document.getElementById("browse-timestamps");
	Array.from(document.getElementsByClassName("tab-pane")).forEach((ee)=> {
	    if (ee.id != id) {
                ee.classList.remove("active");
                ee.classList.remove("show");
            }
	});
    }
    const fetchMyTimestamps = () =>{
	//bootstrap is not removig the "active show" classes from content when
	//clicking on a new tab, so manually fix it here.
	hidePanelsExcept("my-timestamps");
        //let reset_val = Date.now();
        //setFormKey({unique_key: reset_val});
        //console.log(1)
        dispatch(GetTimestampsThunk(userState.email, "mainnet"));
    };

    const fetchBrowseTimestamps = () =>{
	hidePanelsExcept("browse-timestamps");
        
        //let reset_val = Date.now();
        //setFormKey({unique_key: reset_val});
        //console.log(1)
        //setValue({});
        // dispatch(SearchThunk());
    };

    useEffect(()=>{
        // console.log(value)
        // console.log("h1");
        fetchMyTimestamps();
    },[value])
    
    useExternalScript("https://unpkg.com/algosdk@1.13.1/dist/browser/algosdk.min.js");
    useExternalScript("https://www.unpkg.com/buffer@6.0.3/index.js");

    const [algoSignerConnected, setalgoSignerConnected] = useState(false);
 
    //try to init algosigner if already inited and don't need login


    useEffect(() => {
      // declare the data fetching function
      const asyncFunc = async () => {
        try {
            let res = await window.isAlgoSignerConnected();
            setalgoSignerConnected(true);

            if (res) {
                await window.initAlgoSignerSilent();
	    }
	}
        catch (err) {
	    console.log(err);
            setalgoSignerConnected(false);
	}
      }

      // call the function
      asyncFunc().catch(console.error);
    }, [])



    const initAlgoSignerButton = async () => {
	    let res = await initAlgoSigner();
            setalgoSignerConnected(true);

    }
    //AlgoButtons
    const optInAsset = async () => {
	    let sender = (await window.getAccounts())[0]['address'];
	    // console.log(sender);
	    let res = await AssetOptIn(sender, selectedTS['asset_id']);
    }
    const withdrawAsset = async () => {
	    let body = {"asset_id": selectedTS['asset_id'] }

	    //send request to the server
	    let withdrawAssetData = body;
	    dispatch(WithdrawAssetThunk(withdrawAssetData));
    }
    const redeemAsset = async () => {
	    let res = await AssetOptIn(selectedTS['asset_id']);
    }

    const goToBuyPropertyID = async () => {
        window.location = "/buy_property_dids";
    }


    /*
    <button id='withdrawButton' onClick={withdrawAsset}>Withdraw</button>
    <button id='redeemButton' onClick={redeemAsset}>Redeem</button>
 */

    //const {isloadingng,addtimestamp,error:addTimestampError}  = useSelector(state=>state.addTimestamp);
    const {isloading:addTimestampLoading,addtimestamp,error:addTimestampError}  = useSelector(state=>state.addTimestamp);
    const {isloading:appendTimestampLoading,appendtimestamp,error:appendTimestampError}  = useSelector(state=>state.appendTimestamp);
 
    const {isloading:getTimestampLoading,gettimestamp,error:getTimestampError}  = useSelector(state=>state.getTimestamp);
 
    const addTimestamp = (e) =>{
        e.preventDefault();
        if(jurisdiction == ''){
            showToast('Enter Jurisdiction','error');
        }else if(id == ''){
            showToast('Enter Real Estate ID','error');
        }
        else if(owner_name == ''){
            showToast('Enter Owner Name','error');
        }
        else if(address == ''){
            showToast('Enter Address','error');
        }
        else if(gps_coordinates == ''){
            showToast('Enter GPS Coordinates','error');
        }
        else if(files == ''){
            showToast('Enter Files','error');
        }
        else {
            //console.log(addTimestampFiles[0][0], addTimestampFiles[1][0], 'eeeeeeeeeeee')
	    let des = ""
            for (var i=0;i<addTimestampFiles.length;i++) {
              des+= "file"+(i+1)+": "+document.getElementById(addTimestampFiles[i][0].fid).value+"\n"
	    }
	    addTimestampData['description'] = des;
	    filesToHashes( (resData)=> {
		addTimestampData['files'] = resData;
		addTimestampData['email'] = userState.email
                // console.log('lllllllllllllllllllllLLLL')
		// console.log(addTimestampData);
                dispatch(AddTimestampThunk(addTimestampData));
            })

        }
    }

    const appendTimestamp = (e) =>{
        e.preventDefault();
	    // console.log(11111111111111222222222);
	    // console.log(data_description, data_link);
        if(data_description == ''){
            showToast('Enter Description','error');
        }else if(data_link == ''){
            showToast('Enter Link','error');
        }
        else {
	    appendTimestampData['email'] = userState.email;	
            appendTimestampData['txid'] = getSelectedTimestamp.id;
	    appendTimestampData['network'] = 'testnet';
            dispatch(AppendTimestampThunk(appendTimestampData));

            //dispatch(AppendTimestampThunk({...appendTimestampData}));
        }
    }

    // console.log("3333333333333333333333333333333333333333333333333333333333333333333", addtimestamp);
    if (addtimestamp && addtimestamp.result == "success"){
       //addtimestamp.result = "";
       //setValue({})
    }

    
    const getTimestamp = (e) =>{
        e.preventDefault();
	// console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
	// console.log(e);
    }
    // console.log("TTTTTTTTTTTTTTTTTTTTT")
    // console.log(gettimestamp)

    // console.log("++++DDDDDDDDDDDD+++");
    // console.log(appendTimestampData)
    // console.log(userState);

    // console.log(addTimestampData)
    // console.log(gettimestamp, "jjj");
    if (gettimestamp != undefined) {
      for (var i=0;i<gettimestamp.length;i++) {
	var dat = gettimestamp[i];
	var doc = {"txID": dat['txid'],
                   "contentHash": dat['content_hash'],
	           "time": dat['timestamp'],
	           "address": dat['address'],
	           "link": dat['link'],
	           "asset_link": dat['asset_link'],
		   "extra": [],
   	           "chain": dat['chain']}
        timestampData[i] = doc;
      }

	//     console.log('888888888888888888888888888888888888888');
    //   console.log(gettimestamp);
	    
    //   console.log(getTimestampValue, gettimestamp.length, 'kk');
      if (getTimestampValue.numTimestamps != gettimestamp.length) {
	//   console.log("AJGJGJGKJGKJGKJGKJKGJ", getTimestampValue);
          setTimestampValue({"numTimestamps": gettimestamp.length});
	//   console.log("LLLLLLLLLLLLLLLLLLLLLL");
          setValue({});
         
      }
    }
   
    /*
     let timestampData = [
	             {"txID": "example id 1", 
                      "contentHash": "example hash 1",
                      "time": "timey",
		      "address": "1025 Kannen Road, San Francisco, CA, USA",
                      "link": "linky",
	              "asset_link": "asset_link",
		      "chain": "Algorand"},
	             {"txID": "example id 2", 
                      "contentHash": "example hash 2",
                      "time": "timey",
		      "address": "1025 Kannen Road, San Francisco, CA, USA",
                      "link": "linky",
	              "asset_link": "asset_link",
                      "chain": "Algorand"},

	             {"txID": "example id 3", 
                      "contentHash": "example hash 3",
                      "time": "timey",
	              "address": "1025 Kannen Road, San Francisco, CA, USA",
                      "link": "linky",
	              "asset_link": "asset_link",
		      "chain": "Ethereum"},

     ]

    */
    const updateAddTimestampUpdateFiles = (id) =>{ 
        var elm = document.getElementById(id);
	var filedata = [];
	for (var i=0;i<elm.files.length;i++){
	    filedata.push([{
	      "filename": elm.files[i]['name'],
	      "fid": "file_"+i,
	      "i": i,
	    }])
	   
	}
        setaddTimestampFiles(filedata);
    }
    const updateAppendTimestampUpdateFiles = (id) =>{ 
        var elm = document.getElementById(id);
        setappendTimestampData("??");
    }


    // console.log("selct", getSelectedTimestamp, gettimestamp)
    let selectedTS = {}
    if (getSelectedTimestamp['visible']) {
	for (var i=0;i<gettimestamp.length;i++) {
            if (getSelectedTimestamp.id == gettimestamp[i]['txid']) {
                selectedTS = {...gettimestamp[i]}
		selectedTS['visible'] = true;

		break
	    }
	}
    }


    // console.log("tttsss", selectedTS);
    //...
    return(
        <>
        <Helmet>
        <title>Araa.land - Your Global Real Estate Marketplace</title>
        <meta name="description" content="Find Any global Real Estate crowdfunding & tokenization assets from one convenient place" />
        <link rel="canonical" href="https://app.arra.land/propid"/>
        </Helmet>
      <div className="main-content digitalid" id="main_content">
        <div className="main-content__left">

        </div>
        <div className="main-content__right">
        {/* <div className="container-fluid" style={{marginTop:30}}>
                    <button className='btn btn-primary p-2' style={{display:'flex',alignItems:'center'}}onClick={()=>navigate(-1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-big-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M20 15h-8v3.586a1 1 0 0 1 -1.707 .707l-6.586 -6.586a1 1 0 0 1 0 -1.414l6.586 -6.586a1 1 0 0 1 1.707 .707v3.586h8a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1z"></path>
</svg>
                  &nbsp; Back
                    </button>
                </div> */}
	  <div className="min-page">
        <h1 className='mt-5'>Unique Global Property ID</h1>
        <p className="my-2">At Araa, we believe that a standardized and interoperable web3.0 enabled property ID system is crucial for the growth and success of the web3.0 real estate ecosystem. That's why we're dedicated to developing a global property ID system that will make it easier to exchange and trade properties seamlessly. With our web3.0 enabled system, we're creating a more efficient, liquid marketplace for all stakeholders in the real estate industry.</p>
        <h1 className="my-2">Property Passport to Web3.0</h1>
        <p className="my-2">The Property ID is specific to each Property, it is the passport to Web3.0 - if you are a property owner and want to partake and benefit from your real estate in a Web3.0 environment, you can now pre-order an individual ID specific for your property and soon begin building the authenticity and credibility of your ID as we connect to verifiable credential partners.</p>
        <h4 className="mb-5">Join us and step in the future of Real Estate</h4>
            <div className='mx-auto my-2' style={{maxWidth:'100%', padding: '0px'}}>

              <ul class="nav nav-tabs" id="timestampTab" role="tablist">
              <li><button className='btn' onClick={()=>navigate(-1)}><ArrowBackIosIcon/></button></li>
	        <li class="nav-item" role="presentation">
	          <button class="nav-link active" id="my-timestamps-tab" data-bs-toggle="tab" data-bs-target="#my-timestamps" type="button" role="tab" aria-controls="my-timestamps" aria-selected="true"  onClick={fetchMyTimestamps}><h1 className="seo-optimization">My Property IDs</h1></button>
	        </li>
	        <li class="nav-item" role="presentation">
	          <button class="nav-link" id="browse-timestamps-tab" data-bs-toggle="tab" data-bs-target="#browse-timestamps" type="button" role="tab" aria-controls="browse-timestamps" aria-selected="false"  onClick={fetchBrowseTimestamps}><h1 className="seo-optimization">Browse Property IDs</h1></button>
	        </li>
	      </ul>
              <div className="tab-content" id="browseTabContent">
                <div className="tab-pane fade show active" id="my-timestamps" role="tabpanel" aria-labelledby="my-timestamps-tab">
   
                    <div className="row mt-3 mt-lg-5 digitalid-table-item" id=""> 
                        <h3 className="py-3 mb-1">My Property IDs</h3> 
                        <div className="col-12"> 
                            <TimestampTable style={{backgroundColor:'#FFEFBD'}} data={{"data": timestampData, "user": userState}} stateRef={setSelectedTimestamp}></TimestampTable> 
                        </div> 
                    </div>

	            {/* Add Timestamp */}
	            <div className="mt-3 mt-lg-5 add-assets hide-element" id="add-timestamp">
                        <h3>Post Property ID</h3>
                        <form action="" className="">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    {addTimestampFormInputs.map((input)=>{
                                        input.tag = (input.type== 'textarea')?'textarea':'input';
                                        // console.log("ttdddddd", input.tag, input.type, input.id);
					if (input.id != "files") {
                                          return(
                                          <div class="mb-3">
                                              <label for={input.id} class="form-label">{input.label}</label>	
                                              
                                              <input.tag type={input.type} class="form-control" id={input.id} placeholder={input.placeholder} onChange={handleAddTimestampForm(`${input.value}`)} style={input.style}/>
                                          </div>
                                          )
					} else {
					  return(
                                            <div class="mb-3">
                                              <label for={input.id} class="form-label">{input.label}</label>
                                              <input type={input.type} class="form-control" id={input.id} placeholder={input.placeholder} onChange={handleAddTimestampForm(`${input.value}`, `${input.id}`)} multiple/>
                                            </div>
                                          )
					}
                                    })}
	                            
	                            <div class="filenames-grid" id="filenames" style={{"color": "#A2A2A2"}}>
	                            { addTimestampFiles.length > 0 &&
			              <Fragment>
				        <div>Filename</div><div>Description</div>		  
                                        {addTimestampFiles.map((data)=> {
                                          return(
                                            <Fragment>
  				              <div class="mt-3" ><label>{data[0].filename}</label></div>
                                              <input class="mt-3" type="text" id={data[0].fid} placeholder={"File "+(data[0].i+1)+ " description"} />
                                            </Fragment>
				          )   
      				        })}
                                      </Fragment>
				    }
	                            </div>
                                    <button className="btn mt-3 btn-primary py-2 " style={{minWidth:'150px'}}  onClick={(e)=>addTimestamp(e)} > { !addTimestampLoading ? <span>Add Property ID</span> : <span className="d-flex justify-content-center align-items-center"><ThreeDots color="#fff" height={17}></ThreeDots></span> }</button>
                                </div>
                            </div>
                        </form>

                    </div>
	            {/* Append Timestamp */}
	            {/* Timestamp preview  */}
	            <div className="digitalid-selection mt-3" id="digitalid-selection">
	              {
			(selectedTS['visible'] == true) ? (	     
			 <div>
                          <h3>Selected ID</h3>
                          <div className="digitalid-selection-table">
                            <div className="tcell1 tc">Chain</div>
                            <div className="tcell2 tc">{selectedTS['chain']}</div>
	                    <div className="tc">Asset</div>
                            <div className="tc">{selectedTS['asset_link']}</div>
                            <div className="tc">Jurisdiction</div>
                            <div className="tc">{selectedTS['jurisdiction']}</div>
			    <div className="tc">Real Estate ID</div>
                            <div className="tc">{selectedTS['id_number']}</div>
                            <div className="tc">Owner Name</div>
                            <div className="tc">{selectedTS['owner']}</div>
                            <div className="tc">Address</div>
                            <div className="tc">{selectedTS['address']}</div>
                            <div className="tc">GPS Coordinates</div>
                            <div className="tc">{selectedTS['gps']}</div>
                            <div className="tc">Files Description</div>
                            <div className="tc">{selectedTS['description']}</div>
                            <div className="tc">Files Hash</div>
                            <div className="tc">{selectedTS['content_hash']}</div>
			    { (selectedTS['extra'].length > 0) ?
		           
		       	      <Fragment>
                               <div className="tc tcell1">Appended Description</div>
                               <div className="tc tcell2">Appended Link</div>
		              </Fragment>
			     : ""
			    }
			    {selectedTS['extra'].map((obj)=>{
                              return(
			        <Fragment>
				  <div className="tcell3 tc">{obj.description}</div>
				  <div className="tcell4 tc">{obj.link}</div>
				</Fragment>
			    )})}
	                    
                            <div className="tcell1 tc">Token Status</div>
		            <div className="tcell2 tc">Token Actions</div>
		            <div className="tcell3 tc">Available</div>
			    <div className="tcell4 tc">
				{ !algoSignerConnected 
				? <button id='initAlgoButton' onClick={initAlgoSignerButton}>Init Algosigner</button>
				: <Fragment><button id='optInButton' onClick={optInAsset}>OptIn</button>
				<button id='withdrawButton' onClick={withdrawAsset}>Withdraw</button>
				<button id='redeemButton' onClick={redeemAsset}>Redeem</button></Fragment>}
			    </div>
			   </div>
	                 </div>) : "" 
		      }
	            </div>
	            <div className="mt-3 mt-lg-5 add-assets hide-element" id="append-timestamp">
                        <h3>Append Data</h3>
                        <form action="" className="">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    {appendTimestampFormInputs.map((input)=>{
                                        return(
                                        <div class="mb-3">
                                            <label for={input.id} class="form-label">{input.label}</label>
                                            <input type={input.type} class="form-control" id={input.id} placeholder={input.placeholder} onChange={handleAppendTimestampForm(`${input.value}`, `${input.id}`)} />
                                        </div>
                                        )
                                    })}
                                    <button className="btn mt-3 btn-primary py-2 " style={{minWidth:'150px'}}  onClick={(e)=>appendTimestamp(e)} > { !appendTimestampLoading ? <span>Append</span> : <span className="d-flex justify-content-center align-items-center"><ThreeDots color="#fff" height={17}></ThreeDots></span> }</button>
                                </div>
                            </div>
                        </form>
                    </div>
	  	    <div>
	                <button className="btn mt-3 btn-primary py-2 " style={{minWidth:'150px'}}  onClick={(e)=>goToBuyPropertyID(e)} > <span>Buy Property IDs</span></button>
                    </div>

	        </div>
              </div>
              <div className="tab-pane fade" id="browse-timestamps" role="tabpanel" aria-labelledby="browse-timestamps-tab">
	  
                    <div className="row mt-3 mt-lg-5 digitalid-table-item" id=""> 
                        <h3 className="py-3 mb-1">Browse Property IDs</h3> 
                        <div className="col-12"> 
                            <TimestampTable style={{backgroundColor:'#FFEFBD'}} data={{"data": timestampData, "user": userState}}></TimestampTable>
                        </div> 
                    </div>
              </div>
            </div>
	  </div>
        </div>
      </div>
      </>
    )
}

export default Timestamp;
