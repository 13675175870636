/*
Jurisdiction:
ID Number:
Owner
Files:
*/
const addTimestampFormInputs = [{
    label:'Jurisdiction',
    id:'jurisdiction-name',
    type:'text',
    placeholder:'Enter Jurisdiction',
    value:'jurisdiction'
},
{
    label:'Real Estate ID',
    id:'real-estate-id',
    type:'text',
    placeholder:'Enter Real Estate ID',
    value:'id'
},
{
    label:'Owner Name',
    id:'owner-name',
    type:'text',
    placeholder:'Enter Owner Name',
    value:'owner_name'
},
{
    label:'Address',
    id:'address',
    type:'text',
    placeholder:'Enter Address',
    value:'address'
},
{
    label:'GPS Coordinates',
    id:'gps-coordinates',
    type:'text',
    placeholder:'Enter GPS Coordinates',
    value:'gps_coordinates'
},
{
    label:'Files',
    id:'files',
    type:'file',
    placeholder:'Select Files',
    value:'files'
},


]

export default addTimestampFormInputs;
